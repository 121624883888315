// import produce from "immer";
import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const initialState = {
  list: [],
  currentTicket: undefined,
  currentTicketComments: [],
  currentTicketMetaAttributes: [],
  currentTicketSubTickets: [],
  currentTicketTimeline: [],
  currentTicketAssigneeIds: [],
  currentTicketStatus: undefined,
  currentTicketPriority: undefined,
  currentTicketStoryPoint: undefined,
  currentTicketTitle: undefined,
  currentTicketContent: [],
  loading: false,
  selectedTicket: null,
  showTicketCreationModal: false,
  currenPagetBucket: undefined,
  newlyCreatedTicket: undefined,
  comment: {},
};

const slice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    requested: (tickets, action) => {
      tickets.loading = true;
    },

    received: (tickets, action) => {
      if (action.requestType === "show") {
        tickets.currentTicket = action.payload.ticket;
        tickets.currentTicketSubTickets =  action.payload.ticket.sub_tickets
        tickets.currentTicketComments = action.payload.ticket.comments;
        tickets.currentTicketMetaAttributes = action.payload.ticket.metadata;
        tickets.currentTicketAssigneeIds = action.payload.ticket.assignee_ids;
        tickets.currentTicketDueDate = action.payload.ticket.due_date;
        tickets.currentTicketPriority = action.payload.ticket.priority;
        tickets.currentTicketStatus = action.payload.ticket.status;
        tickets.currentTicketStoryPoint = action.payload.ticket.story_points;
        tickets.currentTicketTitle = action.payload.ticket.title;
        tickets.currentTicketContent = action.payload.ticket.content;
      } else {
        tickets.list = action.payload.list;
      }
      tickets.loading = false;
    },

    requestFailed: (tickets, action) => {
      tickets.loading = false;
    },

    createRequested: (tickets, action) => {
      tickets.loading = false;
    },

    createReceived: (tickets, action) => {
      const { ticket } = action.payload;
      tickets.loading = false;
      if (action.requestType === "sub-ticket-creation") {
        tickets.currentTicketSubTickets.push(ticket);
        return;
      }
      if (ticket.bucket_id === tickets.currenPagetBucket?.id && !ticket.parent_ticket_id) {
        tickets.list.push(action.payload.ticket);
      }
      if (ticket.parent_ticket_id === tickets.currentTicket?.id) {
        tickets.currentTicket.sub_tickets.push(ticket);
      }
      if (action.requestType === 'create-and-open') {
        tickets.newlyCreatedTicket = ticket
      }
    },

    createRequestedFailed: (tickets, action) => {
      tickets.loading = false;
    },

    updateRequested: (tickets, action) => {
      tickets.loading = false;
    },

    updateReceived: (tickets, action) => {
      const updatedTicket = action.payload.ticket;

      if (action.requestType === "title-updation") {
        tickets.currentTicketTitle = updatedTicket.title
        tickets.loading = false;
        return;
      }

      if (action.requestType === "content-updation") {
        tickets.currentTicketContent = updatedTicket.content
        tickets.loading = false;
        return;
      }

      if (action.requestType === "metadata-update") {
        tickets.currentTicketMetaAttributes = updatedTicket.metadata
        tickets.loading = false;
        return;
      }

      if (action.requestType === "assignees-updation") {
        tickets.currentTicketAssigneeIds = updatedTicket.assignee_ids
        tickets.loading = false;
        return;
      }

      if (action.requestType === "due-date-updation") {
        tickets.currentTicketDueDate = updatedTicket.due_date
        tickets.loading = false;
        return;
      }

      if (action.requestType === "priority-updation") {
        tickets.currentTicketPriority = updatedTicket.priority
        tickets.loading = false;
        return;
      }

      if (action.requestType === "status-updation") {
        tickets.currentTicketStatus = updatedTicket.status
        tickets.loading = false;
        return;
      }

      if (action.requestType === "sub-ticket-status-updation") {
        const subTicketIndex = tickets.currentTicketSubTickets.findIndex(ticket => ticket.id === updatedTicket.id)
        tickets.currentTicketSubTickets[subTicketIndex] = updatedTicket
        tickets.loading = false;
        return;
      }

      if (action.requestType === "story-points-updation") {
        tickets.currentTicketStoryPoint= updatedTicket.story_points
        tickets.loading = false;
        return;
      }

      // Update the ticket in the main tickets list
      tickets.list.forEach((ticket, index) => {
        if (ticket.id === updatedTicket.id) {
          tickets.list[index] = updatedTicket;
        }
      });

      // If the currentTicket exists and its id matches the updated ticket's id, update currentTicket
      if (tickets.currentTicket && tickets.currentTicket.id === updatedTicket.id) {
        tickets.currentTicket = updatedTicket;
      }

      // If the selectedTicket exists and its id matches the updated ticket's id, update selectedTicket
      if (tickets.selectedTicket && tickets.selectedTicket.id === updatedTicket.id) {
        tickets.selectedTicket = updatedTicket;
      }

      // If the currentTicket's sub_tickets exist, update them
      if (tickets.currentTicket && tickets.currentTicket.sub_tickets) {
        const updatedCurrentTicketSubTickets = tickets.currentTicket.sub_tickets.map((subTicket) =>
          subTicket.id === updatedTicket.id ? updatedTicket : subTicket
        );

        // If updatedCurrentTicketSubTickets is updated, update currentTicket's sub_tickets
        if (updatedCurrentTicketSubTickets) {
          tickets.currentTicket.sub_tickets = updatedCurrentTicketSubTickets;
        }
      }

      // If the selectedTicket's sub_tickets exist, update them
      if (tickets.selectedTicket && tickets.selectedTicket.sub_tickets) {
        const updatedSelectedTicketSubTickets = tickets.selectedTicket.sub_tickets.map((subTicket) =>
          subTicket.id === updatedTicket.id ? updatedTicket : subTicket
        );

        // If updatedSelectedTicketSubTickets is updated, update selectedTicket's sub_tickets
        if (updatedSelectedTicketSubTickets) {
          tickets.selectedTicket.sub_tickets = updatedSelectedTicketSubTickets;
        }
      }
    },

    updateRequestedFailed: (tickets, action) => {
      tickets.loading = false;
    },

    commentCreationRequested: (tickets, action) => {
      tickets.comment.loading = false;
    },

    commentCreationReceived: (tickets, action) => {
      const comment = action.payload.comment;
      const commentsList = tickets.currentTicketComments;

      if (comment.parent_comment_id) {
        const parentComment = commentsList.find((com) => com.id === comment.parent_comment_id);

        if (parentComment) {
          parentComment.comments.push(comment);
        }
      } else {
        commentsList.push(comment);
      }

      tickets.loading = false;
    },

    commentCreationFailed: (tickets, action) => {
      tickets.loading = false;
    },

    commentUpdationRequested: (tickets, action) => {
      tickets.comment.loading = true;
    },

    commentUpdationReceived: (tickets, action) => {
      const comment = action.payload.comment;
      const commentsList = tickets.currentTicketComments;

      if (comment.parent_comment_id) {
        const parentCommentIndex = commentsList.findIndex((com) => com.id === comment.parent_comment_id);

        if (parentCommentIndex !== -1) {
          const commentIndex = commentsList[parentCommentIndex].comments.findIndex((com) => com.id === comment.id);
          if (commentIndex !== -1) {
            commentsList[parentCommentIndex].comments[commentIndex] = comment;
          }
        }
      } else {
        const commentIndex = commentsList.findIndex((com) => com.id === comment.id);
        if (commentIndex !== -1) {
          commentsList[commentIndex] = comment;
        }
      }
      tickets.loading = false;
    },

    commentUpdationFailed: (tickets, action) => {
      tickets.loading = false;
    },

    ticketDeletionRequested: (tickets, action) => {
      tickets.comment.loading = false;
    },

    ticketDeletionReceived: (tickets, action) => {
      const { id } = action.payload;

      if (action.requestType === "sub-ticket-deletion") {
        tickets.currentTicketSubTickets = tickets.currentTicketSubTickets.filter((ticket) => ticket.id !== id)
        tickets.loading = false
        return;
      }

      // Remove the ticket from the parent ticket's sub_tickets list (if applicable)
      if (tickets.currentTicket?.sub_tickets) {
        tickets.currentTicket.sub_tickets = tickets.currentTicket.sub_tickets.filter((subTicket) => subTicket.id !== id);
      }
      tickets.loading = false;
    },

    ticketDeletionFailed: (tickets, action) => {
      tickets.loading = false;
    },

    commentDeletionRequested: (tickets, action) => {
      tickets.comment.loading = false;
    },

    commentDeletionReceived: (tickets, action) => {
      const commentsList = tickets.currentTicketComments;
      const currentCommentIndex = commentsList.findIndex((com) => com.id === action.payload.id);
      commentsList.splice(currentCommentIndex, 1);
      tickets.loading = false;
    },

    commentDeletionFailed: (tickets, action) => {
      tickets.loading = false;
    },

    onToggleTicketCreationModal: (tickets, action) => {
      tickets.showTicketCreationModal = action.payload.open;
    },

    onChangeNewTicketKeys: (tickets, action) => {
      tickets.newTicket[action.payload.key] = action.payload.value;
    },

    onRemoveCurrentTicket: (tickets, action) => {
      tickets.currentTicket = null;
    },

    updateCurrentBucket: (tickets, action) => {
      tickets.currenPagetBucket = action.payload.bucket;
    },

    timelineRequested: (state, action) => {
      state.loading = false;
    },

    timelineReceived: (state, action) => {
      state.currentTicketTimeline = action.payload.timeline?.timeline || []
    },

    timelineRequestFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;

export const {
  requested,
  received,
  requestFailed,
  createRequested,
  createReceived,
  createRequestedFailed,
  updateRequested,
  updateReceived,
  updateRequestedFailed,
  ticketDeletionRequested,
  ticketDeletionReceived,
  ticketDeletionFailed,
  onToggleTicketCreationModal,
  onChangeNewTicketKeys,
  onRemoveCurrentTicket,
  updateCurrentBucket,
  commentCreationRequested,
  commentCreationReceived,
  commentCreationFailed,
  commentUpdationRequested,
  commentUpdationReceived,
  commentUpdationFailed,
  commentDeletionRequested,
  commentDeletionReceived,
  commentDeletionFailed,
  timelineRequested,
  timelineReceived,
  timelineRequestFailed,
} = slice.actions;

const url = "v1/tickets";

export const getTickets = (bucketId) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/buckets/${bucketId}/tickets`,
      type: "index",
      onStart: requested.type,
      onSuccess: received.type,
      onError: requestFailed.type,
    })
  );
};

export const getTicketDetails = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.ticketId}`,
      type: "show",
      onStart: requested.type,
      onSuccess: received.type,
      onError: requestFailed.type,
    })
  );
};

export const createTicket = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/buckets/${payload.bucket_id}/tickets`,
      method: "POST",
      data: { ticket: payload },
      type: payload.action_type || 'ceation',
      onStart: createRequested.type,
      onSuccess: createReceived.type,
      onError: createRequestedFailed.type,
      showAlert: true,
      successMessage: 'Ticket created successfully!',
      failureMessage: 'Failed to create the ticket',
    })
  );
};

export const updateTicket = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { ticket: payload },
      type: payload.action_type || "updation",
      onStart: updateRequested.type,
      onSuccess: updateReceived.type,
      onError: updateRequestedFailed.type,
    })
  );
};

export const deleteTicket = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: payload.action_type || "deletion",
      onStart: ticketDeletionRequested.type,
      onSuccess: ticketDeletionReceived.type,
      onError: ticketDeletionFailed.type,
      showAlert: true,
      successMessage: 'Ticket deleted successfully!',
      failureMessage: 'Failed to delete the bucket',
    })
  );
};

export const sendComment = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.ticket_id}/comments`,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: commentCreationRequested.type,
      onSuccess: commentCreationReceived.type,
      onError: commentCreationFailed.type,
    })
  );
};

export const updateComment = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.ticket_id}/comments/${payload.id}`,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: commentUpdationRequested.type,
      onSuccess: commentUpdationReceived.type,
      onError: commentUpdationFailed.type,
    })
  );
};

export const deleteComment = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.ticket_id}/comments/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: commentDeletionRequested.type,
      onSuccess: commentDeletionReceived.type,
      onError: commentDeletionFailed.type,
    })
  );
};

export const getTicketTimeline = (ticketId) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/tickets/${ticketId}/timeline`,
      type: "index",
      onStart: timelineRequested.type,
      onSuccess: timelineReceived.type,
      onError: timelineRequestFailed.type,
    })
  );
};

export const toggleTicketCreationModal = (payload) => (dispatch) => {
  return dispatch(onToggleTicketCreationModal(payload));
};

export const updateNewTicketKeys = (payload) => (dispatch) => {
  return dispatch(onChangeNewTicketKeys(payload));
};

export const removeCurrentTicket = () => (dispatch) => {
  return dispatch(onRemoveCurrentTicket());
};

export const setCurrentPageBucket = (payload) => (dispatch) => {
  return dispatch(updateCurrentBucket(payload));
};
