import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import Logo from "./common/Logo";
import MenuIcon from "@mui/icons-material/MenuRounded";
import useTheme from "@mui/material/styles/useTheme";

function HideOnScroll({ direction, ...props }) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction={direction} in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

const AppPageTitle = ({
  loggedInUser,
  currentPath,
  handleDrawerOpen,
  open,
  renderOnlyTitle = false,
  ...props
}) => {
  const theme = useTheme();
  const getTitle = () => {
    if (currentPath.endsWith("hrms/home") || currentPath.endsWith("hrms")) {
      return "Home";
    } else if (
      currentPath.includes("hrms/employee") &&
      loggedInUser &&
      currentPath.includes(loggedInUser.uid)
    ) {
      return "My Space";
    } else if (currentPath.includes("hrms/my-team")) {
      return "My Team Space";
    } else if (currentPath.includes("hrms/organization-space")) {
      return "Organization Space";
    } else if (currentPath.includes("hrms/organization-policies")) {
      return "Organization Policies";
    } else if (currentPath.includes("hrms/goals")) {
      return "Goal Settings";
    } else if (currentPath.includes("hrms/admin-settings")) {
      return "Admin Settings";
    } else if (currentPath.includes("hrms/finance-management")) {
      return "Finance Management";
    }
  };

  const Title = () => {
    const title = getTitle();

    if (!title) {
      return <Logo handleClick={handleDrawerOpen} onlyIcon={true} />;
    }

    return (
      <Button
        variant="text"
        onClick={handleDrawerOpen}
        color={theme.custom.themeContrastColor}
        // sx={{  ml: -1 }}
        style={{ fontSize: "1.1rem", backgroundColor: "transparent" }}
        startIcon={
          <MenuIcon sx={{ fontSize: "30px !important", marginLeft: "-16px" }} />
        }
      >
        {title}
      </Button>
    );
  };

  if (renderOnlyTitle) {
    return <Title />;
  }

  return (
    <HideOnScroll direction="down" {...props}>
      <AppBar
        elevation={0}
        position="fixed"
        open={open}
        sx={{
          height: "3.325rem !important",
          top: 0,
          bottom: "auto",
          backgroundColor: theme.custom.appBarBackground,
          borderBottom: `0.5px solid ${theme.custom.tabBorderColor}`,
        }}
        color="white"
      >
        <Toolbar sx={{ height: "3.325rem", minHeight: "3.325rem !important" }}>
          <Title />
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default AppPageTitle;
