import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "performance_settings",
  initialState: {
    list: [],
    loading: false,
    saving_loading: false,
    saved: false,
    deletion_loading: false,
  },

  reducers: {
    performanceSettingsRequested: (state, action) => {
      state.loading = true;
    },

    performanceSettingsReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    performanceSettingsRequestFailed: (state, action) => {
      state.loading = false;
    },

    performanceSettingCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    performanceSettingCreationReceived: (state, action) => {
      const { performance_setting } = action.payload;
      if (performance_setting) {
        state.list.push(performance_setting);
      }
      state.saving_loading = false;
      state.saved = true
    },

    performanceSettingCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    performanceSettingUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    performanceSettingUpdationReceived: (state, action) => {
      const { performance_setting } = action.payload;

      const performanceSettingIndex = state.list.findIndex(
        (stat) => stat.id === performance_setting.id
      );
      if (performanceSettingIndex !== -1) {
        state.list[performanceSettingIndex] = performance_setting;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    performanceSettingUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    performanceSettingDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    performanceSettingDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const performanceSettingIndex = state.list.findIndex(
        (dep) => dep.id === id
      );
      state.list.splice(performanceSettingIndex, 1);
      state.deletion_loading = false;
    },

    performanceSettingDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.saving_loading = false;
      state.saved = false;
      state.deletion_loading = false;
    },
  },
});

export default slice.reducer;

const {
  performanceSettingsRequested,
  performanceSettingsReceived,
  performanceSettingsRequestFailed,
  performanceSettingCreationRequested,
  performanceSettingCreationReceived,
  performanceSettingCreationFailed,
  performanceSettingUpdationRequested,
  performanceSettingUpdationReceived,
  performanceSettingUpdationFailed,
  performanceSettingDeletionRequested,
  performanceSettingDeletionReceived,
  performanceSettingDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/performance_settings";

export const loadPerformanceSettings = (payload) => (dispatch) => {
  let endpoint = url;
  if (payload.year) {
    endpoint = `${endpoint}?year=${payload.year}`;
  }
  if (payload.quarters) {
    endpoint = `${endpoint}${payload.year ? "&" : "?"}quarters=${
      payload.quarters
    }`;
  }
  if (payload.employee_level_ids) {
    endpoint = `${endpoint}${
      payload.year || payload.quarters ? "&" : "?"
    }employee_level_ids=${payload.employee_level_ids}`;
  }
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: performanceSettingsRequested.type,
      onSuccess: performanceSettingsReceived.type,
      onError: performanceSettingsRequestFailed.type,
    })
  );
};

export const createPerformanceSetting = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { performance_setting: payload.performance_setting },
      type: "creation",
      onStart: performanceSettingCreationRequested.type,
      onSuccess: performanceSettingCreationReceived.type,
      onError: performanceSettingCreationFailed.type,
      showAlert: true,
      successMessage: "Created succefully!"
    })
  );
};

export const updatePerformanceSetting = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { performance_setting: payload.performance_setting },
      type: "updation",
      onStart: performanceSettingUpdationRequested.type,
      onSuccess: performanceSettingUpdationReceived.type,
      onError: performanceSettingUpdationFailed.type,
      showAlert: true,
      successMessage: "Updated succefully!"
    })
  );
};

export const deletePerformanceSetting = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: performanceSettingDeletionRequested.type,
      onSuccess: performanceSettingDeletionReceived.type,
      onError: performanceSettingDeletionFailed.type,
      showAlert: true,
      successMessage: "Deleted succefully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
