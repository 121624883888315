import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "hrms",
  initialState: {
    total_employees: {},
    new_employees: {},
    today_leaves: {},
    today_attendance_logs: [],
    holidays: [],
    upcoming_holiday: {},
    employee_turnover: {},
    milestones: {},
    pending_actions: [],
    events: [],
    hirings: [],
    loading: false,
  },

  reducers: {
    homePageDataRequested: (state, action) => {
      state.loading = true;
    },

    homePageDataReceived: (state, action) => {
      const { data } = action.payload;
      state.state = action.payload.data;
      state.total_employees = data.total_employees;
      state.today_leaves = data.today_leaves;
      state.new_employees = data.new_employees;
      state.today_attendance_logs = data.today_attendance_logs;
      state.employee_turnover = data.employee_turnover;
      state.holidays = data.holidays;
      state.milestones = data.milestones;
      state.pending_actions = data.pending_actions;
      state.upcoming_holiday = data.holidays.find(
        (holiday) => !holiday.past_holiday
      );
      state.events = data.events;
      state.hirings = data.hirings ?? [];
      state.loading = false;
    },

    homePageDataFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;

const { homePageDataRequested, homePageDataReceived, homePageDataFailed } =
  slice.actions;

const homePageUrl = "v1/hrms/home";

export const getHomePageData = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: homePageUrl,
      onStart: homePageDataRequested.type,
      onSuccess: homePageDataReceived.type,
      onError: homePageDataFailed.type,
    })
  );
};
