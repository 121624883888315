import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "teams",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    teamsRequested: (state, action) => {
      state.loading = true;
    },

    teamsReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    teamsRequestFailed: (state, action) => {
      state.loading = false;
    },

    teamCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    teamCreationReceived: (state, action) => {
      const { team } = action.payload;
      if (team) {
        state.list.push(team);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    teamCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    teamUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    teamUpdationReceived: (state, action) => {
      const { team } = action.payload;

        const teamIndex = state.list.findIndex((stat) => stat.id === team.id);
        if (teamIndex !== -1) {
          state.list[teamIndex] = team
        }
      state.saving_loading = false;
      state.saved = true;
    },

    teamUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    teamDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    teamDeletionReceived: (state, action) => {
      const { id } = action.payload
      const teamIndex = state.list.findIndex((team) => team.id === id);
      state.list.splice(teamIndex, 1);
      state.deletion_loading = false;
    },

    teamDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  teamsRequested,
  teamsReceived,
  teamsRequestFailed,
  teamCreationRequested,
  teamCreationReceived,
  teamCreationFailed,
  teamUpdationRequested,
  teamUpdationReceived,
  teamUpdationFailed,
  teamDeletionRequested,
  teamDeletionReceived,
  teamDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/teams";

export const loadTeams = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: teamsRequested.type,
      onSuccess: teamsReceived.type,
      onError: teamsRequestFailed.type,
    }),
  );
};

export const createTeam = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { team: payload.team },
      type: "creation",
      onStart: teamCreationRequested.type,
      onSuccess: teamCreationReceived.type,
      onError: teamCreationFailed.type,
      showAlert: true,
      successMessage: "Team created successfully!"
    }),
  );
};

export const updateTeam = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { team: payload.team },
      type: "updation",
      onStart: teamUpdationRequested.type,
      onSuccess: teamUpdationReceived.type,
      onError: teamUpdationFailed.type,
      showAlert: true,
      successMessage: "Team updated successfully!"
    }),
  );
};

export const deleteTeam = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: teamDeletionRequested.type,
      onSuccess: teamDeletionReceived.type,
      onError: teamDeletionFailed.type,
      showAlert: true,
      successMessage: "Team deleted successfully!"
    }),
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
