import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "company_leaves",
  initialState: {
    list: [],
    loading: false,
    departmentLeaves: [],
    departmentLeavesLoading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    companyLeavesRequested: (state, action) => {
      state.loading = true;
    },

    companyLeavesReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    companyLeavesRequestFailed: (state, action) => {
      state.loading = false;
    },

    companyLeaveCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyLeaveCreationReceived: (state, action) => {
      const { company_leave } = action.payload;
      if (company_leave) {
        state.list.push(company_leave);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    companyLeaveCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    companyLeaveUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyLeaveUpdationReceived: (state, action) => {
      const { company_leave } = action.payload;

      const companyLeaveIndex = state.list.findIndex(
        (stat) => stat.id === company_leave.id
      );
      if (companyLeaveIndex !== -1) {
        state.list[companyLeaveIndex] = company_leave;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    companyLeaveUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    companyLeaveDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    companyLeaveDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const companyLeaveIndex = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(companyLeaveIndex, 1);
      state.deletion_loading = false;
    },

    companyLeaveDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    loadDepartmentLeavesRequested: (state, action) => {
      state.departmentLeavesLoading = true;
    },

    loadDepartmentLeavesReceived: (state, action) => {
      const { data } = action.payload;
      state.departmentLeaves = data;
      state.departmentLeavesLoading = false;
    },

    loadDepartmentLeavesRequestFailed: (state, action) => {
      state.departmentLeavesLoading = true;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  companyLeavesRequested,
  companyLeavesReceived,
  companyLeavesRequestFailed,
  companyLeaveCreationRequested,
  companyLeaveCreationReceived,
  companyLeaveCreationFailed,
  companyLeaveUpdationRequested,
  companyLeaveUpdationReceived,
  companyLeaveUpdationFailed,
  companyLeaveDeletionRequested,
  companyLeaveDeletionReceived,
  companyLeaveDeletionFailed,
  loadDepartmentLeavesRequested,
  loadDepartmentLeavesReceived,
  loadDepartmentLeavesRequestFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/company_leaves";

export const loadCompanyLeaves = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}?year=${payload.year}`,
      onStart: companyLeavesRequested.type,
      onSuccess: companyLeavesReceived.type,
      onError: companyLeavesRequestFailed.type,
    })
  );
};

export const createCompanyLeave = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { company_leave: payload },
      type: "creation",
      onStart: companyLeaveCreationRequested.type,
      onSuccess: companyLeaveCreationReceived.type,
      onError: companyLeaveCreationFailed.type,
      showAlert: true,
      successMessage: "Leave created successfully!",
    })
  );
};

export const updateCompanyLeave = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { company_leave: payload.leave },
      type: "updation",
      onStart: companyLeaveUpdationRequested.type,
      onSuccess: companyLeaveUpdationReceived.type,
      onError: companyLeaveUpdationFailed.type,
      showAlert: true,
      successMessage: "Leave updated successfully!",
    })
  );
};

export const deleteCompanyLeave = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: companyLeaveDeletionRequested.type,
      onSuccess: companyLeaveDeletionReceived.type,
      onError: companyLeaveDeletionFailed.type,
      showAlert: true,
      successMessage: "Leave deleted successfully!",
    })
  );
};

export const getDepartmentLeaves = (payload) => (dispatch) => {
  const endpoint = `v1/hrms/employees/${payload.employeeId}/leaves/department_leaves`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "POST",
      data: payload.payload,
      onStart: loadDepartmentLeavesRequested.type,
      onSuccess: loadDepartmentLeavesReceived.type,
      onError: loadDepartmentLeavesRequestFailed.type,
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
