import React, { useState, useEffect, useRef } from 'react';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Box from '@mui/material/Box';
import RefreshIcon from '@mui/icons-material/Refresh';
import FadeLoader from "react-spinners/FadeLoader";

const isIOS = () => {
  return (
    /iPad|iPhone|iPod/.test(navigator.userAgent) &&
    !window.MSStream
  );
};

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.includes('safari') && !ua.includes('chrome');
};

const PullToRefresh = ({ children }) => {
  const [isPulling, setIsPulling] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [startY, setStartY] = useState(0);
  const [distance, setDistance] = useState(0);
  const threshold = 300; // Minimum distance to trigger a refresh
  const refreshContainerRef = useRef(null);

  useEffect(() => {
    if (!isIOS() && !isSafari()) return;

    const handleTouchStart = (e) => {
      // Ensure the touch event starts at the top of the container
      if (refreshContainerRef.current.scrollTop === 0 && !refreshing) {
        setStartY(e.touches[0].clientY);
      }
    };

    const handleTouchMove = (e) => {
      // Ensure the touch move event starts at the top of the container
      if (startY && refreshContainerRef.current.scrollTop === 0 && !refreshing) {
        const currentY = e.touches[0].clientY;
        const distance = currentY - startY;
        if (distance > 0) {
          setDistance(distance);
          setIsPulling(true);
        }
      }
    };

    const handleTouchEnd = () => {
      if (distance > threshold) {
        setRefreshing(true);
        window.location.reload();
      } else {
        setIsPulling(false);
        setDistance(0);
        setStartY(0);
      }
    };

    const container = refreshContainerRef.current;
    container.addEventListener('touchstart', handleTouchStart, { passive: true });
    container.addEventListener('touchmove', handleTouchMove, { passive: true });
    container.addEventListener('touchend', handleTouchEnd);

    return () => {
      container.removeEventListener('touchstart', handleTouchStart);
      container.removeEventListener('touchmove', handleTouchMove);
      container.removeEventListener('touchend', handleTouchEnd);
    };
  }, [startY, distance, refreshing]);

  return (
    <div ref={refreshContainerRef} className="refresh-container">
      <SwipeableDrawer
        anchor="top"
        open={isPulling && !refreshing}
        onClose={() => setIsPulling(false)}
        onOpen={() => {}}
        disableSwipeToOpen
        PaperProps={{
          style: {
            height: distance > threshold ? distance : 0,
            transition: 'height 0.3s',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        }}
      >
        {isPulling && (
          distance > threshold ? <RefreshIcon /> : <RefreshIcon />
        )}
      </SwipeableDrawer>
      {refreshing && (
        <Box display="flex" justifyContent="center" alignItems="center" mt={8} mb={0}>
          <FadeLoader color="#6cdaa4" size="15px" height={10} width={3} margin={-5} />
        </Box>
      )}
      {children}
    </div>
  );
};

export default PullToRefresh;
