import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "events",
  initialState: {
    list: [],
    loading: false,
  },

  reducers: {
    eventsRequested: (state, action) => {
      state.loading = true;
    },

    eventsReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    eventsRequestFailed: (state, action) => {
      state.loading = false;
    },

    eventCreationRequested: (state, action) => {
      state.loading = true;
    },

    eventCreationReceived: (state, action) => {
      const { event } = action.payload;
      if (event) {
        state.list.push(event);
      }
      state.loading = false;
    },

    eventCreationFailed: (state, action) => {
      state.loading = false;
    },

    eventUpdationRequested: (state, action) => {
      state.loading = true;
    },

    eventUpdationReceived: (state, action) => {
      const { event } = action.payload;

      const eventIndex = state.list.findIndex((stat) => stat.id === event.id);
      if (eventIndex !== -1) {
        state.list[eventIndex] = event;
      }
      state.loading = false;
    },

    eventUpdationFailed: (state, action) => {
      state.loading = false;
    },

    eventDeletionRequested: (state, action) => {
      state.loading = true;
    },

    eventDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const eventIndex = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(eventIndex, 1);
      state.loading = false;
    },

    eventDeletionFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;

const {
  eventsRequested,
  eventsReceived,
  eventsRequestFailed,
  eventCreationRequested,
  eventCreationReceived,
  eventCreationFailed,
  eventUpdationRequested,
  eventUpdationReceived,
  eventUpdationFailed,
  eventDeletionRequested,
  eventDeletionReceived,
  eventDeletionFailed,
} = slice.actions;

const url = "v1/hrms/events";

export const loadEvents = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: eventsRequested.type,
      onSuccess: eventsReceived.type,
      onError: eventsRequestFailed.type,
    })
  );
};

export const createEvent = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: eventCreationRequested.type,
      onSuccess: eventCreationReceived.type,
      onError: eventCreationFailed.type,
      extraHeaders: { "Content-Type": "multipart/form-data" },
      successMessage: "Event Created Successfully!",
    })
  );
};

export const updateEvent = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.get("id")}`,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: eventUpdationRequested.type,
      onSuccess: eventUpdationReceived.type,
      onError: eventUpdationFailed.type,
      extraHeaders: { "Content-Type": "multipart/form-data" },
      successMessage: "Event Updated Successfully!",
    })
  );
};

export const deleteEvent = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: eventDeletionRequested.type,
      onSuccess: eventDeletionReceived.type,
      onError: eventDeletionFailed.type,
    })
  );
};
