import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TextField from "@mui/material/TextField";
import SelectAssignees from "./SelectAssignees";
import SelectPriority from "./SelectPriority";
import SelectStatus from "./SelectStatus";
import SelectBucket from "./SelectBucket";
import SelectDate from "./SelectDate";
import { getBuckets } from "../../../store/buckets";
import { createTicket } from "../../../store/tickets";
import { getUsers } from "../../../store/users";
import { loadStatuses } from "../../../store/statuses";
import { useDispatch, useSelector } from "react-redux";
import { flattenTheList } from "../../../common/Utils";
import { useNavigate } from 'react-router-dom';

const NewTicketModal = ({ open, onClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [title, setTitle] = useState(null)
  const [assignee_ids, setAssigneeIds] = useState([])
  const [status, setStatus] = useState(null)
  const [bucket_id, setBucketId] = useState(null)
  const [story_points, setStoryPoints] = useState(null)
  const [priority, setPriority] = useState('medium')
  const [due_date, setDueDate] = useState(null)
  const [currentBucket, setCurrentBucket] = useState(null)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const users = useSelector((state) => state.users.list);
  const statuses = useSelector((state) => state.statuses.list);
  const buckets = useSelector((state) => state.buckets.list); 
  const newlyCreatedTicket = useSelector((state) => state.tickets.newlyCreatedTicket);

  useEffect(() => {
    statuses.length < 1 && dispatch(loadStatuses());
    users.length < 1 && dispatch(getUsers());
    buckets.length < 1 && dispatch(getBuckets());
  }, []);

  useEffect(() => {
    const bucket = buckets.find(bucket => bucket.uid === bucket_id)
    if (bucket) {
      setCurrentBucket(bucket)
      setStatus(bucket.status_group?.initial_status?.name || statuses[0]?.name)
    }
  }, [bucket_id])

  useEffect(() => {
    if (newlyCreatedTicket) {
      navigate(`/pm/bucket/${newlyCreatedTicket?.bucket_uid}/ticket/${newlyCreatedTicket?.uid}`)
      handleClose()
    }
  }, [newlyCreatedTicket])

  const statusList = currentBucket?.status_group?.statuses || statuses

  const flattenBuckets = flattenTheList(buckets, "sub_buckets");

  const handleClose = () => {
    setTitle(null)
    setAssigneeIds([])
    setStatus(null)
    setBucketId(null)
    setPriority(null)
    setDueDate(null)
    setStoryPoints(null)
    onClose();
  };

  const handleCreate = async (actionType = null) => {
    await dispatch(
      createTicket({
        title: title,
        bucket_id: bucket_id,
        assignee_ids: assignee_ids,
        priority: priority,
        status: status,
        due_date: due_date,
        story_points: story_points,
        action_type: actionType,
      }),
    );
    if (actionType !== 'create-and-open') {
      handleClose()
    }
  };

  const shouldEnableCreateButton = !!title && title.trim().length && !!bucket_id

  const Header = () => {
    return (
      <DialogTitle
        sx={{
          width: "-webkit-fill-available",
          height: 60,
          borderBottom: "1px solid #e7e8e7",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2px 20px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          Ticket Creation
        </Typography>
        <CloseRoundedIcon onClick={onClose} />
      </DialogTitle>
    );
  };

  const Body = () => {
    return (
      <DialogContent
        key="new-ticket-content"
        sx={{
          width: "-webkit-fill-available",
          padding: "5px 25px",
          marginBottom: "20px",
        }}
      >
        <TextField
          key="new-ticket-title"
          sx={{ marginTop: "1.5rem", width: "-webkit-fill-available" }}
          label="Title"
          multiline
          maxRows={4}
          minRows={2}
          id="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <Box mt={2}>
          <SelectBucket
            buckets={buckets || []}
            flattenList={flattenBuckets || []}
            mt="1rem"
            value={bucket_id}
            handleChange={(value) => setBucketId(value)}
            showClearButton={false}
          />
        </Box>  
        <SelectAssignees
          users={users || []}
          value={assignee_ids}
          mt="1rem"
          handleChange={(value) =>
            setAssigneeIds(
              typeof value === "string" ? value.split(",") : value
            )
          }
        />
        <SelectPriority
          value={priority}
          handleChange={(value) => setPriority(value)}
          mt="1rem"
        />
        <SelectStatus
          statuses={statusList}
          value={status}
          handleChange={(value) => setStatus(value)}
          mt="1rem"
        />
        <SelectDate
          value={due_date}
          handleChange={(value) => setDueDate(value)}
          mt="1rem"
          applyColorCodes={false}
        />
      </DialogContent>
    );
  };

  const Footer = () => {
    return (
      <DialogActions sx={{ borderTop: "1px solid #e7e8e7", height: 60 }}>
        <Button onClick={() => handleCreate()} variant="success-outlined" disabled={!shouldEnableCreateButton}>
          Create
        </Button>
        <Button onClick={() => handleCreate('create-and-open')} variant="success-outlined" disabled={!shouldEnableCreateButton}>
          Create & Open
        </Button>
      </DialogActions>
    );
  };

  return (
    <Dialog
      // fullScreen={fullScreen}
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      key="new-ticket-creation-modal"
      PaperProps={{ sx: { margin: 0, width: "calc(100% - 40px)", minHeight: { xs: "calc(100vh - 100px)", sm: 'inherit' } } }}
    >
        {Header()}
        {Body()}
        {Footer()}
    </Dialog>
  );
};

export default NewTicketModal;
