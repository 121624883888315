import React, { useState, useEffect } from "react";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import FormHelperText from "@mui/material/FormHelperText";
import makeStyles from "@mui/styles/makeStyles";
import Chip from "@mui/material/Chip";
import { profileShortName } from "../../../common/Utils";
import { useSelector } from "react-redux";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
  autocompleteRoot: {
    height: "auto",
  },
  button: {
    maxHeight: "25px",
  },
  chip: {
    maxHeight: "25px",
  },
  tag: {
    maxHeight: "25px",
  },
}));

const SelectAssignees = React.memo(
  ({
    value,
    handleChange,
    mt = 0,
    maxHeight = undefined,
  }) => {
    const [open, setOpen] = useState(true);
    const [selectedUserIds, setSelectedAssineeIds] = useState(value);

    const classes = useStyles();

    const users = useSelector(state => state.users.list)

     useEffect(() => {
      setSelectedAssineeIds(value)
    }, [value]);

    const onChange = (e, values) => {
      const selectedIds = values.map((value) => value.id);
      setSelectedAssineeIds(selectedIds);
    };

    const selectedUsers = users.filter((user) =>
      selectedUserIds.includes(user.id),
    );

    const onRemove = (userId) => {
      const selectedIds = selectedUserIds.filter((id) => id !== userId);
      setSelectedAssineeIds(selectedIds);
      handleChange(selectedIds);
    };

    return (
      <div>
        <FormControl
          id="select-assignees-form-input"
          sx={{ marginTop: mt, width: "-webkit-fill-available" }}
          size="medium"
        >
          <Autocomplete
            classes={{
              root: classes.autocompleteRoot,
              button: classes.button,
              chip: classes.chip,
              tag: classes.tag,
            }}
            multiple
            limitTags={5}
            id="select-assignees-tags"
            options={users}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            onChange={onChange}
            onClose={() => handleChange(selectedUserIds)}
            value={selectedUsers}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.name || profileShortName(option.name)}
                  onDelete={() => onRemove(option.id)}
                  key={index}
                  style={{
                    maxHeight: "25px",
                    marginRight: "8px",
                    marginTop: "8px",
                    backgroundColor: option.profile_color,
                  }}
                />
              ))
            }
            renderOption={(props, option, { selected }) => (
              <li
                {...props}
                style={{ borderBottom: "1px dashed #ccc", paddingBottom: "8px", paddingTop: "8px", maxHeight: '40px' }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Assignees"
                sx={{
                  "& .MuiInputBase-root": {
                    height: "auto",
                  },
                }}
              />
            )}
          />
        </FormControl>
      </div>
    );
  },
);

export default SelectAssignees;
