import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "tags",
  initialState: {
    list: [],
    loading: false,
  },

  reducers: {
    tagsRequested: (state, action) => {
      state.loading = true;
    },

    tagsReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    tagsRequestFailed: (state, action) => {
      state.loading = false;
    },

    tagCreationRequested: (state, action) => {
      state.loading = true;
    },

    tagCreationReceived: (state, action) => {
      const { tag } = action.payload;
      if (tag) {
        state.list.push(tag);
      }
      state.loading = false;
    },

    tagCreationFailed: (state, action) => {
      state.loading = false;
    },

    tagUpdationRequested: (state, action) => {
      state.loading = true;
    },

    tagUpdationReceived: (state, action) => {
      const { tag } = action.payload;

        const tagIndex = state.list.findIndex((stat) => stat.id === tag.id);
        if (tagIndex !== -1) {
          state.list[tagIndex] = tag
        }
      state.loading = false;
    },

    tagUpdationFailed: (state, action) => {
      state.loading = false;
    },

    tagDeletionRequested: (state, action) => {
      state.loading = true;
    },

    tagDeletionReceived: (state, action) => {
      const { id } = action.payload
      const tagIndex = state.list.findIndex((stat) => stat.id === id);
      state.list.splice(tagIndex, 1);
      state.loading = false;
    },

    tagDeletionFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;

const {
  tagsRequested,
  tagsReceived,
  tagsRequestFailed,
  tagCreationRequested,
  tagCreationReceived,
  tagCreationFailed,
  tagUpdationRequested,
  tagUpdationReceived,
  tagUpdationFailed,
  tagDeletionRequested,
  tagDeletionReceived,
  tagDeletionFailed,
} = slice.actions;

const url = "v1/tags";

export const loadTags = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: tagsRequested.type,
      onSuccess: tagsReceived.type,
      onError: tagsRequestFailed.type,
    }),
  );
};

export const createTag = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { tag: payload },
      type: "creation",
      onStart: tagCreationRequested.type,
      onSuccess: tagCreationReceived.type,
      onError: tagCreationFailed.type,
    }),
  );
};

export const updateTag = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { tag: payload },
      type: "updation",
      onStart: tagUpdationRequested.type,
      onSuccess: tagUpdationReceived.type,
      onError: tagUpdationFailed.type,
    }),
  );
};

export const deleteTag = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: tagDeletionRequested.type,
      onSuccess: tagDeletionReceived.type,
      onError: tagDeletionFailed.type,
    }),
  );
};
