import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "buckets",
  initialState: {
    list: [],
    currentBucketId: null,
    currentBucket: null,
    loading: false,
  },

  reducers: {
    requested: (buckets, action) => {
      buckets.loading = true;
    },

    received: (buckets, action) => {
      buckets.list = action.payload.list;
      buckets.loading = false;
    },

    requestFailed: (buckets, action) => {
      buckets.loading = false;
    },

    createRequested: (state, action) => {
      state.loading = true;
    },

    createReceived: (state, action) => {
      const { bucket } = action.payload;

      // Recursive function to find the parent bucket based on parent_bucket_id
      const findParentBucketAndUpdate = (buckets, newBucket, parentBucketId) => {
        for (const bucket of buckets) {
          if (bucket.id === parentBucketId) {
            // If the current bucket matches the parentBucketId, add the newBucket to its sub_buckets array
            if (!bucket.sub_buckets) {
              // If sub_buckets property does not exist, create it and add the newBucket
              bucket.sub_buckets = [newBucket];
            } else {
              // If sub_buckets property already exists, push the newBucket into it
              bucket.sub_buckets.push(newBucket);
            }
            newBucket.parent_ids = [...bucket.parent_ids, parentBucketId];
            return true;
          }
          if (bucket.sub_buckets && bucket.sub_buckets.length > 0) {
            // If the current bucket has sub_buckets, recursively search for the parent bucket and update it
            const found = findParentBucketAndUpdate(
              bucket.sub_buckets,
              newBucket,
              parentBucketId
            );
            if (found) {
              return true;
            }
          }
        }
        return false; // Return false if parent bucket is not found
      };

      // Check if the bucket has a parent_bucket_id
      if (bucket.parent_bucket_id !== null) {
        const parentBucketFound = findParentBucketAndUpdate(
          state.list,
          bucket,
          bucket.parent_bucket_id
        );

        if (!parentBucketFound) {
          console.error(`Parent bucket with id ${bucket.parent_bucket_id} not found.`);
        }
      } else {
        // If the bucket has no parent_bucket_id, it's a top-level bucket
        state.list.push(bucket);
      }
      state.loading = false;
    },

    createRequestedFailed: (state, action) => {
      state.loading = false;
    },

    updateRequested: (state, action) => {
      state.loading = true
    },

    updateReceived: (state, action) => {
      const { bucket } = action.payload;

      // Recursive function to find the bucket based on its id and update it
      const findAndUpdateBucket = (buckets, updatedBucket) => {
        for (let i = 0; i < buckets.length; i++) {
          const currentBucket = buckets[i];
          if (currentBucket.id === updatedBucket.id) {
            // Replace the existing bucket with the updatedBucket
            buckets[i] = updatedBucket;
            return true;
          }
          if (currentBucket.sub_buckets && currentBucket.sub_buckets.length > 0) {
            // If the current bucket has sub_buckets, recursively search for the bucket and update it
            const found = findAndUpdateBucket(currentBucket.sub_buckets, updatedBucket);
            if (found) {
              return true;
            }
          }
        }
        return false; // Return false if bucket is not found
      };

      // Check if the bucket has a parent_bucket_id
      if (bucket.parent_bucket_id !== null) {
        // We should not encounter a situation where the bucket has a parent_bucket_id and needs to be updated,
        // as it should be created first before updating it. But just to be safe, we'll handle the update here.
        const parentBucketFound = findAndUpdateBucket(state.list, bucket);

        if (!parentBucketFound) {
          console.error(`Bucket with id ${bucket.id} not found.`);
        }
      } else {
        // If the bucket has no parent_bucket_id, it's a top-level bucket
        const bucketFound = findAndUpdateBucket(state.list, bucket);

        if (!bucketFound) {
          console.error(`Bucket with id ${bucket.id} not found.`);
        }
      }
      state.loading = false;
    },

    updateRequestedFailed: (state, action) => {
      state.loading = false
    },

    deletionRequested: (state, action) => {
      state.loading = true;
    },

    deletionReceived: (state, action) => {
      const { id } = action.payload;

      // Recursive function to find and delete the bucket based on its id
      const findAndDeleteBucket = (buckets, bucketIdToDelete) => {
        for (let i = 0; i < buckets.length; i++) {
          const currentBucket = buckets[i];
          if (currentBucket.id === bucketIdToDelete) {
            // Remove the bucket from the array
            buckets.splice(i, 1);
            return true;
          }
          if (currentBucket.sub_buckets && currentBucket.sub_buckets.length > 0) {
            // If the current bucket has sub_buckets, recursively search for the bucket and delete it
            const found = findAndDeleteBucket(currentBucket.sub_buckets, bucketIdToDelete);
            if (found) {
              return true;
            }
          }
        }
        return false; // Return false if bucket is not found
      };

      const bucketDeleted = findAndDeleteBucket(state.list, id);

      if (!bucketDeleted) {
        console.error(`Bucket with id ${id} not found.`);
      }

      state.loading = false;
    },

    deletionFailed: (state, action) => {
      state.loading = false;
    },

    updateCurrentBucketId: (buckets, action) => {
      buckets.currentBucketId = action.payload.bucketId;
    },
    updateCurrentBucket: (buckets, action) => {
      buckets.currentBucket = action.payload.bucket;
    },
  },
});

export default slice.reducer;

const {
  requested,
  received,
  requestFailed,
  updateCurrentBucketId,
  updateCurrentBucket,
  createRequested,
  createReceived,
  createRequestedFailed,
  updateRequested,
  updateReceived,
  updateRequestedFailed,
  deletionRequested,
  deletionReceived,
  deletionFailed,
} = slice.actions;

const url = "v1/buckets";

export const getBuckets = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: requested.type,
      onSuccess: received.type,
      onError: requestFailed.type,
    })
  );
};

export const createBucket = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { bucket: payload },
      type: payload.action_type || "creation",
      onStart: createRequested.type,
      onSuccess: createReceived.type,
      onError: createRequestedFailed.type,
      showAlert: true,
      successMessage: 'Bucket created successfully!',
      failureMessage: 'Failed to create the bucket',
    })
  );
};

export const updateBucket = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { bucket: payload },
      type: payload.action_type || "updation",
      onStart: updateRequested.type,
      onSuccess: updateReceived.type,
      onError: updateRequestedFailed.type,
      showAlert: true,
      successMessage: 'Bucket updated successfully!',
      failureMessage: 'Failed to update the bucket',
    })
  );
};

export const deleteBucket = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: payload.action_type || "deletion",
      onStart: deletionRequested.type,
      onSuccess: deletionReceived.type,
      onError: deletionFailed.type,
      showAlert: true,
      successMessage: 'Bucket deleted successfully!',
      failureMessage: 'Failed to delete bucket',
    })
  );
};

export const setCurrentBucketId = (payload) => (dispatch) => {
  return dispatch(updateCurrentBucketId(payload));
};

export const setCurrentBucket = (payload) => (dispatch) => {
  return dispatch(updateCurrentBucket(payload));
};
