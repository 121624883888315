import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "departments",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    departmentsRequested: (state, action) => {
      state.loading = true;
    },

    departmentsReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    departmentsRequestFailed: (state, action) => {
      state.loading = false;
    },

    departmentCreationRequested: (state, action) => {
      state.saving_loading = true;
    },

    departmentCreationReceived: (state, action) => {
      const { department } = action.payload;
      if (department) {
        state.list.push(department);
      }
      state.saving_loading = false;
    },

    departmentCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    departmentUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    departmentUpdationReceived: (state, action) => {
      const { department } = action.payload;

      const departmentIndex = state.list.findIndex(
        (stat) => stat.id === department.id
      );
      if (departmentIndex !== -1) {
        state.list[departmentIndex] = department;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    departmentUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    departmentDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    departmentDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const departmentIndex = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(departmentIndex, 1);
      state.deletion_loading = false;
    },

    departmentDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },
    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  departmentsRequested,
  departmentsReceived,
  departmentsRequestFailed,
  departmentCreationRequested,
  departmentCreationReceived,
  departmentCreationFailed,
  departmentUpdationRequested,
  departmentUpdationReceived,
  departmentUpdationFailed,
  departmentDeletionRequested,
  departmentDeletionReceived,
  departmentDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/departments";

export const loadDepartments = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: departmentsRequested.type,
      onSuccess: departmentsReceived.type,
      onError: departmentsRequestFailed.type,
    })
  );
};

export const createDepartment = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { department: payload },
      type: "creation",
      onStart: departmentCreationRequested.type,
      onSuccess: departmentCreationReceived.type,
      onError: departmentCreationFailed.type,
      showAlert: true,
      successMessage: "Department created successfully!"
    })
  );
};

export const updateDepartment = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { department: payload },
      type: "updation",
      onStart: departmentUpdationRequested.type,
      onSuccess: departmentUpdationReceived.type,
      onError: departmentUpdationFailed.type,
      showAlert: true,
      successMessage: "Department updated successfully!"
    })
  );
};

export const deleteDepartment = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: departmentDeletionRequested.type,
      onSuccess: departmentDeletionReceived.type,
      onError: departmentDeletionFailed.type,
      showAlert: true,
      successMessage: "Role deleted successfully!"
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
