import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "shifts",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    shiftsRequested: (state, action) => {
      state.loading = true;
    },

    shiftsReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    shiftsRequestFailed: (state, action) => {
      state.loading = false;
    },

    shiftCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    shiftCreationReceived: (state, action) => {
      const { shift } = action.payload;
      if (shift) {
        state.list.push(shift);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    shiftCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    shiftUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    shiftUpdationReceived: (state, action) => {
      const { shift } = action.payload;

      const shiftIndex = state.list.findIndex((stat) => stat.id === shift.id);
      if (shiftIndex !== -1) {
        state.list[shiftIndex] = shift;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    shiftUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    shiftDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    shiftDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const shiftIndex = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(shiftIndex, 1);
      state.deletion_loading = false;
    },

    shiftDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  shiftsRequested,
  shiftsReceived,
  shiftsRequestFailed,
  shiftCreationRequested,
  shiftCreationReceived,
  shiftCreationFailed,
  shiftUpdationRequested,
  shiftUpdationReceived,
  shiftUpdationFailed,
  shiftDeletionRequested,
  shiftDeletionReceived,
  shiftDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/shifts";

export const loadShifts = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: shiftsRequested.type,
      onSuccess: shiftsReceived.type,
      onError: shiftsRequestFailed.type,
    })
  );
};

export const createShift = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { shift: payload.payload },
      type: "creation",
      onStart: shiftCreationRequested.type,
      onSuccess: shiftCreationReceived.type,
      onError: shiftCreationFailed.type,
      showAlert: true,
      successMessage: "Shift created successfully!",
    })
  );
};

export const updateShift = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { shift: payload.payload },
      type: "updation",
      onStart: shiftUpdationRequested.type,
      onSuccess: shiftUpdationReceived.type,
      onError: shiftUpdationFailed.type,
      showAlert: true,
      successMessage: "Shift updated successfully!",
    })
  );
};

export const deleteShift = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: shiftDeletionRequested.type,
      onSuccess: shiftDeletionReceived.type,
      onError: shiftDeletionFailed.type,
      showAlert: true,
      successMessage: "Shift deleted successfully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
