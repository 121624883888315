import cryptoRandomString from "crypto-random-string";
import dayjs from "dayjs";
import { QUARTERS } from "../constants";

const ConvertToTitleCase = (txt) => {
  var i, j, str, lowers, uppers;
  str = txt.replace(/_/g, " ").replace(/([^\W_]+[^\s-]*) */g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  lowers = [
    "A",
    "An",
    "The",
    "And",
    "But",
    "Or",
    "For",
    "Nor",
    "As",
    "At",
    "By",
    "For",
    "From",
    "In",
    "Into",
    "Near",
    "Of",
    "On",
    "Onto",
    "To",
    "With",
  ];
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(
      new RegExp("\\s" + lowers[i] + "\\s", "g"),
      function (txt) {
        return txt.toLowerCase();
      }
    );

  // Certain words such as initialisms or acronyms should be left uppercase
  uppers = ["Id", "Tv"];
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(
      new RegExp("\\b" + uppers[i] + "\\b", "g"),
      uppers[i].toUpperCase()
    );

  return str;
};

const flattenArray = (array) => {
  let len = array.length;
  let result = [];
  for (let i = 0; i < len; i++) {
    let currentElement = array[i];

    if (Array.isArray(currentElement)) {
      let flattedArray = flattenArray(currentElement);
      result.push(...flattedArray);
    } else {
      result.push(currentElement);
    }
  }
  return result;
};

const flattenTheList = (arr, childrenKey, depth = Infinity) => {
  let flattenedArray = [];

  arr.forEach((item) => {
    let currentItem = item[childrenKey];
    flattenedArray.push(item);
    if (Array.isArray(currentItem) && depth > 0) {
      flattenedArray = flattenedArray.concat(
        flattenTheList(currentItem, childrenKey, depth - 1)
      );
    } else if (
      typeof currentItem === "object" &&
      currentItem !== null &&
      depth > 0
    ) {
      flattenedArray = flattenedArray.concat(
        flattenTheList(currentItem, childrenKey, depth - 1)
      );
    }
  });

  return flattenedArray;
};

const downloadFile = (url, filename) => {
  fetch(url, { method: "get", mode: "no-cors", referrerPolicy: "no-referrer" })
    .then((res) => res.blob())
    .then((res) => {
      const aElement = document.createElement("a");
      aElement.setAttribute("download", filename);
      const href = URL.createObjectURL(res);
      aElement.href = href;
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    });
};

const getFileObjectUrls = async (files) => {
  const list = [];
  if (files && files.length) {
    const readAllFiles = async () => {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const url = await readFileAsDataURL(file);
        list.push({
          url: url,
          key: generateUniqKey(),
          file: file,
          filename: file?.name,
        });
      }
    };

    await readAllFiles();
  }
  return list;
};

const readFileAsDataURL = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function (readerEvt) {
      const dataURL = readerEvt.target.result;
      resolve(dataURL);
    };
    reader.readAsDataURL(file);
  });
};

const generateUniqKey = () => {
  const key = cryptoRandomString({ length: 40, type: "url-safe" });
  const timestamp = Date.now();
  return `${timestamp}-${key}`;
};

const viewFile = (url, filename) => {
  const aElement = document.createElement("a");
  aElement.setAttribute("download", filename);
  const href = url;
  aElement.href = href;
  aElement.setAttribute("target", "_blank");
  aElement.click();
};

const profileShortName = (name, length = 2) => {
  if (!name) {
    return "ER";
  }
  const words = name.split(" ").filter((word) => /^[A-Za-z]/.test(word)); // Filter out non-alphabetic words

  let avatarName = "";
  if (words.length === 1) {
    return `${words[0][0]}${words[0][1] ? words[0][1] : ""}`.toUpperCase();
  }
  for (const word of words) {
    avatarName += word[0].toUpperCase();
    if (avatarName.length >= length) {
      break;
    }
  }

  return avatarName;
};

const copyToClipboard = (text) => {
  const copyText = () => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  if (navigator.clipboard && navigator.clipboard.writeText) {
    return navigator.clipboard
      .writeText(text)
      .then(() => true)
      .catch((err) => {
        console.error("Failed to copy using navigator.clipboard:", err);
        copyText();
        return true;
      });
  } else {
    try {
      copyText();
      return true;
    } catch (err) {
      console.error("Failed to copy using document.execCommand:", err);
      return false;
    }
  }
};

const webSharing = async (content = null) => {
  try {
    if (navigator.share) {
      await navigator.share({
        title: "Check out this awesome website!",
        text: "I found this cool website that you might like.",
        url: content || window.location.href,
      });
    } else {
      alert("Web Share API not supported in this browser.");
    }
  } catch (error) {
    console.error("Error sharing:", error);
  }
};

const groupArraysByKey = (array, key) => {
  const groupedArrays = {};

  array.forEach((item) => {
    const value = item[key];
    if (!groupedArrays[value]) {
      groupedArrays[value] = [item];
    } else {
      groupedArrays[value].push(item);
    }
  });

  return groupedArrays;
};

const containsText = (name, searchTerm) =>
  name && name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

const removeCookie = (cookieName) => {
  const expirationDate = new Date();
  expirationDate.setTime(expirationDate.getTime() - 1);

  document.cookie = `${cookieName}=; expires=${expirationDate.toUTCString()}; path=/;`;
};

const dateDifference = (startDate, endDate = dayjs(), unit = "years") => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);
  return end.diff(start, unit);
};

const calculateTimeDifference = (
  time1,
  time2,
  isEndTimeSpansToNextDay = false,
  text = ""
) => {
  if (!time1 || !time2) {
    return null;
  }
  const start = dayjs(time1);
  let end = dayjs(time2);

  if (isEndTimeSpansToNextDay && end.isBefore(start)) {
    end = end.add(1, "day");
  }

  let duration = end.diff(start, "minutes");

  if (duration <= 0) {
    return (
      <span style={{ color: "red" }}>
        end time must be greater than the start time
      </span>
    );
  }
  const hours = duration / 60;

  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  if (rminutes < 1) {
    return `${rhours} hrs ${text}`;
  } else {
    return `${rhours} hrs ${rminutes} mins ${text}`;
  }
};

const getOkrReviewQuarter = (currentQuarter) => {
  return { Q2: "Q1", Q3: "Q2", Q4: "Q3", Q1: "Q4" }[currentQuarter];
};

const getOkrReviewYear = (okrStartMonth, ongoingOkrQuarter) => {
  const currentMonth = dayjs().month();
  const currentYear = dayjs().year();

  if (okrStartMonth === 0) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear;
    }
    if (ongoingOkrQuarter === "Q2") {
      return currentYear;
    }
    if (ongoingOkrQuarter === "Q3") {
      return currentYear;
    }
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    }
  }

  if (okrStartMonth === 1) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear;
    } // May, Jun, Jul
    if (ongoingOkrQuarter === "Q2") {
      return currentYear;
    } // Aug, Sep, Oct
    if (ongoingOkrQuarter === "Q3") {
      return [10, 11].includes(currentMonth) ? currentYear : currentYear - 1;
    } // Nov, Dec, Jan
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    } // Feb, Mar, Apr
  }

  if (okrStartMonth === 2) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear;
    } // Jun, Jul, Aug
    if (ongoingOkrQuarter === "Q2") {
      return currentYear;
    } // Sep, Oct, Nov
    if (ongoingOkrQuarter === "Q3") {
      return currentMonth === 11 ? currentYear : currentYear - 1;
    } // Dec, Jan, Feb
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    } // Mar, Apr, May
  }

  if (okrStartMonth === 3) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear;
    } // Jul, Aug, Sep
    if (ongoingOkrQuarter === "Q2") {
      return currentYear;
    } // Oct, Nov, Dec
    if (ongoingOkrQuarter === "Q3") {
      return currentYear - 1;
    } // Jan, Feb, March
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    } // Apr, May, Jun
  }

  if (okrStartMonth === 4) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear;
    } // Aug, Sep, Oct
    if (ongoingOkrQuarter === "Q2") {
      return [10, 11].includes(currentMonth) ? currentYear : currentYear - 1;
    } // Nov, Dec, Jan
    if (ongoingOkrQuarter === "Q3") {
      return currentYear - 1;
    } // Feb, Mar, Apr
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    } // May, Jun, Jul
  }

  if (okrStartMonth === 5) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear;
    } // Sep, Oct, Nov
    if (ongoingOkrQuarter === "Q2") {
      return currentMonth === 11 ? currentYear : currentYear - 1;
    } // Dec, Jan, Feb
    if (ongoingOkrQuarter === "Q3") {
      return currentYear - 1;
    } // Mar, Apr, May
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    } // June, Jul, Aug
  }

  if (okrStartMonth === 6) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear;
    } // Oct, Nov, Dec
    if (ongoingOkrQuarter === "Q2") {
      return currentYear - 1;
    } // Jan, Feb, Mar
    if (ongoingOkrQuarter === "Q3") {
      return currentYear - 1;
    } // Apr, May, Jun
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    } // Jul, Aug, Sep
  }

  if (okrStartMonth === 7) {
    if (ongoingOkrQuarter === "Q1") {
      return [10, 11].includes(currentMonth) ? currentYear : currentYear - 1;
    } // Nov, Dec, Jan
    if (ongoingOkrQuarter === "Q2") {
      return currentYear - 1;
    } // Feb, Mar, Apr
    if (ongoingOkrQuarter === "Q3") {
      return currentYear - 1;
    } // May, Jun, Jul
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    } // Aug, Sep, Oct
  }

  if (okrStartMonth === 8) {
    if (ongoingOkrQuarter === "Q1") {
      return currentMonth === 11 ? currentYear : currentYear - 1;
    } // Dec, Jan, Feb
    if (ongoingOkrQuarter === "Q2") {
      return currentYear - 1;
    } // Mar, Apr, May
    if (ongoingOkrQuarter === "Q3") {
      return currentYear - 1;
    } // Jun, Jul, Aug
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    } // Sep, Oct, Nov
  }

  if (okrStartMonth === 9) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear - 1;
    } // Jan, Feb, Mar
    if (ongoingOkrQuarter === "Q2") {
      return currentYear - 1;
    } // Apr, May, Jun
    if (ongoingOkrQuarter === "Q3") {
      return currentYear - 1;
    } // Jul, Aug, Sep
    if (ongoingOkrQuarter === "Q4") {
      return currentYear - 1;
    } // Oct, Nov, Dec
  }

  if (okrStartMonth === 10) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear - 1;
    } // Feb, Mar, Apr
    if (ongoingOkrQuarter === "Q2") {
      return currentYear - 1;
    } // May, Jun, Jul
    if (ongoingOkrQuarter === "Q3") {
      return currentYear - 1;
    } // Aug, Sep, Oct
    if (ongoingOkrQuarter === "Q4") {
      return [10, 11].includes(currentMonth) ? currentYear : currentYear - 1;
    } // Nov, Dec, Jan
  }

  if (okrStartMonth === 11) {
    if (ongoingOkrQuarter === "Q1") {
      return currentYear - 1;
    } // Mar, Apr, May
    if (ongoingOkrQuarter === "Q2") {
      return currentYear - 1;
    } // Jun, Jul, Aug
    if (ongoingOkrQuarter === "Q3") {
      return currentYear - 1;
    } // Sep, Oct, Nov
    if (ongoingOkrQuarter === "Q4") {
      return currentMonth === 11 ? currentYear : currentYear - 1;
    } // Dec, Jan, Feb
  }
};

const getCurrentOkrQuarter = (okrStartMonth) => {
  const currentMonth = dayjs().month();

  if (okrStartMonth === 0) {
    if (currentMonth >= 0 && currentMonth <= 2) {
      return "Q1";
    }
    if (currentMonth >= 3 && currentMonth <= 5) {
      return "Q2";
    }
    if (currentMonth >= 6 && currentMonth <= 8) {
      return "Q3";
    }
    if (currentMonth >= 9 && currentMonth <= 11) {
      return "Q4";
    }
  }

  if (okrStartMonth === 1) {
    if (currentMonth >= 1 && currentMonth <= 3) {
      return "Q1";
    }
    if (currentMonth >= 4 && currentMonth <= 6) {
      return "Q2";
    }
    if (currentMonth >= 7 && currentMonth <= 9) {
      return "Q3";
    }
    if (currentMonth >= 10 || currentMonth === 0) {
      return "Q4";
    }
  }

  if (okrStartMonth === 2) {
    if (currentMonth >= 2 && currentMonth <= 4) {
      return "Q1";
    }
    if (currentMonth >= 5 && currentMonth <= 7) {
      return "Q2";
    }
    if (currentMonth >= 8 && currentMonth <= 10) {
      return "Q3";
    }
    if (currentMonth >= 11 || currentMonth <= 1) {
      return "Q4";
    }
  }

  if (okrStartMonth === 3) {
    if (currentMonth >= 3 && currentMonth <= 5) {
      return "Q1";
    }
    if (currentMonth >= 6 && currentMonth <= 8) {
      return "Q2";
    }
    if (currentMonth >= 9 && currentMonth <= 11) {
      return "Q3";
    }
    if (currentMonth >= 0 && currentMonth <= 2) {
      return "Q4";
    }
  }

  if (okrStartMonth === 4) {
    if (currentMonth >= 4 && currentMonth <= 6) {
      return "Q1";
    }
    if (currentMonth >= 7 && currentMonth <= 9) {
      return "Q2";
    }
    if (currentMonth >= 10 || currentMonth === 0) {
      return "Q3";
    }
    if (currentMonth >= 1 && currentMonth <= 3) {
      return "Q4";
    }
  }

  if (okrStartMonth === 5) {
    if (currentMonth >= 5 && currentMonth <= 7) {
      return "Q1";
    }
    if (currentMonth >= 8 && currentMonth <= 10) {
      return "Q2";
    }
    if (currentMonth === 11 || currentMonth <= 1) {
      return "Q3";
    }
    if (currentMonth >= 2 && currentMonth <= 4) {
      return "Q4";
    }
  }

  if (okrStartMonth === 6) {
    if (currentMonth >= 6 && currentMonth <= 8) {
      return "Q1";
    }
    if (currentMonth >= 9 && currentMonth <= 11) {
      return "Q2";
    }
    if (currentMonth >= 0 && currentMonth <= 2) {
      return "Q3";
    }
    if (currentMonth >= 3 && currentMonth <= 5) {
      return "Q4";
    }
  }

  if (okrStartMonth === 7) {
    if (currentMonth >= 7 && currentMonth <= 9) {
      return "Q1";
    }
    if (currentMonth >= 10 || currentMonth === 0) {
      return "Q2";
    }
    if (currentMonth >= 1 && currentMonth <= 3) {
      return "Q3";
    }
    if (currentMonth >= 4 && currentMonth <= 6) {
      return "Q4";
    }
  }

  if (okrStartMonth === 8) {
    if (currentMonth >= 8 && currentMonth <= 10) {
      return "Q1";
    }
    if (currentMonth === 11 || currentMonth <= 1) {
      return "Q2";
    }
    if (currentMonth >= 2 && currentMonth <= 4) {
      return "Q3";
    }
    if (currentMonth >= 5 && currentMonth <= 7) {
      return "Q4";
    }
  }

  if (okrStartMonth === 9) {
    if (currentMonth >= 9 && currentMonth <= 11) {
      return "Q1";
    }
    if (currentMonth >= 0 && currentMonth <= 2) {
      return "Q2";
    }
    if (currentMonth >= 3 && currentMonth <= 5) {
      return "Q3";
    }
    if (currentMonth >= 6 && currentMonth <= 8) {
      return "Q4";
    }
  }

  if (okrStartMonth === 10) {
    if (currentMonth >= 10 || currentMonth === 0) {
      return "Q1";
    }
    if (currentMonth >= 1 && currentMonth <= 3) {
      return "Q2";
    }
    if (currentMonth >= 4 && currentMonth <= 6) {
      return "Q3";
    }
    if (currentMonth >= 7 && currentMonth <= 9) {
      return "Q4";
    }
  }

  if (okrStartMonth === 11) {
    if (currentMonth === 11 && currentMonth <= 1) {
      return "Q1";
    }
    if (currentMonth >= 2 && currentMonth <= 4) {
      return "Q2";
    }
    if (currentMonth >= 5 && currentMonth <= 7) {
      return "Q3";
    }
    if (currentMonth >= 8 && currentMonth <= 10) {
      return "Q4";
    }
  }
};

const generateQuarterDropdown = (month, year) => {
  const quarters = [
    { label: "Q1", value: "Q1" },
    { label: "Q2", value: "Q2" },
    { label: "Q3", value: "Q3" },
    { label: "Q4", value: "Q4" },
  ];

  const adjustedQuarters = quarters.map((quarter, index) => {
    let helperText = "";
    let startDate = "";
    let endDate = "";
    const currentYear = year;
    const nextYear = year + 1;

    if (month === 0) {
      if (index === 0) {
        startDate = dayjs(`${currentYear}-01-01`);
        endDate = dayjs(`${currentYear}-03-01`).endOf("month");
        helperText = `${QUARTERS[0][0]} ${startDate.year()} - ${
          QUARTERS[0][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[3];
        startDate = dayjs(`${currentYear}-04-01`);
        endDate = dayjs(`${currentYear}-06-01`).endOf("month");
        helperText = `${QUARTERS[0][0]} ${startDate.year()} - ${
          QUARTERS[0][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[6];
        startDate = dayjs(`${currentYear}-07-01`);
        endDate = dayjs(`${currentYear}-09-01`).endOf("month");
        helperText = `${QUARTERS[0][0]} ${startDate.year()} - ${
          QUARTERS[0][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[9];
        startDate = dayjs(`${currentYear}-10-01`);
        endDate = dayjs(`${currentYear}-12-01`).endOf("month");
        helperText = `${QUARTERS[0][0]} ${startDate.year()} - ${
          QUARTERS[0][1]
        } ${endDate.year()}`;
      }
    } else if (month === 1) {
      if (index === 0) {
        helperText = QUARTERS[1];
        startDate = dayjs(`${currentYear}-02-01`);
        endDate = dayjs(`${currentYear}-04-01`).endOf("month");
        helperText = `${QUARTERS[1][0]} ${startDate.year()} - ${
          QUARTERS[1][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[4];
        startDate = dayjs(`${currentYear}-05-01`);
        endDate = dayjs(`${currentYear}-07-01`).endOf("month");
        helperText = `${QUARTERS[4][0]} ${startDate.year()} - ${
          QUARTERS[4][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[7];
        startDate = dayjs(`${currentYear}-08-01`);
        endDate = dayjs(`${currentYear}-10-01`).endOf("month");
        helperText = `${QUARTERS[7][0]} ${startDate.year()} - ${
          QUARTERS[7][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[10];
        startDate = dayjs(`${currentYear}-11-01`);
        endDate = dayjs(`${nextYear}-01-01`).endOf("month");
        helperText = `${QUARTERS[10][0]} ${startDate.year()} - ${
          QUARTERS[10][1]
        } ${endDate.year()}`;
      }
    } else if (month === 2) {
      if (index === 0) {
        helperText = QUARTERS[2];
        startDate = dayjs(`${currentYear}-03-01`);
        endDate = dayjs(`${currentYear}-05-01`).endOf("month");
        helperText = `${QUARTERS[2][0]} ${startDate.year()} - ${
          QUARTERS[2][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[5];
        startDate = dayjs(`${currentYear}-06-01`);
        endDate = dayjs(`${currentYear}-08-01`).endOf("month");
        helperText = `${QUARTERS[5][0]} ${startDate.year()} - ${
          QUARTERS[5][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[8];
        startDate = dayjs(`${currentYear}-09-01`);
        endDate = dayjs(`${currentYear}-11-01`).endOf("month");
        helperText = `${QUARTERS[8][0]} ${startDate.year()} - ${
          QUARTERS[8][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[11];
        startDate = dayjs(`${currentYear}-12-01`);
        endDate = dayjs(`${nextYear}-02-01`).endOf("month");
        helperText = `${QUARTERS[11][0]} ${startDate.year()} - ${
          QUARTERS[11][1]
        } ${endDate.year()}`;
      }
    } else if (month === 3) {
      if (index === 0) {
        helperText = QUARTERS[3];
        startDate = dayjs(`${currentYear}-04-01`);
        endDate = dayjs(`${currentYear}-06-01`).endOf("month");
        helperText = `${QUARTERS[3][0]} ${startDate.year()} - ${
          QUARTERS[3][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[6];
        startDate = dayjs(`${currentYear}-07-01`);
        endDate = dayjs(`${currentYear}-09-01`).endOf("month");
        helperText = `${QUARTERS[6][0]} ${startDate.year()} - ${
          QUARTERS[6][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[9];
        startDate = dayjs(`${currentYear}-10-01`);
        endDate = dayjs(`${currentYear}-12-01`).endOf("month");
        helperText = `${QUARTERS[9][0]} ${startDate.year()} - ${
          QUARTERS[9][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[0];
        startDate = dayjs(`${nextYear}-01-01`);
        endDate = dayjs(`${nextYear}-03-01`).endOf("month");
        helperText = `${QUARTERS[0][0]} ${startDate.year()} - ${
          QUARTERS[0][1]
        } ${endDate.year()}`;
      }
    } else if (month === 4) {
      if (index === 0) {
        helperText = QUARTERS[4];
        startDate = dayjs(`${currentYear}-05-01`);
        endDate = dayjs(`${currentYear}-07-01`).endOf("month");
        helperText = `${QUARTERS[4][0]} ${startDate.year()} - ${
          QUARTERS[4][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[7];
        startDate = dayjs(`${currentYear}-08-01`);
        endDate = dayjs(`${currentYear}-10-01`).endOf("month");
        helperText = `${QUARTERS[7][0]} ${startDate.year()} - ${
          QUARTERS[7][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[10];
        startDate = dayjs(`${currentYear}-11-01`);
        endDate = dayjs(`${nextYear}-01-01`).endOf("month");
        helperText = `${QUARTERS[10][0]} ${startDate.year()} - ${
          QUARTERS[10][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[1];
        startDate = dayjs(`${nextYear}-02-01`);
        endDate = dayjs(`${nextYear}-04-01`).endOf("month");
        helperText = `${QUARTERS[1][0]} ${startDate.year()} - ${
          QUARTERS[1][1]
        } ${endDate.year()}`;
      }
    } else if (month === 5) {
      if (index === 0) {
        helperText = QUARTERS[5];
        startDate = dayjs(`${currentYear}-06-01`);
        endDate = dayjs(`${currentYear}-08-01`).endOf("month");
        helperText = `${QUARTERS[5][0]} ${startDate.year()} - ${
          QUARTERS[5][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[8];
        startDate = dayjs(`${currentYear}-09-01`);
        endDate = dayjs(`${currentYear}-11-01`).endOf("month");
        helperText = `${QUARTERS[8][0]} ${startDate.year()} - ${
          QUARTERS[8][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[11];
        startDate = dayjs(`${currentYear}-12-01`);
        endDate = dayjs(`${nextYear}-02-01`).endOf("month");
        helperText = `${QUARTERS[11][0]} ${startDate.year()} - ${
          QUARTERS[11][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[2];
        startDate = dayjs(`${nextYear}-03-01`);
        endDate = dayjs(`${nextYear}-05-01`).endOf("month");
        helperText = `${QUARTERS[2][0]} ${startDate.year()} - ${
          QUARTERS[2][1]
        } ${endDate.year()}`;
      }
    } else if (month === 6) {
      if (index === 0) {
        helperText = QUARTERS[6];
        startDate = dayjs(`${currentYear}-07-01`);
        endDate = dayjs(`${currentYear}-09-01`).endOf("month");
        helperText = `${QUARTERS[6][0]} ${startDate.year()} - ${
          QUARTERS[6][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[9];
        startDate = dayjs(`${currentYear}-10-01`);
        endDate = dayjs(`${currentYear}-12-01`).endOf("month");
        helperText = `${QUARTERS[9][0]} ${startDate.year()} - ${
          QUARTERS[9][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[0];
        startDate = dayjs(`${nextYear}-01-01`);
        endDate = dayjs(`${nextYear}-03-01`).endOf("month");
        helperText = `${QUARTERS[0][0]} ${startDate.year()} - ${
          QUARTERS[0][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[3];
        startDate = dayjs(`${nextYear}-04-01`);
        endDate = dayjs(`${nextYear}-06-01`).endOf("month");
        helperText = `${QUARTERS[3][0]} ${startDate.year()} - ${
          QUARTERS[3][1]
        } ${endDate.year()}`;
      }
    } else if (month === 7) {
      if (index === 0) {
        helperText = QUARTERS[7];
        startDate = dayjs(`${currentYear}-08-01`);
        endDate = dayjs(`${currentYear}-10-01`).endOf("month");
        helperText = `${QUARTERS[7][0]} ${startDate.year()} - ${
          QUARTERS[7][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[10];
        startDate = dayjs(`${currentYear}-11-01`);
        endDate = dayjs(`${nextYear}-01-01`).endOf("month");
        helperText = `${QUARTERS[10][0]} ${startDate.year()} - ${
          QUARTERS[10][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[1];
        startDate = dayjs(`${nextYear}-02-01`);
        endDate = dayjs(`${nextYear}-04-01`).endOf("month");
        helperText = `${QUARTERS[1][0]} ${startDate.year()} - ${
          QUARTERS[1][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[4];
        startDate = dayjs(`${nextYear}-05-01`);
        endDate = dayjs(`${nextYear}-07-01`).endOf("month");
        helperText = `${QUARTERS[4][0]} ${startDate.year()} - ${
          QUARTERS[4][1]
        } ${endDate.year()}`;
      }
    } else if (month === 8) {
      if (index === 0) {
        helperText = QUARTERS[8];
        startDate = dayjs(`${currentYear}-09-01`);
        endDate = dayjs(`${currentYear}-11-01`).endOf("month");
        helperText = `${QUARTERS[8][0]} ${startDate.year()} - ${
          QUARTERS[8][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[11];
        startDate = dayjs(`${currentYear}-12-01`);
        endDate = dayjs(`${nextYear}-02-01`).endOf("month");
        helperText = `${QUARTERS[11][0]} ${startDate.year()} - ${
          QUARTERS[11][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[2];
        startDate = dayjs(`${nextYear}-03-01`);
        endDate = dayjs(`${nextYear}-05-01`).endOf("month");
        helperText = `${QUARTERS[2][0]} ${startDate.year()} - ${
          QUARTERS[2][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[5];
        startDate = dayjs(`${nextYear}-06-01`);
        endDate = dayjs(`${nextYear}-08-01`).endOf("month");
        helperText = `${QUARTERS[5][0]} ${startDate.year()} - ${
          QUARTERS[5][1]
        } ${endDate.year()}`;
      }
    } else if (month === 9) {
      if (index === 0) {
        helperText = QUARTERS[9];
        startDate = dayjs(`${currentYear}-10-01`);
        endDate = dayjs(`${currentYear}-12-01`).endOf("month");
        helperText = `${QUARTERS[9][0]} ${startDate.year()} - ${
          QUARTERS[9][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[0];
        startDate = dayjs(`${nextYear}-01-01`);
        endDate = dayjs(`${nextYear}-03-01`).endOf("month");
        helperText = `${QUARTERS[0][0]} ${startDate.year()} - ${
          QUARTERS[0][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[3];
        startDate = dayjs(`${nextYear}-04-01`);
        endDate = dayjs(`${nextYear}-06-01`).endOf("month");
        helperText = `${QUARTERS[3][0]} ${startDate.year()} - ${
          QUARTERS[3][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[6];
        startDate = dayjs(`${nextYear}-07-01`);
        endDate = dayjs(`${nextYear}-09-01`).endOf("month");
        helperText = `${QUARTERS[6][0]} ${startDate.year()} - ${
          QUARTERS[6][1]
        } ${endDate.year()}`;
      }
    } else if (month === 10) {
      if (index === 0) {
        helperText = QUARTERS[10];
        startDate = dayjs(`${currentYear}-11-01`);
        endDate = dayjs(`${nextYear}-01-01`).endOf("month");
        helperText = `${QUARTERS[10][0]} ${startDate.year()} - ${
          QUARTERS[10][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[1];
        startDate = dayjs(`${nextYear}-02-01`);
        endDate = dayjs(`${nextYear}-04-01`).endOf("month");
        helperText = `${QUARTERS[0][0]} ${startDate.year()} - ${
          QUARTERS[0][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[4];
        startDate = dayjs(`${nextYear}-05-01`);
        endDate = dayjs(`${nextYear}-07-01`).endOf("month");
        helperText = `${QUARTERS[4][0]} ${startDate.year()} - ${
          QUARTERS[4][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[7];
        startDate = dayjs(`${nextYear}-08-01`);
        endDate = dayjs(`${nextYear}-10-01`).endOf("month");
        helperText = `${QUARTERS[7][0]} ${startDate.year()} - ${
          QUARTERS[7][1]
        } ${endDate.year()}`;
      }
    } else if (month === 11) {
      if (index === 0) {
        helperText = QUARTERS[11];
        startDate = dayjs(`${currentYear}-12-01`);
        endDate = dayjs(`${nextYear}-02-01`).endOf("month");
        helperText = `${QUARTERS[11][0]} ${startDate.year()} - ${
          QUARTERS[11][1]
        } ${endDate.year()}`;
      } else if (index === 1) {
        helperText = QUARTERS[2];
        startDate = dayjs(`${nextYear}-03-01`);
        endDate = dayjs(`${nextYear}-05-01`).endOf("month");
        helperText = `${QUARTERS[2][0]} ${startDate.year()} - ${
          QUARTERS[2][1]
        } ${endDate.year()}`;
      } else if (index === 2) {
        helperText = QUARTERS[5];
        startDate = dayjs(`${nextYear}-06-01`);
        endDate = dayjs(`${nextYear}-08-01`).endOf("month");
        helperText = `${QUARTERS[5][0]} ${startDate.year()} - ${
          QUARTERS[5][1]
        } ${endDate.year()}`;
      } else if (index === 3) {
        helperText = QUARTERS[8];
        startDate = dayjs(`${nextYear}-09-01`);
        endDate = dayjs(`${nextYear}-11-01`).endOf("month");
        helperText = `${QUARTERS[8][0]} ${startDate.year()} - ${
          QUARTERS[8][1]
        } ${endDate.year()}`;
      }
    }

    quarter.helper_text = helperText;
    quarter.startDate = startDate;
    quarter.endDate = endDate;

    return quarter;
  });

  return adjustedQuarters;
};

const debounce = (func, delay) => {
  let timer;
  return function (...args) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const convertEmptyObjectToNull = (obj) => {
  if (!obj) {
    return null;
  }

  return isObjectEmpty(obj) ? null : obj;
};

const isObjectEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

const isEmpty = (str) => {
  if (!str) {
    return true;
  } else if (str.trim() === "") {
    return true;
  }
  return false;
};

const capitalizeFirstLetter = (str) => {
  return str ? str[0].toUpperCase() + str.slice(1) : null;
};

const ellipsisStyle = (lineClamp = 2) => {
  return {
    display: "-webkit-box",
    WebkitLineClamp: lineClamp,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };
};

function formatNumberToLakhOrCrore(number) {
  if (number >= 10000000) {
    return (number / 10000000).toFixed(2) + " Cr";
  } else if (number >= 100000) {
    return (number / 100000).toFixed(2) + " L";
  } else {
    return new Intl.NumberFormat("en-IN").format(number);
  }
}

const deepEqualObject = (obj1, obj2) => {
  // Check if both arguments are objects
  if (
    typeof obj1 === "object" &&
    obj1 !== null &&
    typeof obj2 === "object" &&
    obj2 !== null
  ) {
    // Check if both objects have the same number of properties
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
    // Recursively compare each property
    for (const key in obj1) {
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }
      if (!deepEqualObject(obj1[key], obj2[key])) {
        return false;
      }
    }
    return true;
  } else {
    return obj1 === obj2;
  }
};

const deepEqualArray = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false;
  }

  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (!deepEqualObject(arr1[i], arr2[i])) {
      return false;
    }
  }

  return true;
};

const formatDateRange = (startDate, endDate) => {
  const startYear = dayjs(startDate).year();
  const endYear = dayjs(endDate).year();

  let finalStartDate;

  if (startYear === endYear) {
    finalStartDate = dayjs(startDate).format("MMM DD");
  } else {
    finalStartDate = dayjs(startDate).format("ll");
  }

  const finalEndDate = dayjs(endDate).format("ll");

  return `${finalStartDate} - ${finalEndDate}`;
};

function hexToRgba(hex, alpha = 1) {
  hex = hex.replace(/^#/, "");

  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export {
  groupArraysByKey,
  webSharing,
  copyToClipboard,
  ConvertToTitleCase,
  flattenArray,
  flattenTheList,
  downloadFile,
  viewFile,
  getFileObjectUrls,
  profileShortName,
  containsText,
  removeCookie,
  dateDifference,
  calculateTimeDifference,
  generateQuarterDropdown,
  getCurrentOkrQuarter,
  getOkrReviewQuarter,
  getOkrReviewYear,
  debounce,
  convertEmptyObjectToNull,
  isObjectEmpty,
  capitalizeFirstLetter,
  isEmpty,
  ellipsisStyle,
  formatNumberToLakhOrCrore,
  deepEqualObject,
  deepEqualArray,
  formatDateRange,
  hexToRgba,
};
