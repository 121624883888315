import React, { useRef, useState, useEffect } from "react";
import Tooltip from "@mui/material/Tooltip";

const OverflowTip = ({
  onClick,
  children,
  value = undefined,
  showByDefault = false,
}) => {
  const [isOverflowed, setIsOverflow] = useState(false);
  const textElementRef = useRef();

  useEffect(() => {
    setIsOverflow(
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth
    );
  }, []);

  return (
    <Tooltip
      title={value || children}
      disableHoverListener={!showByDefault && !isOverflowed}
    >
      <div
        ref={textElementRef}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        onClick={() => onClick && onClick()}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default OverflowTip;
