import useTheme from "@mui/material/styles/useTheme";
import { hexToRgba } from "../common/Utils";

const HomeIcon = ({
  strokeColor = undefined,
  strokeWidth = "1.5",
  height = "25",
  width = "25",
  active = false,
}) => {
  const theme = useTheme();
  const iconColor = strokeColor || theme.custom.themeContrastColorCode;
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-home-2"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        strokeWidth={strokeWidth}
        stroke={iconColor}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M5 12l-2 0l9 -9l9 9l-2 0" fill={hexToRgba(iconColor, 0.2)} />
        <path
          d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"
          fill={hexToRgba(iconColor, 0.2)}
        />
        <path d="M10 12h4v4h-4z" stroke={iconColor} />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-home-2"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      stroke={iconColor}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
      <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
      <path d="M10 12h4v4h-4z" />
    </svg>
  );
};

export default HomeIcon;
