import React, { useState } from "react";
import {
  Typography,
  Button,
  Box,
  Divider,
  Link,
  Slide,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import OAuthButton from "../../common/OAuthButton";
import { SOCIAL_MEDIA_BUTTONS } from "../../constants";
import LoginIllustrations from "./LoginIllustrations";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { setAlert } from "../../store/alerts";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const LOGIN_URL = `${process.env.REACT_APP_API_URL}/login`;

const LoginMain = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showLoginPage, toggleLoginPage] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(["authToken"]);

  const isSmOrBelow = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(LOGIN_URL, {
        user: { email, password },
      });
      setCookie("authToken", response.headers["authorization"], { path: "/" });
      navigate("/home");
    } catch (error) {
      console.error(error);
      removeCookie("authToken", { path: "/" });
      dispatch(
        setAlert({ message: error.response?.data?.message, type: "error" }),
      );
    }
  };

  const handleGoogleLogin = () => {
    console.log("Login with Google");
  };

  const handleFacebookLogin = () => {
    console.log("Login with Facebook");
  };

  const Content = () => {
    return (
      <Box>
        <LoginIllustrations />
        {!showLoginPage && isSmOrBelow && (
          <Box mt={4}>
            <Button
              size="large"
              variant="contained"
              color="green"
              onClick={() => toggleLoginPage(true)}
              fullWidth
              disableElevation
            >
              Login
            </Button>
          </Box>
        )}
      </Box>
    );
  };

  const Login = () => {
    return (
      <Box px={2} sx={{ maxWidth: "90%" }}>
        <Box>
          <Typography variant="h4">Welcome back!</Typography>
          <Typography variant="caption" sx={{ opacity: 0.5 }}>
            Enhance your efficiency using eorganizer to boost productivity.
          </Typography>
        </Box>
        <Box mt={2}>
          <TextField
            label="Username"
            type="email"
            placeholder="Username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ mr: 0.5 }}>
                  <PersonIcon />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Box>
        <Box mt={2}>
          <TextField
            label="Password"
            type={showPassword ? "text" : "password"}
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment sx={{ mr: 0.5 }}>
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  sx={{ cursor: "pointer" }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Box>
        <Box mt={0.5} sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            variant="link"
            onClick={handleLogin}
            sx={{ color: "#1F51FF" }}
            disableElevation
          >
            Forgot Password?
          </Button>
        </Box>
        <Box mt={2}>
          <Button
            size="large"
            variant="contained"
            color="green"
            onClick={handleLogin}
            fullWidth
            disableElevation
            // disabled={!checked}
          >
            Login
          </Button>
        </Box>
        <Box mt={2}>
          <Divider>or</Divider>
        </Box>
        <Box mt={2} sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
          {SOCIAL_MEDIA_BUTTONS.map((item) => {
            return (
              <OAuthButton
                data={item}
                sx={{ fontWeight: 500, minWidth: "130px" }}
              />
            );
          })}
        </Box>
        <Box mt={2} sx={{ textAlign: "center" }}>
          <Typography variant="body2">
            By logging in, you agree to our{" "}
            <Link
              href="/terms-and-conditions"
              variant="subtitle2"
              underline="none"
            >
              Terms and Conditions
            </Link>
            .
          </Typography>
        </Box>
        <Box mt={2} sx={{ textAlign: "center" }}>
          <Typography variant="body2">
            Don't have an account?{" "}
            <Link href="/signup" variant="subtitle2" underline="none">
              Sign up
            </Link>
            .
          </Typography>
        </Box>
      </Box>
    );
  };

  if (isSmOrBelow) {
    return (
      <Box
        mx={1}
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {showLoginPage ? (
          <Slide direction="left" in={true}>
            {Login()}
          </Slide>
        ) : (
          <Box sx={{ maxWidth: { xs: "90%", sm: "60%" } }} margin="auto">
            {Content()}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ maxWidth: "1200px", margin: "auto" }}>
      <Box
        sx={{ display: "flex", gap: 2, alignItems: "center", height: "100vh" }}
      >
        <Box
          sx={{
            width: { md: "60%", lg: "65%" },
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box sx={{ maxWidth: "60%" }}>{Content()}</Box>
        </Box>
        <Box sx={{ width: { md: "40%", lg: "35%" } }}>{Login()}</Box>
      </Box>
    </Box>
  );
};

export default LoginMain;
