import React, { useState, useEffect } from "react";
import { List, ListItem, Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { getBuckets } from "./store/buckets";
import PlusIcon from "./icons/PlusIcon";
import { useNavigate } from "react-router-dom";
import OverflowTip from "./common/OverflowTip";
import BucketFormModal from "./pages/pm/components/buckets/BucketFormModal";
import NewTicketModal from "./pages/pm/components/NewTicketModal";
import MenuUpIcon from "./icons/MenuUpIcon";
import MenuDownIcon from "./icons/MenuDownIcon";
import useTheme from "@mui/material/styles/useTheme";

const PmSideMenu = ({ onClose, isMobile }) => {
  const [openSubmenus, setOpenSubmenus] = useState([]);
  const [showNewBucketForm, toggleNewBucketForm] = useState(false);
  const [parentBucketIdForNewSubBucket, setParentBucketIdForNewSubBucket] =
    useState(null);
  const [showNewTicketForm, toggleNewTicketForm] = useState(false);
  const level = 0;

  const buckets = useSelector((state) => state.buckets.list);
  const currentBucket = useSelector((state) => state.buckets.currentBucket);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const isMenuItemSelected = (uid) => {
    return uid === currentBucket?.uid;
  };

  useEffect(() => {
    buckets.length < 1 && dispatch(getBuckets());
  }, []);

  const onSelectBucket = (bucket_id) => {
    navigate(`/pm/bucket/${bucket_id}`);
    isMobile && onClose();
  };

  const handleClick = (uid) => {
    if (openSubmenus.includes(uid)) {
      setOpenSubmenus(openSubmenus.filter((subMenuId) => subMenuId !== uid));
    } else {
      setOpenSubmenus([...openSubmenus, uid]);
    }
  };

  const onCreateNewBucket = async (uid) => {
    await setParentBucketIdForNewSubBucket(uid);
    await toggleNewBucketForm(true);
  };

  const activeColor = theme.palette.green?.main;

  const Label = (item) => {
    return (
      <Typography
        variant="h6"
        fontSize="0.95rem"
        fontWeight={400}
        {...(isMenuItemSelected(item.uid) && { color: "#32CD32" })}
        onClick={(event) => {
          handleClick(item.uid);
          onSelectBucket(item.uid);
        }}
      >
        {item.name}
      </Typography>
    );
  };

  const renderMenuItems = (items, currentLevel) => {
    return items.map((item, index) => {
      const hasSubItems = item.sub_buckets && item.sub_buckets.length > 0;
      const isSubMenuOpen = openSubmenus.includes(item.uid);

      const listItemStyle = {
        paddingLeft: currentLevel * 20,
      };

      if (hasSubItems) {
        return (
          <React.Fragment key={item.uid}>
            <ListItem
              button
              style={listItemStyle}
              sx={{
                paddingRight: "2px",
                marginBottom: "8px",
                alignItems: "start",
                // color: isMenuItemSelected(item.uid) ? activeColor : "inherit",
                display: "flex",
                justifyContent: "space-between",
                fontWeight: 400,
              }}
            >
              <Box
                sx={{ display: "flex", width: "85%", alignItems: "flex-start" }}
              >
                <IconButton
                  onClick={() => handleClick(item.uid)}
                  sx={{
                    padding: 0,
                    marginLeft: "0px",
                    marginRight: "7px",
                  }}
                >
                  {isSubMenuOpen ? (
                    <MenuUpIcon
                      height={22}
                      width={22}
                      strokeColor={
                        isMenuItemSelected(item.uid) ? activeColor : undefined
                      }
                    />
                  ) : (
                    <MenuDownIcon
                      height={22}
                      width={22}
                      strokeColor={
                        isMenuItemSelected(item.uid) ? activeColor : undefined
                      }
                    />
                  )}
                </IconButton>
                {Label(item)}
              </Box>
              <OverflowTip showByDefault={true} value="Add new bucket">
                <button
                  style={{
                    border: "none",
                    padding: 0,
                    margin: "0px 3px 0px 0px",
                    background: "transparent",
                  }}
                  onClick={() => onCreateNewBucket(item.uid)}
                >
                  <PlusIcon height={14} width={14} />
                </button>
              </OverflowTip>
            </ListItem>
            <Collapse in={isSubMenuOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {renderMenuItems(item.sub_buckets, currentLevel + 1)}
              </List>
            </Collapse>
          </React.Fragment>
        );
      } else {
        return (
          <ListItem
            button
            key={item.uid}
            style={listItemStyle}
            sx={{
              paddingRight: "2px",
              marginBottom: "8px",
              // color: isMenuItemSelected(item.uid) ? activeColor : "inherit",
              display: "flex",
              justifyContent: "space-between",
              fontWeight: 400,
            }}
          >
            <Box sx={{ width: "80%" }}>{Label(item)}</Box>
            <OverflowTip showByDefault={true} value="Add new bucket">
              <button
                style={{
                  border: "none",
                  padding: 0,
                  margin: "0px 3px 0px 0px",
                  background: "transparent",
                }}
                onClick={() => onCreateNewBucket(item.uid)}
              >
                <PlusIcon height={14} width={14} />
              </button>
            </OverflowTip>
          </ListItem>
        );
      }
    });
  };

  return (
    <Box px={2}>
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItem sx={{ padding: "8px 0px" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="default"
              color="primary"
              onClick={() => toggleNewBucketForm(true)}
              sx={{
                border: "1px dashed #32CD32",
                color: "#32CD32",
                maxHeight: "35px",
                padding: "8px 14px",
                fontSize: "0.575rem",
                minWidth: "30px",
              }}
            >
              + ADD NEW BUCKET
            </Button>
            <Button
              variant="default"
              color="primary"
              onClick={() => {
                toggleNewTicketForm(true);
                isMobile && onClose();
              }}
              sx={{
                marginLeft: "8px",
                border: "1px dashed #32CD32",
                color: "#32CD32",
                maxHeight: "35px",
                padding: "8px 14px",
                fontSize: "0.575rem",
                minWidth: "30px",
              }}
            >
              + ADD NEW TICKET
            </Button>
          </Box>
        </ListItem>
        {renderMenuItems(buckets, level)}
      </List>
      <BucketFormModal
        parentBucketId={parentBucketIdForNewSubBucket}
        open={showNewBucketForm}
        onClose={() => {
          toggleNewBucketForm(false);
          setParentBucketIdForNewSubBucket(null);
        }}
      />
      <NewTicketModal
        open={showNewTicketForm}
        onClose={() => toggleNewTicketForm(false)}
      />
    </Box>
  );
};

export default PmSideMenu;
