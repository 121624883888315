import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "employees_attendances",
  initialState: {
    list: [],
    list_loading: false,
    today_logs: {},
    today_logs_loading: false,
    approval_pending_attendances: [],
    approval_pending_attendances_loading: false,
    action_loading: false,
  },

  reducers: {
    employeesCurrentDateTimeLogsRequested: (state, action) => {
      state.today_logs = {};
      state.today_logs_loading = true;
    },

    employeesCurrentDateTimeLogsReceived: (state, action) => {
      state.today_logs = action.payload.logs;
      state.today_logs_loading = false;
    },

    employeesCurrentDateTimeLogsRequestFailed: (state, action) => {
      state.today_logs_loading = false;
    },

    employeesApprovalPendingAttendancesRequested: (state, action) => {
      state.approval_pending_attendances = [];
      state.approval_pending_attendances_loading = true;
    },

    employeesApprovalPendingAttendancesReceived: (state, action) => {
      state.approval_pending_attendances = action.payload.attendances;
      state.approval_pending_attendances_loading = false;
    },

    employeesApprovalPendingAttendancesRequestFailed: (state, action) => {
      state.approval_pending_attendances_loading = false;
    },

    takeAnActionRequested: (state, action) => {
      state.action_loading = true;
    },

    takeAnActionReceived: (state, action) => {
      const { id, attendance } = action.payload;
      if (action.requestType === "reject" || action.requestType === "approve") {
        state.approval_pending_attendances = state.approval_pending_attendances.filter(
          (item) => item.uid !== id,
        );
      }

      if (state.list.length) {
        const attendanceIndex = state.list.findIndex((item) => item.id === attendance.id);
        if (attendanceIndex !== -1) {
          state.list[attendanceIndex] = attendance;
        }
      }
      state.action_loading = false;
    },

    takeAnActionRequestFailed: (state, action) => {
      state.action_loading = false;
    },
  },
});

export default slice.reducer;

const {
  employeesCurrentDateTimeLogsRequested,
  employeesCurrentDateTimeLogsReceived,
  employeesCurrentDateTimeLogsRequestFailed,
  employeesApprovalPendingAttendancesRequested,
  employeesApprovalPendingAttendancesReceived,
  employeesApprovalPendingAttendancesRequestFailed,
  takeAnActionRequested,
  takeAnActionReceived,
  takeAnActionRequestFailed,
} = slice.actions;

const url = "v1/hrms/employees_attendances";


export const getEmployeesCurrentDateTimeLogs = (payload) => (dispatch) => {
  let endPoint = `${url}/${payload.attendance_of}/today_logs`;
  if (payload.year) {
    endPoint = `${endPoint}?year=${payload.year}`
  }
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesCurrentDateTimeLogsRequested.type,
      onSuccess: employeesCurrentDateTimeLogsReceived.type,
      onError: employeesCurrentDateTimeLogsRequestFailed.type,
    }),
  );
};

export const getEmployeesApprovalPendingAttendances = (payload) => (dispatch) => {
  const endPoint = `${url}/${payload.attendance_of}/approval_pending_attendances`;
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesApprovalPendingAttendancesRequested.type,
      onSuccess: employeesApprovalPendingAttendancesReceived.type,
      onError: employeesApprovalPendingAttendancesRequestFailed.type,
    }),
  );
};

export const takeAnAction = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.attendance_of}/${payload.action}`,
      method: "PATCH",
      data: { id: payload.id, reason: payload.reason },
      type: payload.action,
      onStart: takeAnActionRequested.type,
      onSuccess: takeAnActionReceived.type,
      onError: takeAnActionRequestFailed.type,
      showAlert: true,
    }),
  );
};
