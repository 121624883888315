import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "users",
  initialState: {
    list: [],
    lightWeightList: [],
    user: null,
    currentEmployeeLoading: false,
    loading: false,
    employees_list_loading: false,
    loginLoading: false,
    authKey: null,
    creationLoading: false,
    creationSaved: false,
    updationLoading: false,
    updationSaved: false,
    employees: [],
    lightWeightEmployeesList: [],
    currentEmployeeProfileData: null,
    currentEmployeeProfileDataLoading: false,
    currentEmployeeLeavesSummaryData: {},
    currentEmployeeLeavesSummaryDataLoading: false,
    currentEmployeeLeavesData: [],
    currentEmployeeLeavesDataLoading: false,
    currentEmployeeAttendanceData: [],
    currentEmployeeAttendanceDataLoading: false,
    currentEmployeeMonthAttendanceStatsData: [],
    currentEmployeeMonthAttendanceStatsDataLoading: false,
    currentEmployee30DaysAttendanceStatsData: [],
    currentEmployee30DaysAttendanceStatsDataLoading: false,
    currentEmployee7DaysAttendanceStatsData: [],
    currentEmployee7DaysAttendanceStatsDataLoading: false,
    current_employee_okrs_data: [],
    current_employee_okrs_data_loading: false,
    currentEmployeePerformanceData: {},
    current_employee_performance_data_loading: false,
    currentEmployeeHistoricalPerformanceData: {},
    currentEmployeeHistoricalPerformanceDataLoading: false,
    saveAttandanceLogLoading: false,
    applyLeaveLoading: false,
    save_okr_loading: false,
    okr_deletion_Loading: false,
    savePerformanceLoading: false,
    employeesByType: [],
    employeesByTypeLoading: false,
    currentEmployeeCompOffsData: [],
    currentEmployeeCompOffsDataLoading: null,
    saveCompOffLoading: false,
    compOffSaved: false,
    saveLeaveLoading: false,
    leaveSaved: false,
    feedbackSaved: false,
    review_process_initiated: false,
    ongoing_reviews_data: [],
    ongoing_reviews_data_loading: false,
    employee_ongoing_review_data: null,
    employee_ongoing_review_data_loading: false,
  },

  reducers: {
    loggedInUserDataRequested: (state, action) => {
      state.currentEmployeeLoading = true;
    },

    loggedInUserDataReceived: (state, action) => {
      state.user = action.payload.user;
      state.currentEmployeeLoading = false;
    },

    loggedInUserDataFailed: (state, action) => {
      state.currentEmployeeLoading = false;
    },

    requested: (users, action) => {
      users.loading = true;
    },

    received: (users, action) => {
      const { list } = action.payload;
      if (action.requestType === "minimal-info") {
        users.lightWeightList = list;
      } else {
        users.list = list;
      }

      users.loading = false;
    },

    requestFailed: (users, action) => {
      users.loading = false;
    },

    employeesRequested: (state, action) => {
      state.employees_list_loading = true;
    },

    employeesReceived: (state, action) => {
      const { list } = action.payload;
      if (action.requestType === "minimal-info") {
        state.lightWeightEmployeesList = list;
      } else {
        state.employees = list;
      }

      state.employees_list_loading = false;
    },

    employeesRequestFailed: (state, action) => {
      state.employees_list_loading = false;
    },

    employeesByTypeRequested: (state, action) => {
      state.employeesByTypeLoading = true;
      state.employeesByType = [];
    },

    employeesByTypeReceived: (state, action) => {
      const { list } = action.payload;
      state.employeesByType = list;
      state.employeesByTypeLoading = false;
    },

    employeesByTypeRequestFailed: (state, action) => {
      state.employeesByTypeLoading = false;
    },

    currentEmployeeDataRequested: (state, action) => {
      if (action.requestType === "profile") {
        state.currentEmployeeProfileDataLoading = true;
        state.currentEmployeeProfileData = null;
      } else if (action.requestType === "leaves-summary") {
        state.currentEmployeeLeavesSummaryData = {};
        state.currentEmployeeLeavesSummaryDataLoading = true;
      } else if (action.requestType === "leaves") {
        state.currentEmployeeLeavesData = [];
        state.currentEmployeeLeavesDataLoading = true;
      } else if (action.requestType === "attendance") {
        state.currentEmployeeAttendanceData = [];
        state.currentEmployeeAttendanceDataLoading = true;
      } else if (action.requestType === "month-attendance-stats") {
        state.currentEmployeeMonthAttendanceStatsDataLoading = true;
      } else if (action.requestType === "30days-attendance-stats") {
        state.currentEmployee30DaysAttendanceStatsDataLoading = true;
      } else if (action.requestType === "7days-attendance-stats") {
        state.currentEmployee7DaysAttendanceStatsDataLoading = true;
      } else if (action.requestType === "okrs") {
        state.current_employee_okrs_data = [];
        state.current_employee_okrs_data_loading = true;
      } else if (action.requestType === "current-performance") {
        state.current_employee_performance_data_loading = true;
        state.review_process_initiated = false;
      } else if (action.requestType === "historical-performance") {
        state.currentEmployeeHistoricalPerformanceDataLoading = true;
      }
    },

    currentEmployeeDataReceived: (state, action) => {
      const { data, status } = action.payload;
      if (action.requestType === "profile") {
        state.currentEmployeeProfileData = data;
        state.currentEmployeeProfileDataLoading = false;
      } else if (action.requestType === "leaves-summary") {
        state.currentEmployeeLeavesSummaryData = data;
        state.currentEmployeeLeavesSummaryDataLoading = false;
      } else if (action.requestType === "leaves") {
        state.currentEmployeeLeavesData = data;
        state.currentEmployeeLeavesDataLoading = false;
      } else if (action.requestType === "attendance") {
        state.currentEmployeeAttendanceData = data;
        state.currentEmployeeAttendanceDataLoading = false;
      } else if (action.requestType === "month-attendance-stats") {
        state.currentEmployeeMonthAttendanceStatsData = data;
        state.currentEmployeeMonthAttendanceStatsDataLoading = false;
      } else if (action.requestType === "30days-attendance-stats") {
        state.currentEmployee30DaysAttendanceStatsData = data;
        state.currentEmployee30DaysAttendanceStatsDataLoading = false;
      } else if (action.requestType === "7days-attendance-stats") {
        state.currentEmployee7DaysAttendanceStatsData = data;
        state.currentEmployee7DaysAttendanceStatsDataLoading = false;
      } else if (action.requestType === "okrs") {
        state.current_employee_okrs_data = data;
        state.current_employee_okrs_data_loading = false;
      } else if (action.requestType === "current-performance") {
        if (status === "review_process_initiated") {
          state.currentEmployeePerformanceData = data;
          state.review_process_initiated = true;
        } else if (status === "review_process_not_initiated") {
          state.review_process_initiated = false;
        }
        state.current_employee_performance_data_loading = false;
      } else if (action.requestType === "historical-performance") {
        state.currentEmployeeHistoricalPerformanceData = data;
        state.currentEmployeeHistoricalPerformanceDataLoading = false;
      }
    },

    currentEmployeeDataRequestFailed: (state, action) => {
      if (action.requestType === "profile") {
        state.currentEmployeeProfileDataLoading = false;
      } else if (action.requestType === "leaves-summary") {
        state.currentEmployeeLeavesSummaryDataLoading = true;
      } else if (action.requestType === "leaves") {
        state.currentEmployeeLeavesDataLoading = false;
      } else if (action.requestType === "attendance") {
        state.currentEmployeeAttendanceDataLoading = false;
      } else if (action.requestType === "month-attendance-stats") {
        state.currentEmployeeMonthAttendanceStatsDataLoading = false;
      } else if (action.requestType === "30days-attendance-stats") {
        state.currentEmployee30DaysAttendanceStatsDataLoading = false;
      } else if (action.requestType === "7days-attendance-stats") {
        state.currentEmployee7DaysAttendanceStatsDataLoading = false;
      } else if (action.requestType === "okrs") {
        state.current_employee_okrs_data_loading = false;
      } else if (action.requestType === "current-performance") {
        state.current_employee_performance_data_loading = false;
        state.review_process_initiated = false;
      } else if (action.requestType === "historical-performance") {
        state.currentEmployeeHistoricalPerformanceDataLoading = false;
      }
    },

    employeeCreationRequested: (state, action) => {
      state.creationLoading = true;
      state.creationSaved = false;
    },

    employeeCreationReceived: (state, action) => {
      const { employee } = action.payload;
      state.employees = [employee, ...state.employees];
      state.creationLoading = false;
      state.creationSaved = true;
    },

    employeeCreationFailed: (state, action) => {
      state.creationLoading = false;
      state.creationSaved = false;
    },

    employeeUpdationRequested: (state, action) => {
      state.updationLoading = true;
    },

    employeeUpdationReceived: (state, action) => {
      const { employee } = action.payload;

      // Find the index of the employee to update in the list
      const indexToUpdate = state.employees.findIndex(
        (item) => item.id === employee.id
      );

      if (indexToUpdate !== -1) {
        // Create a new list with the updated employee
        const updatedList = [...state.employees];
        updatedList[indexToUpdate] = employee;

        // Update the state with the new list
        state.employees = updatedList;
      }
      state.updationLoading = false;
    },

    employeeUpdationFailed: (state, action) => {
      state.updationLoading = false;
    },

    loginRequested: (state, action) => {
      state.loginLoading = true;
    },

    loginReceived: (state, action) => {
      const { data } = action.payload;
      state.user = data;
      state.loginLoading = false;
    },

    loginFailed: (state, action) => {
      state.loginLoading = false;
    },

    logoutRequested: (state, action) => {
      state.logoutLoading = true;
    },

    logoutReceived: (state, action) => {
      state.user = null;
      state.logoutLoading = false;
      localStorage.removeItem("authKey");
    },

    logoutFailed: (state, action) => {
      state.logoutLoading = false;
    },

    removeAuthKey: (state, action) => {
      state.authKey = null;
      state.user = null;
      localStorage.removeItem("authKey");
    },

    updateCurrentEmployeeProfileImage: (state, action) => {
      state.currentEmployeeProfileData.profile_image_url = action.payload.url;
    },

    saveAttendanceLogRequest: (state, action) => {
      state.saveAttandanceLogLoading = false;
    },

    applyLeaveRequest: (state, action) => {
      state.applyLeaveLoading = true;
      state.leaveSaved = false;
    },

    applyLeaveReceived: (state, action) => {
      const { leave, summary } = action.payload;

      const profileData = state.currentEmployeeProfileData;

      const { comp_off, type } = leave;

      if (type === "comp-off") {
        const compOffIndex = state.currentEmployeeCompOffsData.findIndex(
          (item) => item.id === comp_off.id
        );

        if (compOffIndex !== -1) {
          state.currentEmployeeCompOffsData[compOffIndex] = comp_off;
        }
      }

      if (profileData?.id === leave.user_id) {
        // Update profile data's leave schedule
        profileData.leaves_schedule.push(leave);

        // Update leave summary if available
        if (
          state.currentEmployeeLeavesSummaryData &&
          typeof summary === "object"
        ) {
          state.currentEmployeeLeavesSummaryData = {
            summary: {
              ...state.currentEmployeeLeavesSummaryData.summary,
              ...summary.summary,
            },
            leaves_schedule:
              summary.leaves_schedule ||
              state.currentEmployeeLeavesSummaryData.leaves_schedule ||
              [],
            department_leaves_schedule:
              summary.department_leaves_schedule ||
              state.currentEmployeeLeavesSummaryData
                .department_leaves_schedule ||
              [],
          };
        }

        // Update currentEmployeeLeavesData
        // Ensure currentEmployeeLeavesData is initialized as an empty array if null/undefined
        if (!state.currentEmployeeLeavesData) {
          state.currentEmployeeLeavesData = [leave];
        } else {
          state.currentEmployeeLeavesData = [
            leave,
            ...state.currentEmployeeLeavesData,
          ];
        }
      }
      state.applyLeaveLoading = false;
      state.leaveSaved = true;
    },

    applyLeaveRequestFailed: (state, action) => {
      state.applyLeaveLoading = false;
    },

    updateLeaveReceived: (state, action) => {
      const { leave: updatedLeave, summary } = action.payload;

      const profileData = state.currentEmployeeProfileData;

      const { comp_off, type } = updatedLeave;

      if (type === "comp-off") {
        const compOffIndex = state.currentEmployeeCompOffsData.findIndex(
          (item) => item.id === comp_off.id
        );

        if (compOffIndex !== -1) {
          state.currentEmployeeCompOffsData[compOffIndex] = comp_off;
        }
      }

      if (profileData?.id === updatedLeave.user_id) {
        // Update profile data's leave schedule
        const updatedLeavesSchedule = profileData.leaves_schedule.map((leave) =>
          leave.id === updatedLeave.id ? updatedLeave : leave
        );
        profileData.leaves_schedule = updatedLeavesSchedule;

        // Update leave summary if available
        if (
          state.currentEmployeeLeavesSummaryData &&
          typeof summary === "object"
        ) {
          state.currentEmployeeLeavesSummaryData = {
            summary: {
              ...state.currentEmployeeLeavesSummaryData.summary,
              ...summary.summary,
            },
            leaves_schedule:
              summary.leaves_schedule ||
              state.currentEmployeeLeavesSummaryData.leaves_schedule ||
              [],
            department_leaves_schedule:
              summary.department_leaves_schedule ||
              state.currentEmployeeLeavesSummaryData
                .department_leaves_schedule ||
              [],
          };
        }

        // Update currentEmployeeLeavesData
        if (state.currentEmployeeLeavesData) {
          // Find and update the leave in the currentEmployeeLeavesData array
          const updatedLeavesData = state.currentEmployeeLeavesData.map(
            (leave) => (leave.id === updatedLeave.id ? updatedLeave : leave)
          );
          state.currentEmployeeLeavesData = updatedLeavesData;
        }
      }
      state.applyLeaveLoading = false;
      state.leaveSaved = true;
    },

    saveAttendanceLogReceived: (state, action) => {
      const { data } = action.payload;

      // Update attendance data by creating a new array with modified attendance
      const updatedAttendance =
        state.currentEmployeeAttendanceData?.map((attendance) => {
          if (attendance.date === data.date) {
            return data;
          }
          return attendance;
        }) || null;

      // Clone the currentEmployeeProfileData to avoid modifying the draft
      const updatedCurrentEmployeeProfileData = {
        ...state.currentEmployeeProfileData,
      };

      if (data.user_id === updatedCurrentEmployeeProfileData.uid) {
        if (
          updatedCurrentEmployeeProfileData.today_attendance.date === data.date
        ) {
          updatedCurrentEmployeeProfileData.today_attendance = data;
        }
      }

      // Clone the currentEmployeeProfileData to avoid modifying the draft
      const updatedLoggedInEmployeeProfileData = {
        ...state.user,
      };

      if (data.user_id === updatedLoggedInEmployeeProfileData.uid) {
        if (
          updatedLoggedInEmployeeProfileData.today_attendance.date === data.date
        ) {
          updatedLoggedInEmployeeProfileData.today_attendance = data;
        }
      }

      return {
        ...state,
        currentEmployeeAttendanceData: updatedAttendance,
        currentEmployeeProfileData: updatedCurrentEmployeeProfileData,
        user: updatedLoggedInEmployeeProfileData,
        saveAttandanceLogLoading: true,
      };
    },

    saveAttendanceLogFailed: (state, action) => {
      state.saveAttandanceLogLoading = true;
    },

    okrCreationRequested: (state, action) => {
      state.save_okr_loading = true;
      state.okrs_saved = false;
    },

    okrCreationReceived: (state, action) => {
      const { okr } = action.payload;
      if (okr) {
        state.current_employee_okrs_data.push(okr);
      }
      state.save_okr_loading = false;
      state.okrs_saved = true;
    },

    okrCreationFailed: (state, action) => {
      state.save_okr_loading = false;
    },

    okrUpdationRequested: (state, action) => {
      state.save_okr_loading = true;
      state.okrs_saved = false;
    },

    okrUpdationReceived: (state, action) => {
      const { okr } = action.payload;

      const okrIndex = state.current_employee_okrs_data.findIndex(
        (item) => item.id === okr.id
      );
      if (okrIndex !== -1) {
        state.current_employee_okrs_data[okrIndex] = okr;
      }
      state.save_okr_loading = false;
      state.okrs_saved = true;
    },

    okrUpdationFailed: (state, action) => {
      state.save_okr_loading = false;
    },

    okrDeletionRequested: (state, action) => {
      state.okr_deletion_Loading = true;
    },

    okrDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const okrIndex = state.current_employee_okrs_data.findIndex(
        (item) => item.id === id
      );
      state.current_employee_okrs_data.splice(okrIndex, 1);
      state.okr_deletion_Loading = false;
    },

    okrDeletionFailed: (state, action) => {
      state.okr_deletion_Loading = false;
    },

    performanceUpdationRequested: (state, action) => {
      state.savePerformanceLoading = true;
      state.feedbackSaved = false;
    },

    performanceUpdationReceived: (state, action) => {
      const { data } = action.payload;
      const status = data.current_reviewer_feedback?.status;
      const feedback_form_id = data.current_reviewer_feedback?.feedback_form_id;

      state.currentEmployeePerformanceData = data;
      state.savePerformanceLoading = false;
      state.feedbackSaved = true;

      if (feedback_form_id && status) {
        const reviewerIndex = state.ongoing_reviews_data.findIndex(
          (item) => item.id === feedback_form_id
        );
        if (reviewerIndex !== -1) {
          state.ongoing_reviews_data[reviewerIndex].status = status;
        }
      }
    },

    performanceUpdationFailed: (state, action) => {
      state.savePerformanceLoading = false;
      state.feedbackSaved = false;
    },

    removeCurrentEmployeePerformanceData: (state, action) => {
      state.currentEmployeePerformanceData = [];
      state.feedbackSaved = false;
    },

    resetActionStates: (state, action) => {
      state.creationSaved = false;
      state.updationSaved = false;
      state.creationLoading = false;
      state.updationLoading = false;
      state.family_member_saved = false;
      state.family_member_deleted = false;
      state.save_okr_loading = false;
      state.okrs_saved = false;
    },

    compOffsDataRequested: (state, action) => {
      state.currentEmployeeCompOffsDataLoading = true;
      state.currentEmployeeCompOffsData = [];
    },

    compOffsDataReceived: (state, action) => {
      const { comp_offs } = action.payload;
      state.currentEmployeeCompOffsDataLoading = false;
      state.currentEmployeeCompOffsData = comp_offs;
    },

    compOffsDataRequestFailed: (state, action) => {
      state.currentEmployeeCompOffsDataLoading = false;
    },

    compOffCreationRequested: (state, action) => {
      state.saveCompOffLoading = true;
      state.compOffSaved = false;
    },

    compOffCreationReceived: (state, action) => {
      const { comp_off } = action.payload;
      state.currentEmployeeCompOffsData.push(comp_off);
      state.saveCompOffLoading = false;
      state.compOffSaved = true;
    },

    compOffCreationFailed: (state, action) => {
      state.saveCompOffLoading = false;
    },

    compOffUpdationRequested: (state, action) => {
      state.saveCompOffLoading = true;
      state.compOffSaved = false;
    },

    compOffUpdationReceived: (state, action) => {
      const { comp_off } = action.payload;
      const compOffIndex = state.currentEmployeeCompOffsData.findIndex(
        (item) => item.id === comp_off.id
      );
      if (compOffIndex !== -1) {
        state.currentEmployeeCompOffsData[compOffIndex] = comp_off;
      }
      state.saveCompOffLoading = false;
      state.compOffSaved = true;
    },

    compOffUpdationFailed: (state, action) => {
      state.saveCompOffLoading = false;
    },

    ongoingReviewsRequested: (state, action) => {
      state.ongoing_reviews_data_loading = true;
    },

    ongoingReviewsReceived: (state, action) => {
      state.ongoing_reviews_data = action.payload.reviews;
      state.ongoing_reviews_data_loading = false;
    },

    ongoingReviewsRequestFailed: (state, action) => {
      state.ongoing_reviews_data_loading = false;
    },

    employeeOngoingReviewDetailsRequested: (state, action) => {
      state.employee_ongoing_review_data_loading = true;
    },

    employeeOngoingReviewDetailsReceived: (state, action) => {
      state.employee_ongoing_review_data = action.payload.employee_performance;
      state.employee_ongoing_review_data_loading = false;
    },

    employeeOngoingReviewDetailsRequestFailed: (state, action) => {
      state.employee_ongoing_review_data_loading = false;
    },

    familyMemberCreationRequested: (state, action) => {
      state.family_member_updation_loading = true;
      state.family_member_saved = false;
    },

    familyMemberCreationReceived: (state, action) => {
      const { family_member } = action.payload;
      if (family_member.user_id === state.currentEmployeeProfileData?.id) {
        state.currentEmployeeProfileData.family_members.push(family_member);
      }
      state.family_member_updation_loading = false;
      state.family_member_saved = true;
    },

    familyMemberCreationFailed: (state, action) => {
      state.family_member_updation_loading = false;
      state.family_member_saved = false;
    },

    familyMemberUpdationRequested: (state, action) => {
      state.family_member_updation_loading = true;
      state.family_member_saved = false;
    },

    familyMemberUpdationReceived: (state, action) => {
      const { family_member } = action.payload;
      if (family_member.user_id === state.currentEmployeeProfileData?.id) {
        const memberIndex =
          state.currentEmployeeProfileData.family_members.findIndex(
            (member) => member.uid === family_member.uid
          );
        if (memberIndex > -1) {
          state.currentEmployeeProfileData.family_members[memberIndex] =
            family_member;
        }
      }
      state.family_member_updation_loading = false;
      state.family_member_saved = true;
    },

    familyMemberUpdationFailed: (state, action) => {
      state.family_member_updation_loading = false;
      state.family_member_saved = false;
    },

    familyMemberDeletionRequested: (state, action) => {
      state.family_member_deletion_loading = true;
      state.family_member_deleted = false;
    },

    familyMemberDeletionReceived: (state, action) => {
      const { employee_id, id } = action.payload;
      if (employee_id === state.currentEmployeeProfileData?.uid) {
        const memberIndex =
          state.currentEmployeeProfileData.family_members.findIndex(
            (member) => member.uid === id
          );
        if (memberIndex > -1) {
          state.currentEmployeeProfileData.family_members.splice(
            memberIndex,
            1
          );
        }
      }
      state.family_member_deletion_loading = false;
      state.family_member_deleted = true;
    },

    familyMemberDeletionFailed: (state, action) => {
      state.family_member_deletion_loading = false;
      state.family_member_deleted = false;
    },
  },
});

export default slice.reducer;

const {
  loggedInUserDataRequested,
  loggedInUserDataReceived,
  loggedInUserDataFailed,
  requested,
  received,
  requestFailed,
  loginRequested,
  loginReceived,
  loginFailed,
  logoutRequested,
  logoutReceived,
  logoutFailed,
  removeAuthKey,
  employeesRequested,
  employeesReceived,
  employeesRequestFailed,
  employeesByTypeRequested,
  employeesByTypeReceived,
  employeesByTypeRequestFailed,
  employeeCreationRequested,
  employeeCreationReceived,
  employeeCreationFailed,
  employeeUpdationRequested,
  employeeUpdationReceived,
  employeeUpdationFailed,
  currentEmployeeDataRequested,
  currentEmployeeDataReceived,
  currentEmployeeDataRequestFailed,
  updateCurrentEmployeeProfileImage,
  saveAttendanceLogRequest,
  saveAttendanceLogReceived,
  saveAttendanceLogFailed,
  applyLeaveRequest,
  applyLeaveReceived,
  applyLeaveRequestFailed,
  updateLeaveReceived,
  okrCreationRequested,
  okrCreationReceived,
  okrCreationFailed,
  okrUpdationRequested,
  okrUpdationReceived,
  okrUpdationFailed,
  okrDeletionRequested,
  okrDeletionReceived,
  okrDeletionFailed,
  performanceUpdationRequested,
  performanceUpdationReceived,
  performanceUpdationFailed,
  removeCurrentEmployeePerformanceData,
  compOffsDataRequested,
  compOffsDataReceived,
  compOffsDataRequestFailed,
  compOffCreationRequested,
  compOffCreationReceived,
  compOffCreationFailed,
  compOffUpdationRequested,
  compOffUpdationReceived,
  compOffUpdationFailed,
  ongoingReviewsRequested,
  ongoingReviewsReceived,
  ongoingReviewsRequestFailed,
  employeeOngoingReviewDetailsRequested,
  employeeOngoingReviewDetailsReceived,
  employeeOngoingReviewDetailsRequestFailed,
  familyMemberCreationRequested,
  familyMemberCreationReceived,
  familyMemberCreationFailed,
  familyMemberUpdationRequested,
  familyMemberUpdationReceived,
  familyMemberUpdationFailed,
  familyMemberDeletionRequested,
  familyMemberDeletionReceived,
  familyMemberDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/users";
const emplyeesUrl = "v1/hrms/employees";

export const getUsers =
  (payload = {}) =>
  (dispatch) => {
    const updatedUrl = payload.minimalInfo
      ? `${url}?minimal_data=${true}`
      : url;
    return dispatch(
      apiCallBegan({
        url: updatedUrl,
        type: payload.minimalInfo ? "minimal-info" : "complete-data",
        onStart: requested.type,
        onSuccess: received.type,
        onError: requestFailed.type,
      })
    );
  };

export const getEmployees =
  (payload = {}) =>
  (dispatch) => {
    const updatedUrl = payload.minimalInfo
      ? `${emplyeesUrl}?minimal_data=${true}`
      : emplyeesUrl;
    return dispatch(
      apiCallBegan({
        url: updatedUrl,
        type: payload.minimalInfo ? "minimal-info" : "complete-data",
        onStart: employeesRequested.type,
        onSuccess: employeesReceived.type,
        onError: employeesRequestFailed.type,
      })
    );
  };

export const getEmployeesByType = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${emplyeesUrl}/${payload.list_for}/list`,
      onStart: employeesByTypeRequested.type,
      onSuccess: employeesByTypeReceived.type,
      onError: employeesByTypeRequestFailed.type,
    })
  );
};

export const getCurrentEmployeeData = (payload) => (dispatch) => {
  let endpoint = `${emplyeesUrl}/${payload.employeeId}`;
  if (payload.component === "profile") {
  } else if (payload.component === "leaves") {
    endpoint = `${endpoint}/leaves?year=${payload.year}`;
  } else if (payload.component === "attendance") {
    endpoint = `${endpoint}/attendances?date_range=${payload.date_range}`;
  } else if (
    [
      "month-attendance-stats",
      "30days-attendance-stats",
      "7days-attendance-stats",
    ].includes(payload.component)
  ) {
    endpoint = `${endpoint}/attendances/stats?date_range=${payload.date_range}`;
  } else if (payload.component === "leaves-summary") {
    endpoint = `${endpoint}/leaves/summary?year=${payload.year}`;
  } else if (payload.component === "okrs") {
    endpoint = `${endpoint}/okrs?performance_timeframe_id=${payload.performance_timeframe_id}`;
  } else if (payload.component === "current-performance") {
    endpoint = `${endpoint}/performance?performance_timeframe_id=${payload.performance_timeframe_id}&reviewer_type=${payload.reviewer_type}&cycle=ongoing`;
    if (payload.reviewer_id) {
      endpoint = `${endpoint}&reviewer_id=${payload.reviewer_id}`;
    }
  } else if (payload.component === "historical-performance") {
    endpoint = `${endpoint}/performance?performance_timeframe_id=${payload.performance_timeframe_id}&cycle=historical`;
    if (payload.quarters) {
      endpoint = `${endpoint}&quarters=${payload.quarters}`;
    }
  }
  return dispatch(
    apiCallBegan({
      url: endpoint,
      type: payload.component,
      onStart: currentEmployeeDataRequested.type,
      onSuccess: currentEmployeeDataReceived.type,
      onError: currentEmployeeDataRequestFailed.type,
    })
  );
};

export const getLoggedIntUserData = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/profile`,
      onStart: loggedInUserDataRequested.type,
      onSuccess: loggedInUserDataReceived.type,
      onError: loggedInUserDataFailed.type,
    })
  );
};

export const loginUser = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "/login",
      method: "POST",
      data: { user: payload },
      type: "login",
      onStart: loginRequested.type,
      onSuccess: loginReceived.type,
      onError: loginFailed.type,
      isLoginRequest: true,
    })
  );
};

export const logoutUser = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "/logout",
      method: "DELETE",
      type: "logout",
      onStart: logoutRequested.type,
      onSuccess: logoutReceived.type,
      onError: logoutFailed.type,
      isLogoutRequest: true,
    })
  );
};

export const createEmployee = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: emplyeesUrl,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: employeeCreationRequested.type,
      onSuccess: employeeCreationReceived.type,
      onError: employeeCreationFailed.type,
    })
  );
};

export const updateEmployee = (payload) => (dispatch) => {
  let endpoint = `${emplyeesUrl}/${payload.id}`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: employeeUpdationRequested.type,
      onSuccess: employeeUpdationReceived.type,
      onError: employeeUpdationFailed.type,
      showAlert: true,
      showErrorAlert: true,
      successMessage: "Employee Details Updated!",
    })
  );
};

export const deleteEmployee = () => (dispatch) => {};

export const invalidateAuthToken = () => (dispatch) => {
  return dispatch(removeAuthKey());
};

export const updateProfileImageUrl = (payload) => (dispatch) => {
  return dispatch(updateCurrentEmployeeProfileImage(payload));
};

export const saveAttendanceLog = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/attendance_checkins`,
      method: "POST",
      data: { attendance: payload.attendance },
      type: "creation",
      onStart: saveAttendanceLogRequest.type,
      onSuccess: saveAttendanceLogReceived.type,
      onError: saveAttendanceLogFailed.type,
    })
  );
};

export const applyLeave = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/leaves`,
      method: "POST",
      data: { leave: payload.leave },
      type: "creation",
      onStart: applyLeaveRequest.type,
      onSuccess: applyLeaveReceived.type,
      onError: applyLeaveRequestFailed.type,
      showErrorAlert: true,
    })
  );
};

export const updateLeaveRequest = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/leaves/${payload.id}`,
      method: "PATCH",
      data: { leave: payload.leave },
      type: "updation",
      onStart: applyLeaveRequest.type,
      onSuccess: updateLeaveReceived.type,
      onError: applyLeaveRequestFailed.type,
      showErrorAlert: true,
    })
  );
};

export const createOkr = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/okrs`,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: okrCreationRequested.type,
      onSuccess: okrCreationReceived.type,
      onError: okrCreationFailed.type,
    })
  );
};

export const updateOkr = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/okrs/${payload.id}`,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: okrUpdationRequested.type,
      onSuccess: okrUpdationReceived.type,
      onError: okrUpdationFailed.type,
    })
  );
};

export const deleteOkr = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/okrs/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: okrDeletionRequested.type,
      onSuccess: okrDeletionReceived.type,
      onError: okrDeletionFailed.type,
      showAlert: true,
      successMessage: "Okr deleted successfully!",
    })
  );
};

export const updatePerformance = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/performance/${payload.action}`,
      method: "PATCH",
      data: { feedback: payload },
      type: "updation",
      onStart: performanceUpdationRequested.type,
      onSuccess: performanceUpdationReceived.type,
      onError: performanceUpdationFailed.type,
      showAlert: true,
    })
  );
};

export const getOngoingReviews = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/performance/ongoing_reviews?performance_timeframe_id=${payload.performance_timeframe_id}`,
      onStart: ongoingReviewsRequested.type,
      onSuccess: ongoingReviewsReceived.type,
      onError: ongoingReviewsRequestFailed.type,
    })
  );
};

export const getEmployeeOngoingPerformanceStatus = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/performances/${payload.employeeId}/employee_ongoing_review`,
      onStart: employeeOngoingReviewDetailsRequested.type,
      onSuccess: employeeOngoingReviewDetailsReceived.type,
      onError: employeeOngoingReviewDetailsRequestFailed.type,
    })
  );
};

export const resetCurrentEmployeePerformanceData = () => (dispatch) => {
  return dispatch(removeCurrentEmployeePerformanceData());
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};

export const getEmployeeCompOffs = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/comp_offs`,
      onStart: compOffsDataRequested.type,
      onSuccess: compOffsDataReceived.type,
      onError: compOffsDataRequestFailed.type,
    })
  );
};

export const createCompOff = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/comp_offs`,
      method: "POST",
      data: payload.data,
      type: "creation",
      onStart: compOffCreationRequested.type,
      onSuccess: compOffCreationReceived.type,
      onError: compOffCreationFailed.type,
      showErrorAlert: true,
    })
  );
};

export const updateCompOff = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employeeId}/comp_offs/${payload.id}`,
      method: "PATCH",
      data: payload.data,
      type: "updation",
      onStart: compOffUpdationRequested.type,
      onSuccess: compOffUpdationReceived.type,
      onError: compOffUpdationFailed.type,
      showErrorAlert: true,
    })
  );
};

export const createFamilyMember = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employee_id}/family_members`,
      method: "POST",
      data: { family_member: payload.family_member },
      type: "creation",
      onStart: familyMemberCreationRequested.type,
      onSuccess: familyMemberCreationReceived.type,
      onError: familyMemberCreationFailed.type,
      showAlert: true,
      successMessage: "Family member added!",
    })
  );
};

export const updateFamilyMember = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employee_id}/family_members/${payload.id}`,
      method: "PATCH",
      data: { family_member: payload.family_member },
      type: "updation",
      onStart: familyMemberUpdationRequested.type,
      onSuccess: familyMemberUpdationReceived.type,
      onError: familyMemberUpdationFailed.type,
      showAlert: true,
      successMessage: "Family member details updated!",
    })
  );
};

export const deleteFamilyMember = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/employees/${payload.employee_id}/family_members/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: familyMemberDeletionRequested.type,
      onSuccess: familyMemberDeletionReceived.type,
      onError: familyMemberDeletionFailed.type,
      showAlert: true,
      successMessage: "Family member deleted!",
    })
  );
};
