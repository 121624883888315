import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "common",
  initialState: {
    countries: [],
    countriesLoading: false,
    companyDetails: null,
    companyDetailsLoaded: false,
    companyDetailsUpdateLoading: false,
    reporting_structure: [],
    reporting_structure_loading: false,
  },

  reducers: {
    countriesDataRequested: (state, action) => {
      state.countriesLoading = true;
    },

    countriesDataReceived: (state, action) => {
      state.countries = action.payload.countries;
      state.countriesLoading = false;
    },

    countriesDataRequestFailed: (state, action) => {
      state.countriesLoading = false;
    },

    companyDetailsRequested: (state, action) => {
      state.companyDetailsLoaded = true;
    },

    companyDetailsReceived: (state, action) => {
      state.companyDetails = action.payload.company;
      state.companyDetailsLoaded = false;
    },

    companyDetailsRequestFailed: (state, action) => {
      state.companyDetailsLoaded = false;
    },

    companyDetailsUpdateRequested: (state, action) => {
      state.companyDetailsUpdateLoading = true;
    },

    companyDetailsUpdateReceived: (state, action) => {
      state.companyDetails = action.payload.company;
      state.companyDetailsUpdateLoading = false;
    },

    companyDetailsUpdateRequestFailed: (state, action) => {
      state.companyDetailsUpdateLoading = false;
    },

    reportingStructureRequested: (state, action) => {
      state.reporting_structure_loaded = true;
    },

    reportingStructureReceived: (state, action) => {
      state.reporting_structure = action.payload.reporting_structure;
      state.reporting_structure_loaded = false;
    },

    reportingStructureRequestFailed: (state, action) => {
      state.reporting_structure_loaded = false;
    },
  },
});

export default slice.reducer;

const {
  countriesDataRequested,
  countriesDataReceived,
  countriesDataRequestFailed,
  companyDetailsRequested,
  companyDetailsReceived,
  companyDetailsRequestFailed,
  companyDetailsUpdateRequested,
  companyDetailsUpdateReceived,
  companyDetailsUpdateRequestFailed,
  reportingStructureRequested,
  reportingStructureReceived,
  reportingStructureRequestFailed,
} = slice.actions;

export const loadCountries = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "v1/eorganizer-data/countries",
      onStart: countriesDataRequested.type,
      onSuccess: countriesDataReceived.type,
      onError: countriesDataRequestFailed.type,
    })
  );
};

export const loadCompanyDetails = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "v1/hrms/companies/show",
      onStart: companyDetailsRequested.type,
      onSuccess: companyDetailsReceived.type,
      onError: companyDetailsRequestFailed.type,
    })
  );
};

export const getReportingStructure = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: "v1/hrms/companies/reporting_structure",
      onStart: reportingStructureRequested.type,
      onSuccess: reportingStructureReceived.type,
      onError: reportingStructureRequestFailed.type,
    })
  );
};

export const updateCompanyDetails = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `v1/hrms/companies/${payload.companyId}`,
      method: "PATCH",
      data: { company: payload.company },
      type: "updation",
      onStart: companyDetailsUpdateRequested.type,
      onSuccess: companyDetailsUpdateReceived.type,
      onError: companyDetailsUpdateRequestFailed.type,
    })
  );
};
