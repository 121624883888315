import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ConvertToTitleCase, flattenArray } from "../../../common/Utils";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import ListSubheader from "@mui/material/ListSubheader";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import FormHelperText from "@mui/material/FormHelperText";
import makeStyles from "@mui/styles/makeStyles";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: "400px", // Set your desired maximum height here
  },
}));

const SelectBucket = ({
  buckets,
  flattenList,
  value,
  handleChange,
  label = "Bucket",
  disabled = false,
  showClearButton = true,
}) => {
  const [searchTerm, setSearchTerm] = useState(null);

  const classes = useStyles();

  const setSearchText = (term) => {
    setSearchTerm(term);
  };

  const containsText = (name) =>
    name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

  const subBucketsContains = (bucket) =>
    bucket.sub_buckets.some(
      (sub_bucket) =>
        containsText(sub_bucket.name) ||
        (sub_bucket.sub_buckets.length > 0 && subBucketsContains(sub_bucket)),
    );

  const filteredBuckets =
    searchTerm && searchTerm.length > 0
      ? buckets.filter(
          (bucket) => containsText(bucket.name) || subBucketsContains(bucket),
        )
      : buckets;

  const generateMenuItems = (list, counter = 0) => {
    let len = list.length;
    let result = [];
    for (let i = 0; i < len; i++) {
      let currentItem = list[i];

      result.push(constructMenuItem(currentItem, counter + 2));

      if (currentItem.sub_buckets && currentItem.sub_buckets.length > 0) {
        let subMenuItems = generateMenuItems(
          currentItem.sub_buckets,
          counter + 2,
        );
        result.push(...subMenuItems);
      }
    }
    return flattenArray(result);
  };

  const constructMenuItem = (item, counter) => {
    const marginLeft = counter * 1;
    return (
      <MenuItem
        sx={{
          borderRadius: "5px",
          paddingTop: "8px",
          paddingBottom: "8px",
          marginLeft: `${marginLeft}rem`,
          marginRight: "1.5rem",
        }}
        key={item.uid}
        value={item.uid}
        selected={value && item.uid === value}
      >
        {item.sub_buckets && item.sub_buckets.length > 0 && (
          <IconButton
            sx={{
              padding: 0,
              marginLeft: "0px",
              marginRight: "5px",
              transform: "rotate(270deg)",
            }}
          >
            <SlideshowOutlinedIcon />
          </IconButton>
        )}
        <ListItemText primary={item.name} />
      </MenuItem>
    );
  };

  const selectedBucket = flattenList.find((i) => i.uid === value);

  return (
    <div>
      <FormControl
        id="select-bucket-form-input"
        sx={{ width: "-webkit-fill-available" }}
        size="medium"
      >
        <InputLabel
          id="select-bucket-label"
          sx={{
            background: selectedBucket ? "#FFF" : "transparent",
            padding: "2px 4px",
          }}
          shrink={selectedBucket}
        >
          {label}
        </InputLabel>
        <Select
          MenuProps={{
            classes: { paper: classes.menuPaper },
          }}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          label={label}
          renderValue={() => {
            return selectedBucket?.name;
          }}
          disabled={disabled}
          IconComponent={() => (
            <>
              {selectedBucket && showClearButton && !disabled ? (
                <IconButton
              onClick={() => handleChange(null)}
              size="small"
              sx={{
                position: 'absolute',
                top: '50%',
                right: 8,
                transform: 'translateY(-50%)',
              }}
            >
              <ClearIcon sx={{ fontSize: '14px' }} />
                </IconButton>
              ) : (
                <ArrowDropDownIcon
                  sx={{ marginRight: "8px", color: "#0000008a" }}
                />
              )}
            </>
          )}
        >
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              fullWidth
              sx={{ marginTop: "8px", marginBottom: "8px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {generateMenuItems(filteredBuckets).map((item) => item)}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectBucket;
