import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "employees_comp_offs",
  initialState: {
    list: [],
    list_loading: false,
    approval_pending_comp_offs: [],
    approval_pending_comp_offs_loading: false,
    action_loading: false,
  },

  reducers: {
    employeesCompOffsRequested: (state, action) => {
      state.list = [];
      state.list_loading = true;
    },

    employeesCompOffsReceived: (state, action) => {
      state.list = action.payload.comp_offs;
      state.list_loading = false;
    },

    employeesCompOffsRequestFailed: (state, action) => {
      state.list_loading = false;
    },

    employeesApprovalPendingCompOffsRequested: (state, action) => {
      state.approval_pending_comp_offs = [];
      state.approval_pending_comp_offs_loading = true;
    },

    employeesApprovalPendingCompOffsReceived: (state, action) => {
      state.approval_pending_comp_offs = action.payload.comp_offs;
      state.approval_pending_comp_offs_loading = false;
    },

    employeesApprovalPendingCompOffsRequestFailed: (state, action) => {
      state.approval_pending_comp_offs_loading = false;
    },

    takeAnActionRequested: (state, action) => {
      state.action_loading = true;
    },

    takeAnActionReceived: (state, action) => {
      const { id, comp_off } = action.payload;
      if (action.requestType === "reject" || action.requestType === "approve") {
        state.approval_pending_comp_offs = state.approval_pending_comp_offs.filter(
          (item) => item.uid !== id,
        );
      }

      if (state.list.length) {
        const compOffIndex = state.list.findIndex((item) => item.id === comp_off.id);
        if (compOffIndex !== -1) {
          state.list[compOffIndex] = comp_off;
        }
      }
      state.action_loading = false;
    },

    takeAnActionRequestFailed: (state, action) => {
      state.action_loading = false;
    },
  },
});

export default slice.reducer;

const {
  employeesCompOffsRequested,
  employeesCompOffsReceived,
  employeesCompOffsRequestFailed,
  employeesApprovalPendingCompOffsRequested,
  employeesApprovalPendingCompOffsReceived,
  employeesApprovalPendingCompOffsRequestFailed,
  takeAnActionRequested,
  takeAnActionReceived,
  takeAnActionRequestFailed,
} = slice.actions;

const url = "v1/hrms/employees_comp_offs";

export const getEmployeesCompOffs = (payload) => (dispatch) => {
  const endPoint = `${url}/${payload.comp_offs_of}/comp_offs?start_date=${payload.start_date}&end_date=${payload.end_date}`;
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesCompOffsRequested.type,
      onSuccess: employeesCompOffsReceived.type,
      onError: employeesCompOffsRequestFailed.type,
    }),
  );
};

export const getEmployeesApprovalPendingCompOffs = (payload) => (dispatch) => {
  const endPoint = `${url}/${payload.comp_offs_of}/approval_pending_comp_offs`;
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesApprovalPendingCompOffsRequested.type,
      onSuccess: employeesApprovalPendingCompOffsReceived.type,
      onError: employeesApprovalPendingCompOffsRequestFailed.type,
    }),
  );
};

export const takeAnAction = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.comp_offs_of}/${payload.action}`,
      method: "PATCH",
      data: { id: payload.id, reason: payload.reason },
      type: payload.action,
      onStart: takeAnActionRequested.type,
      onSuccess: takeAnActionReceived.type,
      onError: takeAnActionRequestFailed.type,
      showAlert: true,
    }),
  );
};
