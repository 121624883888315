import { useState, useEffect } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const ThemeSetting = () => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    setTheme(window.localStorage.getItem("theme") || "light");
  }, []);

  return (
    <FormControlLabel
      value="start"
      labelPlacement="start"
      sx={{ ml: 0 }}
      control={

        <Switch
          size="small"
          checked={theme === "dark"}
          onChange={(e) => {
            e.stopPropagation();
            setTheme(e.target.checked ? "dark" : "light");
            window.localStorage.setItem(
              "theme",
              e.target.checked ? "dark" : "light"
            );
            window.location.reload();
          }}
        />
      }
      label="Dark Theme"
    />
  );
};

export default ThemeSetting;
