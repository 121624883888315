import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "meta_attributes",
  initialState: {
    list: [],
    loading: false,
  },

  reducers: {
    metaAttributesRequested: (state, action) => {
      state.loading = true;
    },

    metaAttributesReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    metaAttributesRequestFailed: (state, action) => {
      state.loading = false;
    },

    metaAttributeCreationRequested: (state, action) => {
      state.loading = true;
    },

    metaAttributeCreationReceived: (state, action) => {
      const { meta_attribute } = action.payload;
      if (meta_attribute) {
        state.list.push(meta_attribute);
      }
      state.loading = false;
    },

    metaAttributeCreationFailed: (state, action) => {
      state.loading = false;
    },

    metaAttributeUpdationRequested: (state, action) => {
      state.loading = true;
    },

    metaAttributeUpdationReceived: (state, action) => {
      const { meta_attribute } = action.payload;

        const metaAttributeIndex = state.list.findIndex((stat) => stat.id === meta_attribute.id);
        if (metaAttributeIndex !== -1) {
          state.list[metaAttributeIndex] = meta_attribute
        }
      state.loading = false;
    },

    metaAttributeUpdationFailed: (state, action) => {
      state.loading = false;
    },

    metaAttributeDeletionRequested: (state, action) => {
      state.loading = true;
    },

    metaAttributeDeletionReceived: (state, action) => {
      const { id } = action.payload
      const metaAttributeIndex = state.list.findIndex((stat) => stat.id === id);
      state.list.splice(metaAttributeIndex, 1);
      state.loading = false;
    },

    metaAttributeDeletionFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;

const {
  metaAttributesRequested,
  metaAttributesReceived,
  metaAttributesRequestFailed,
  metaAttributeCreationRequested,
  metaAttributeCreationReceived,
  metaAttributeCreationFailed,
  metaAttributeUpdationRequested,
  metaAttributeUpdationReceived,
  metaAttributeUpdationFailed,
  metaAttributeDeletionRequested,
  metaAttributeDeletionReceived,
  metaAttributeDeletionFailed,
} = slice.actions;

const url = "v1/meta_attributes";

export const loadMetaAttributes = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: metaAttributesRequested.type,
      onSuccess: metaAttributesReceived.type,
      onError: metaAttributesRequestFailed.type,
    }),
  );
};

export const createMetaAttribute = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { meta_attribute: payload },
      type: "creation",
      onStart: metaAttributeCreationRequested.type,
      onSuccess: metaAttributeCreationReceived.type,
      onError: metaAttributeCreationFailed.type,
    }),
  );
};

export const updateMetaAttribute = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { meta_attribute: payload },
      type: "updation",
      onStart: metaAttributeUpdationRequested.type,
      onSuccess: metaAttributeUpdationReceived.type,
      onError: metaAttributeUpdationFailed.type,
    }),
  );
};

export const deleteMetaAttribute = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: metaAttributeDeletionRequested.type,
      onSuccess: metaAttributeDeletionReceived.type,
      onError: metaAttributeDeletionFailed.type,
    }),
  );
};
