import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "employees_logs",
  initialState: {
    today_logs: {},
    today_logs_loading: false,
    monthly_logs: {},
    monthly_logs_loading: false,
  },

  reducers: {
    employeesTodayLogsRequested: (state, action) => {
      state.today_logs = {};
      state.today_logs_loading = true;
    },

    employeesTodayLogsReceived: (state, action) => {
      state.today_logs = action.payload.logs;
      state.today_logs_loading = false;
    },

    employeesTodayLogsRequestFailed: (state, action) => {
      state.today_logs_loading = false;
    },

    employeesMonthlyLogsRequested: (state, action) => {
      state.monthly_logs = {};
      state.monthly_logs_loading = true;
    },

    employeesMonthlyLogsReceived: (state, action) => {
      state.monthly_logs = action.payload.logs;
      state.monthly_logs_loading = false;
    },

    employeesMonthlyLogsRequestFailed: (state, action) => {
      state.monthly_logs_loading = false;
    },
  },
});

export default slice.reducer;

const {
  employeesTodayLogsRequested,
  employeesTodayLogsReceived,
  employeesTodayLogsRequestFailed,
  employeesMonthlyLogsRequested,
  employeesMonthlyLogsReceived,
  employeesMonthlyLogsRequestFailed,
} = slice.actions;

const url = "v1/hrms/employees_logs";

export const getEmployeesTodayLogs = (payload) => (dispatch) => {
  const endPoint = `${url}/${payload.logs_for}/today_logs`
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesTodayLogsRequested.type,
      onSuccess: employeesTodayLogsReceived.type,
      onError: employeesTodayLogsRequestFailed.type,
    }),
  );
};

export const getEmployeesMonthlyLogs = (payload) => (dispatch) => {
  const endPoint = `${url}/${payload.logs_for}/monthly_logs?year=${payload.year}&month=${payload.month}`
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesMonthlyLogsRequested.type,
      onSuccess: employeesMonthlyLogsReceived.type,
      onError: employeesMonthlyLogsRequestFailed.type,
    }),
  );
};
