import { Routes, Route } from "react-router-dom";
import LoginMain from "./pages/login/LoginMain";
import Home from "./pages/Home";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginMain />} />
      <Route path="/" element={<Home />} />
    </Routes>
  );
};

export default PublicRoutes;