import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import DangerIcon from "../icons/DangerIcon";
import CircleCheckIcon from "../icons/CircleCheckIcon";
import CloseIcon from "../icons/CloseIcon";

const Toasters = () => {
  const alerts = useSelector((state) => state.alerts.list);

  const toastClass = {
    error: {
      class: "toast-error",
      iconColor: "#fb0909",
      icon: <DangerIcon strokeColor="#fb0909" strokeWidth={2} />,
    },
    info: {
      class: "toast-info",
      iconColor: "#0722ef",
      icon: <DangerIcon strokeColor="#0722ef" strokeWidth={2} />,
    },
    warning: {
      class: "toast-warning",
      iconColor: "#ff6700",
      icon: <DangerIcon strokeColor="#ff6700" strokeWidth={2} />,
    },
    success: {
      class: "toast-success",
      iconColor: "#32CD32",
      icon: <CircleCheckIcon strokeColor="#32CD32" strokeWidth={2} />,
    },
  };

  useEffect(() => {
    alerts.map((alert) => {
      toast.error(alert.message, {
        autoClose: alert.timeout || 5000,
        position: toast.POSITION.TOP_CENTER,
        // closeButton: true,
        className:
          toastClass[alert.type]?.class || toastClass["toast-info"].class,
        progressClassName: "toast-progress",
        icon: toastClass[alert.type]?.icon || toastClass["toast-info"].icon,
        closeButton: (
          <CloseIcon
            strokeColor={
              toastClass[alert.type]?.iconColor ||
              toastClass["toast-info"].iconColor
            }
            strokeWidth={1.5}
            height={16}
            width={16}
          />
        ),
      });
    });
  }, [alerts]);

  return (
    <Box>
      <ToastContainer />
    </Box>
  );
};

export default Toasters;
