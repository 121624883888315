import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const images = [
  {
    context:
      "Empower your team with eorganizer. Streamline workflows, enhance collaboration, and drive successful product launches.",
    src: "https://assets.eorganizer.in/landing-pages/product-management.svg",
  },
  {
    context:
      "Simplify employee management, automate tasks, and create a thriving workplace culture.",
    src: "https://assets.eorganizer.in/landing-pages/hrms.svg",
  },
  {
    context:
      "Exclusive document writing website designed for organizational employees. Elevate internal communication, knowledge sharing, and collaboration within your company.",
    src: "https://assets.eorganizer.in/landing-pages/blog-writing.svg",
  },
];

const LoginIllustrations = () => {
  return (
    <Box>
      <Carousel
        autoPlay={true}
        axis="horizontal"
        infiniteLoop
        // centerMode
        showArrows={false}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
      >
        {images.map((step, index) => (
          <Box>
            <img alt="login-illustration" src={step.src} style={{ objectFit: "fill" }} />
            <Typography
              variant="subtitle2"
              sx={{ textAlign: "center", fontSize: "1.2rem", marginBottom: '12px' }}
            >
              ‟{step.context}”
            </Typography>
          </Box>
        ))}
      </Carousel>
    </Box>
  );
};

export default LoginIllustrations;
