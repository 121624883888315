import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "company_objectives",
  initialState: {
    list: [],
    loading: false,
    saving_loading: false,
    saved: false,
    deletion_loading: false,
  },

  reducers: {
    companyObjectivesRequested: (state, action) => {
      state.loading = true;
      state.list = [];
    },

    companyObjectivesReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    companyObjectivesRequestFailed: (state, action) => {
      state.loading = false;
    },

    companyObjectiveCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyObjectiveCreationReceived: (state, action) => {
      const { company_objective } = action.payload;
      if (company_objective) {
        state.list.push(company_objective);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    companyObjectiveCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    companyObjectiveUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    companyObjectiveUpdationReceived: (state, action) => {
      const { company_objective } = action.payload;

      const companyObjectiveIndex = state.list.findIndex(
        (stat) => stat.id === company_objective.id
      );
      if (companyObjectiveIndex !== -1) {
        state.list[companyObjectiveIndex] = company_objective;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    companyObjectiveUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    companyObjectiveDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    companyObjectiveDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const companyObjectiveIndex = state.list.findIndex(
        (dep) => dep.id === id
      );
      state.list.splice(companyObjectiveIndex, 1);
      state.deletion_loading = false;
    },

    companyObjectiveDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  companyObjectivesRequested,
  companyObjectivesReceived,
  companyObjectivesRequestFailed,
  companyObjectiveCreationRequested,
  companyObjectiveCreationReceived,
  companyObjectiveCreationFailed,
  companyObjectiveUpdationRequested,
  companyObjectiveUpdationReceived,
  companyObjectiveUpdationFailed,
  companyObjectiveDeletionRequested,
  companyObjectiveDeletionReceived,
  companyObjectiveDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/company_objectives";

export const loadCompanyObjectives = (payload) => (dispatch) => {
  let endpoint = "v1/hrms/company_objectives";
  if (payload.filters && Object.keys(payload.filters).length > 0) {
    const queryString = new URLSearchParams(payload.filters).toString();
    endpoint = `${endpoint}?${queryString}`;
  }
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: companyObjectivesRequested.type,
      onSuccess: companyObjectivesReceived.type,
      onError: companyObjectivesRequestFailed.type,
    })
  );
};

export const createCompanyObjective = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: companyObjectiveCreationRequested.type,
      onSuccess: companyObjectiveCreationReceived.type,
      onError: companyObjectiveCreationFailed.type,
      showAlert: true,
      successMessage: "Objective created successfully!",
    })
  );
};

export const updateCompanyObjective = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: companyObjectiveUpdationRequested.type,
      onSuccess: companyObjectiveUpdationReceived.type,
      onError: companyObjectiveUpdationFailed.type,
      showAlert: true,
      successMessage: "Objective updated successfully!",
    })
  );
};

export const deleteCompanyObjective = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: companyObjectiveDeletionRequested.type,
      onSuccess: companyObjectiveDeletionReceived.type,
      onError: companyObjectiveDeletionFailed.type,
      showAlert: true,
      successMessage: "Objective deleted successfully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
