import useTheme from "@mui/material/styles/useTheme";
import { hexToRgba } from "../common/Utils";
const PIcon = ({
  strokeColor = undefined,
  strokeWidth = "1.5",
  height = "25",
  width = "25",
  active = false,
}) => {
  const theme = useTheme();
  const iconColor = strokeColor || theme.custom.themeContrastColorCode;
  if (active) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-square-letter-p"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        strokeWidth={strokeWidth}
        stroke={iconColor}
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path
          d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"
          fill={hexToRgba(iconColor, 0.2)}
        />
        <path d="M10 12h2a2 2 0 1 0 0 -4h-2v8" stroke={iconColor} />
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-square-letter-p"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      stroke={iconColor}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
      <path d="M10 12h2a2 2 0 1 0 0 -4h-2v8" />
    </svg>
  );
};

export default PIcon;
