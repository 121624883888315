import React from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

const Home = () => {
  const [cookies] = useCookies(["authToken"]);
  const authToken = cookies.authToken;

  const isLoggedIn = !!authToken;

  return (
    <div style={{ padding: "20px" }}>
      {!isLoggedIn && (
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to="/login"
        >
          Login
        </Button>
      )}
      <h1>Eorganizer: Streamlining Innovation, Growth, and Insights</h1>

      <p>
        Welcome to a unified platform that revolutionizes the way you manage
        your business. Our all-in-one solution seamlessly integrates Product
        Management, Human Resource Management (HRM), and insightful Blog Writing
        to empower your organization's success.
      </p>

      <h2>Product Management Made Effortless</h2>
      <p>
        Turn ideas into reality with our intuitive Product Management tools.
        From concept to launch, our platform guides you through every stage,
        helping you refine your vision, prioritize features, and collaborate
        seamlessly across teams. Navigate market trends and customer demands
        with precision, ensuring your products meet the needs of today and the
        opportunities of tomorrow.
      </p>

      <h2>Elevate Your HRM Experience</h2>
      <p>
        Your people are your greatest asset, and our HRM system ensures their
        growth and well-being. Simplify recruitment, foster employee
        development, and enhance communication with ease. Empower your workforce
        with self-service tools, performance evaluations, and recognition
        mechanisms, creating a culture of engagement, productivity, and mutual
        success.
      </p>

      <h2>Inspiring Insights through Blog Writing</h2>
      <p>
        Unlock the power of knowledge sharing with our integrated Blog Writing
        platform. Seamlessly create and publish articles that showcase your
        industry expertise, share best practices, and inspire thought
        leadership. Engage your audience, build credibility, and foster
        connections within your community, all from within our versatile hub.
      </p>

      <h2>Experience the Future of Organization</h2>
      <p>
        Embrace innovation, collaboration, and growth like never before. Our
        platform empowers you to streamline product development, nurture your
        talent, and amplify your voice through insightful content. Join us on
        this transformative journey towards organizational excellence.
      </p>
    </div>
  );
};

export default Home;
