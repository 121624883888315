import { useState } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { profileShortName } from "./common/Utils";
import { isPwaOnIos, isMobileDevice } from "./constants";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ThemeSetting from "./ThemeSetting";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import HomeIcon from "@mui/icons-material/HomeOutlined";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import LogoutIcon from "@mui/icons-material/PowerSettingsNewOutlined";
import { logoutUser } from "./store/users";
import useTheme from "@mui/material/styles/useTheme";

const AccountDropdown = ({ size = "35px", showLabel = false }) => {
  const [anchorElUser, setAnchorElUser] = useState(null);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const theme = useTheme();

  const user = useSelector((state) => state.users.user);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleMenuSelect = (value) => {
    if (value === "logout") {
      dispatch(logoutUser());
    } else if (value === "profile") {
      navigate(`/hrms/employee/${user.uid}/profile`);
    }
    setAnchorElUser(null);
  };

  const settings = [
    {
      label: "Profile",
      value: "profile",
      icon: <HomeIcon sx={{ fontSize: "1.3rem" }} />,
      activeIcon: <HomeIcon sx={{ fontSize: "1.3rem" }} />,
    },
    {
      label: "Settings",
      value: "account",
      icon: <SettingsIcon sx={{ fontSize: "1.3rem" }} />,
      activeIcon: <SettingsIcon sx={{ fontSize: "1.3rem" }} />,
    },
    {
      label: "Logout",
      value: "logout",
      icon: <LogoutIcon sx={{ fontSize: "1.3rem" }} />,
      activeIcon: <LogoutIcon sx={{ fontSize: "1.3rem" }} />,
    },
  ];

  const color = Boolean(anchorElUser)
    ? theme.custom.appMainMenuActiveColor
    : theme.custom.appMainMenuColor;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              alt={user?.name}
              src={user?.profile_image_url}
              sx={{
                bgcolor: user?.profile_color || "#CDDEEE",
                width: size,
                height: size,
                objectFit: "cover",
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              {profileShortName(user?.name || "ERG")}
            </Avatar>
            {showLabel && (
              <Typography
                variant="h6"
                fontSize="0.6rem"
                color={color}
                sx={{ mt: 0.5 }}
              >
                Account
              </Typography>
            )}
          </Box>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElUser}
        keepMounted
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
        PaperProps={{ style: { borderRadius: "12px", padding: "1rem" } }}
      >
        {settings.map((setting) => (
          <Box
            key={setting.value}
            value={setting.value}
            sx={{
              minHeight: "2.5rem",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => handleMenuSelect(setting.value)}
          >
            {setting.icon}
            <Typography
              variant="h6"
              fontWeight={400}
              fontSize="1rem"
              sx={{ ml: 1.5 }}
            >
              {setting.label}
            </Typography>
          </Box>
        ))}
        {(isPwaOnIos || !isMobileDevice) && (
          <Box
            key="theme-setting"
            sx={{ display: "flex", alignItems: "center", minHeight: "2.5rem" }}
          >
            <span style={{ marginRight: "0.8rem", marginTop: "0.3rem" }}>
              <DarkModeIcon sx={{ fontSize: "1.3rem" }} />
            </span>
            <ThemeSetting />
          </Box>
        )}
      </Menu>
    </Box>
  );
};

export default AccountDropdown;
