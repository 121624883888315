import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "status_groups",
  initialState: {
    list: [],
    loading: false,
  },

  reducers: {
    statusGroupsRequested: (state, action) => {
      state.loading = true;
    },

    statusGroupsReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    statusGroupsRequestFailed: (state, action) => {
      state.loading = false;
    },

    statusGroupCreationRequested: (state, action) => {
      state.loading = true;
    },

    statusGroupCreationReceived: (state, action) => {
      const { status_group } = action.payload;
      if (status_group) {
        state.list.push(status_group);
      }
      state.loading = false;
    },

    statusGroupCreationFailed: (state, action) => {
      state.loading = false;
    },

    statusGroupUpdationRequested: (state, action) => {
      state.loading = true;
    },

    statusGroupUpdationReceived: (state, action) => {
      const { status_group } = action.payload;

        const statusGroupIndex = state.list.findIndex((stat) => stat.id === status_group.id);
        if (statusGroupIndex !== -1) {
          state.list[statusGroupIndex] = status_group
        }
      state.loading = false;
    },

    statusGroupUpdationFailed: (state, action) => {
      state.loading = false;
    },

    statusGroupDeletionRequested: (state, action) => {
      state.loading = true;
    },

    statusGroupDeletionReceived: (state, action) => {
      const { id } = action.payload
      const statusGroupIndex = state.list.findIndex((stat) => stat.id === id);
      state.list.splice(statusGroupIndex, 1);
      state.loading = false;
    },

    statusGroupDeletionFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;

const {
  statusGroupsRequested,
  statusGroupsReceived,
  statusGroupsRequestFailed,
  statusGroupCreationRequested,
  statusGroupCreationReceived,
  statusGroupCreationFailed,
  statusGroupUpdationRequested,
  statusGroupUpdationReceived,
  statusGroupUpdationFailed,
  statusGroupDeletionRequested,
  statusGroupDeletionReceived,
  statusGroupDeletionFailed,
} = slice.actions;

const url = "v1/status_groups";

export const loadStatusGroups = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: statusGroupsRequested.type,
      onSuccess: statusGroupsReceived.type,
      onError: statusGroupsRequestFailed.type,
    }),
  );
};

export const createStatusGroup = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { status_group: payload },
      type: "creation",
      onStart: statusGroupCreationRequested.type,
      onSuccess: statusGroupCreationReceived.type,
      onError: statusGroupCreationFailed.type,
    }),
  );
};

export const updateStatusGroup = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { status_group: payload },
      type: "updation",
      onStart: statusGroupUpdationRequested.type,
      onSuccess: statusGroupUpdationReceived.type,
      onError: statusGroupUpdationFailed.type,
    }),
  );
};

export const deleteStatusGroup = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: statusGroupDeletionRequested.type,
      onSuccess: statusGroupDeletionReceived.type,
      onError: statusGroupDeletionFailed.type,
    }),
  );
};
