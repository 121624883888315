import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "roles",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    rolesRequested: (state, action) => {
      state.loading = true;
    },

    rolesReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    rolesRequestFailed: (state, action) => {
      state.loading = false;
    },

    roleCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    roleCreationReceived: (state, action) => {
      const { role } = action.payload;
      if (role) {
        state.list.push(role);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    roleCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    roleUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    roleUpdationReceived: (state, action) => {
      const { role } = action.payload;

        const roleIndex = state.list.findIndex((stat) => stat.id === role.id);
        if (roleIndex !== -1) {
          state.list[roleIndex] = role
        }
      state.saving_loading = false;
      state.saved = true;
    },

    roleUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    roleDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    roleDeletionReceived: (state, action) => {
      const { id } = action.payload
      const roleIndex = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(roleIndex, 1);
      state.deletion_loading = false;
    },

    roleDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  rolesRequested,
  rolesReceived,
  rolesRequestFailed,
  roleCreationRequested,
  roleCreationReceived,
  roleCreationFailed,
  roleUpdationRequested,
  roleUpdationReceived,
  roleUpdationFailed,
  roleDeletionRequested,
  roleDeletionReceived,
  roleDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/roles";

export const loadRoles = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: rolesRequested.type,
      onSuccess: rolesReceived.type,
      onError: rolesRequestFailed.type,
    }),
  );
};

export const createRole = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { role: payload },
      type: "creation",
      onStart: roleCreationRequested.type,
      onSuccess: roleCreationReceived.type,
      onError: roleCreationFailed.type,
      showAlert: true,
      successMessage: "Role created successfully!"
    }),
  );
};

export const updateRole = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { role: payload },
      type: "updation",
      onStart: roleUpdationRequested.type,
      onSuccess: roleUpdationReceived.type,
      onError: roleUpdationFailed.type,
      showAlert: true,
      successMessage: "Role updated successfully!"
    }),
  );
};

export const deleteRole = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: roleDeletionRequested.type,
      onSuccess: roleDeletionReceived.type,
      onError: roleDeletionFailed.type,
      showAlert: true,
      successMessage: "Role deleted successfully!"
    }),
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
