import IconButton from "@mui/material/IconButton";
import AppsIcon from "@mui/icons-material/Apps";
import useTheme from "@mui/material/styles/useTheme"

const AppMainMenuIconComponent = ({ handleOpenNavMenu }) => {
  const theme = useTheme();
  const iconColor = theme.custom.appPackedIconColor
  return (
    <IconButton onClick={handleOpenNavMenu} sx={{ mr: 1 }} color={iconColor}>
      <AppsIcon sx={{ fontSize: "2.2rem" }} />
    </IconButton>
  );
};

export default AppMainMenuIconComponent;
