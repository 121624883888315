import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import { capitalizeFirstLetter } from "../../common/Utils";

const slice = createSlice({
  name: "employees_performances",
  initialState: {
    members: [],
    members_loading: false,
    assign_reviewer_loading: false,
    assign_reviewer_saved: false,
    remove_reviewer_loading: false,
    remove_reviewer_saved: false,
    start_review_process_loading: false,
    submit_for_reviewers_feedback_loading: false,
    submitted_for_reviewers_feedback: false,
    reinitiate_review_process_loading: false,
    reinitiated_review_process: false,
    reinitiate_reviewer_review_loading: false,
    reinitiate_reviewer_review_saved: false,
  },

  reducers: {
    membersRequested: (state, action) => {
      state.members = [];
      state.members_loading = true;
    },

    membersReceived: (state, action) => {
      state.members = action.payload.data;
      state.members_loading = false;
    },

    membersRequestFailed: (state, action) => {
      state.members_loading = false;
    },

    assignReviewerRequested: (state, action) => {
      state.assign_reviewer_loading = true;
      state.assign_reviewer_saved = false;
    },

    assignReviewerReceived: (state, action) => {
      const { reviewer } = action.payload;
      const memberIndex = state.members.findIndex(
        (member) => member.employee.id === reviewer.user_id
      );

      if (memberIndex > -1) {
        const updatedMembers = [...state.members];
        const updatedMember = { ...updatedMembers[memberIndex] };
        updatedMember.ratings = [...updatedMember.ratings, reviewer];
        updatedMembers[memberIndex] = updatedMember;

        return {
          ...state,
          members: updatedMembers,
          assign_reviewer_loading: false,
          assign_reviewer_saved: true,
        };
      }
    },

    assignReviewerFailed: (state, action) => {
      state.assign_reviewer_loading = false;
      state.assign_reviewer_saved = false;
    },

    deleteReviewerRequested: (state, action) => {
      state.remove_reviewer_loading = true;
      state.remove_reviewer_saved = false;
    },

    deleteReviewerReceived: (state, action) => {
      const { employee_id, deleted_reviewer_id } = action.payload;

      // Find the index of the member whose ratings need to be updated
      const memberIndex = state.members.findIndex(
        (member) => member.employee.id === employee_id
      );

      if (memberIndex > -1) {
        // Create a copy of the updated member
        const updatedMember = { ...state.members[memberIndex] };

        // Remove the reviewer from the ratings array
        updatedMember.ratings = updatedMember.ratings.filter(
          (rating) => rating.reviewer.id !== deleted_reviewer_id
        );

        // Create a copy of the members array with the updated member
        const updatedMembers = [...state.members];
        updatedMembers[memberIndex] = updatedMember;

        return {
          ...state,
          members: updatedMembers,
          remove_reviewer_loading: false,
          remove_reviewer_saved: true,
        };
      }
    },

    deleteReviewerFailed: (state, action) => {
      state.remove_reviewer_loading = false;
      state.remove_reviewer_saved = false;
    },

    startReviewProcessRequested: (state, action) => {
      state.start_review_process_loading = true;
    },

    startReviewProcessReceived: (state, action) => {
      const { reviews, employee_performance } = action.payload;
      const memberIndex = state.members.findIndex(
        (member) => member.employee.id === reviews[0].user_id
      );

      if (memberIndex > -1) {
        state.members[memberIndex].ratings = reviews;
        state.members[memberIndex].employee_performance = employee_performance;
        state.start_review_process_loading = false;
      }
    },

    startReviewProcessFailed: (state, action) => {
      state.start_review_process_loading = false;
    },

    reinitiateReviewProcessRequested: (state, action) => {
      state.reinitiate_review_process_loading = true;
      state.reinitiated_review_process = false;
    },

    reinitiateReviewProcessReceived: (state, action) => {
      const { employee_performance } = action.payload;
      const memberIndex = state.members.findIndex(
        (member) => member.employee.id === employee_performance.user_id
      );

      if (memberIndex > -1) {
        state.members[memberIndex].ratings = [];
        state.members[memberIndex].employee_performance = employee_performance;
      }
      state.reinitiated_review_process = true;
      state.reinitiate_review_process_loading = false;
    },

    reinitiateReviewProcessFailed: (state, action) => {
      state.reinitiate_review_process_loading = false;
      state.reinitiated_review_process = false;
    },

    submitForReviewersRequested: (state, action) => {
      state.submit_for_reviewers_feedback_loading = true;
      state.submitted_for_reviewers_feedback = false;
    },

    submitForReviewersReceived: (state, action) => {
      const { employee_performance } = action.payload;
      const memberIndex = state.members.findIndex(
        (member) => member.employee.id === employee_performance.user_id
      );

      if (memberIndex > -1) {
        state.members[memberIndex].employee_performance = employee_performance;
        state.submit_for_reviewers_feedback_loading = false;
        state.submitted_for_reviewers_feedback = true;
      }
    },

    submitForReviewersRequestFailed: (state, action) => {
      state.submit_for_reviewers_feedback_loading = false;
      state.submitted_for_reviewers_feedback = false;
    },

    resetNewReviewerStateData: (state, action) => {
      state.assign_reviewer_saved = false;
    },

    reviewerRatingRequested: (state, action) => {
      state.reviewer_rating_loading = true;
    },

    reviewerRatingReceived: (state, action) => {
      const { rating } = action.payload;
      const memberIndex = state.members.findIndex(
        (member) => member.employee.id === rating.user_id
      );

      if (memberIndex > -1) {
        const updatedMembers = [...state.members];
        const updatedMember = { ...updatedMembers[memberIndex] };
        updatedMember.ratings = updatedMember.ratings.map((ratingItem) => {
          if (ratingItem.id === rating.id) {
            return rating;
          }
          return ratingItem;
        });

        updatedMembers[memberIndex] = updatedMember;

        return {
          ...state,
          members: updatedMembers,
          reviewer_rating_loading: false,
        };
      }
    },

    reviewerRatingRequestFailed: (state, action) => {
      state.reviewer_rating_loading = false;
    },

    reinitiateReviewerReviewRequested: (state, action) => {
      state.reinitiate_reviewer_review_loading = true;
      state.reinitiate_reviewer_review_saved = false;
    },

    reinitiateReviewerReviewReceived: (state, action) => {
      const { rating } = action.payload;

      const memberIndex = state.members.findIndex(
        (member) => member.employee.id === rating.user_id
      );

      if (memberIndex > -1) {
        const member = state.members[memberIndex];

        const ratingIndex = member.ratings.findIndex(
          (item) =>
            item.reviewer_id === rating.reviewer_id &&
            item.reviewer_type === rating.reviewer_type
        );

        if (ratingIndex > -1) {
          member.employee_performance.status =
            rating.employee_performance_status;
          member.ratings[ratingIndex] = rating;
        }

        state.reinitiate_reviewer_review_loading = false;
        state.reinitiate_reviewer_review_saved = true;
      }
    },

    reinitiateReviewerReviewFailed: (state, action) => {
      state.reinitiate_reviewer_review_loading = false;
    },

    resetActionStates: (state, action) => {
      if (action.payload.action_type === "reviewer_deletion") {
        state.remove_reviewer_loading = false;
        state.remove_reviewer_saved = false;
      } else if (action.payload.action_type === "reinitiate_reviewer_review") {
        state.reinitiate_reviewer_review_loading = false;
        state.reinitiate_reviewer_review_saved = false;
      }
    },
  },
});

export default slice.reducer;

const {
  membersRequested,
  membersReceived,
  membersRequestFailed,
  assignReviewerRequested,
  assignReviewerReceived,
  assignReviewerFailed,
  startReviewProcessRequested,
  startReviewProcessReceived,
  startReviewProcessFailed,
  deleteReviewerRequested,
  deleteReviewerReceived,
  deleteReviewerFailed,
  resetNewReviewerStateData,
  reviewerRatingRequested,
  reviewerRatingReceived,
  reviewerRatingRequestFailed,
  submitForReviewersRequested,
  submitForReviewersReceived,
  submitForReviewersRequestFailed,
  reinitiateReviewProcessRequested,
  reinitiateReviewProcessReceived,
  reinitiateReviewProcessFailed,
  reinitiateReviewerReviewRequested,
  reinitiateReviewerReviewReceived,
  reinitiateReviewerReviewFailed,
  resetActionStates,
} = slice.actions;

const ratingsUrl = "v1/hrms/performances";

export const getMembersPerformanceRatings = (payload) => (dispatch) => {
  const endPoint = `${ratingsUrl}/${payload.performances_of}/ratings?performance_timeframe_id=${payload.performance_timeframe_id}`;
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: membersRequested.type,
      onSuccess: membersReceived.type,
      onError: membersRequestFailed.type,
    })
  );
};

export const getReviewerRating = (payload) => (dispatch) => {
  const endPoint = `${ratingsUrl}/${payload.employee_id}/reviewer_rating?reviewer_id=${payload.reviewer_id}&reviewer_type=${payload.reviewer_type}&performance_timeframe_id=${payload.performance_timeframe_id}`;
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: reviewerRatingRequested.type,
      onSuccess: reviewerRatingReceived.type,
      onError: reviewerRatingRequestFailed.type,
    })
  );
};

export const startReviewProcess = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${ratingsUrl}/start_review_process`,
      method: "POST",
      data: payload.body,
      type: payload.action,
      onStart: startReviewProcessRequested.type,
      onSuccess: startReviewProcessReceived.type,
      onError: startReviewProcessFailed.type,
      showAlert: true,
      successMessage: `${payload.employee_name}'s review process initiated`,
    })
  );
};

export const reinitiateReviewProcess = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${ratingsUrl}/${payload.employee_id}/reinitiate_review_process`,
      method: "PATCH",
      data: payload.body,
      type: payload.action,
      onStart: reinitiateReviewProcessRequested.type,
      onSuccess: reinitiateReviewProcessReceived.type,
      onError: reinitiateReviewProcessFailed.type,
      showAlert: true,
      successMessage: `${payload.employee_name}'s review process re-initiated`,
    })
  );
};

export const reinitiateReviewerReview = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${ratingsUrl}/${payload.employee_id}/reinitiate_review_process_for_a_single_reviewer`,
      method: "PATCH",
      data: payload.body,
      type: payload.action,
      onStart: reinitiateReviewerReviewRequested.type,
      onSuccess: reinitiateReviewerReviewReceived.type,
      onError: reinitiateReviewerReviewFailed.type,
      showAlert: true,
      successMessage: `${capitalizeFirstLetter(
        payload.body.reviewer_type
      )} review re-initiated`,
    })
  );
};

export const submitForReviewersFeedback = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${ratingsUrl}/${payload.employee_id}/submit_for_reviewers_feedback`,
      method: "PATCH",
      data: { id: payload.id },
      type: payload.action,
      onStart: submitForReviewersRequested.type,
      onSuccess: submitForReviewersReceived.type,
      onError: submitForReviewersRequestFailed.type,
      showAlert: true,
      successMessage: `${payload.name} feedback is submitted for reviewers feedback`,
    })
  );
};

export const assignReviewer = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${ratingsUrl}/assign_reviewer`,
      method: "POST",
      data: payload.body,
      type: payload.action,
      onStart: assignReviewerRequested.type,
      onSuccess: assignReviewerReceived.type,
      onError: assignReviewerFailed.type,
      showAlert: true,
      successMessage: `${payload.reviewer_name} is added as ${payload.employee_name} ${payload.body.reviewer_type} reviewer`,
    })
  );
};

export const deleteReviewer = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${ratingsUrl}/delete_reviewer`,
      method: "POST",
      data: payload.body,
      type: payload.action,
      onStart: deleteReviewerRequested.type,
      onSuccess: deleteReviewerReceived.type,
      onError: deleteReviewerFailed.type,
      showAlert: true,
      successMessage: `${payload.reviewer_name} removed as reviewer for ${payload.employee_name}`,
    })
  );
};

export const resetNewReviewerState = () => (dispatch) => {
  return dispatch(resetNewReviewerStateData());
};

export const resetActions = (payload) => (dispatch) => {
  return dispatch(resetActionStates(payload));
};
