import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "employee_levels",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    employeeLevelsRequested: (state, action) => {
      state.loading = true;
    },

    employeeLevelsReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    employeeLevelsRequestFailed: (state, action) => {
      state.loading = false;
    },

    employeeLevelCreationRequested: (state, action) => {
      state.saving_loading = true;
    },

    employeeLevelCreationReceived: (state, action) => {
      const { employee_level } = action.payload;
      if (employee_level) {
        state.list.push(employee_level);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    employeeLevelCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    employeeLevelUpdationRequested: (state, action) => {
      state.saving_loading = true;
    },

    employeeLevelUpdationReceived: (state, action) => {
      const { employee_level } = action.payload;

      const employeeLevelIndex = state.list.findIndex(
        (stat) => stat.id === employee_level.id
      );
      if (employeeLevelIndex !== -1) {
        state.list[employeeLevelIndex] = employee_level;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    employeeLevelUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    employeeLevelDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    employeeLevelDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const employeeLevelIndex = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(employeeLevelIndex, 1);
      state.deletion_loading = false;
    },

    employeeLevelDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  employeeLevelsRequested,
  employeeLevelsReceived,
  employeeLevelsRequestFailed,
  employeeLevelCreationRequested,
  employeeLevelCreationReceived,
  employeeLevelCreationFailed,
  employeeLevelUpdationRequested,
  employeeLevelUpdationReceived,
  employeeLevelUpdationFailed,
  employeeLevelDeletionRequested,
  employeeLevelDeletionReceived,
  employeeLevelDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/employee_levels";

export const loadEmployeeLevels = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: employeeLevelsRequested.type,
      onSuccess: employeeLevelsReceived.type,
      onError: employeeLevelsRequestFailed.type,
    })
  );
};

export const createEmployeeLevel = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { employee_level: payload.employee_level },
      type: "creation",
      onStart: employeeLevelCreationRequested.type,
      onSuccess: employeeLevelCreationReceived.type,
      onError: employeeLevelCreationFailed.type,
      showAlert: true,
      successMessage: "Employee level created!",
    })
  );
};

export const updateEmployeeLevel = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: { employee_level: payload.employee_level },
      type: "updation",
      onStart: employeeLevelUpdationRequested.type,
      onSuccess: employeeLevelUpdationReceived.type,
      onError: employeeLevelUpdationFailed.type,
      showAlert: true,
      successMessage: "Employee level updated!",
    })
  );
};

export const deleteEmployeeLevel = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: employeeLevelDeletionRequested.type,
      onSuccess: employeeLevelDeletionReceived.type,
      onError: employeeLevelDeletionFailed.type,
      showErrorAlert: true,
      successMessage: "Employee level deleted!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
