import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "performance_questionnaires",
  initialState: {
    list: [],
    loading: false,
    saving_loading: false,
    saved: false,
    deletion_loading: false,
  },

  reducers: {
    performanceQuestionnairesRequested: (state, action) => {
      state.loading = true;
    },

    performanceQuestionnairesReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    performanceQuestionnairesRequestFailed: (state, action) => {
      state.loading = false;
    },

    performanceQuestionnaireCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    performanceQuestionnaireCreationReceived: (state, action) => {
      const { performance_questionnaire } = action.payload;
      if (performance_questionnaire) {
        state.list.push(performance_questionnaire);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    performanceQuestionnaireCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    performanceQuestionnaireUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    performanceQuestionnaireUpdationReceived: (state, action) => {
      const { performance_questionnaire } = action.payload;

      const performanceQuestionnaireIndex = state.list.findIndex(
        (stat) => stat.id === performance_questionnaire.id,
      );
      if (performanceQuestionnaireIndex !== -1) {
        state.list[performanceQuestionnaireIndex] = performance_questionnaire;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    performanceQuestionnaireUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    performanceQuestionnaireDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    performanceQuestionnaireDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const performanceQuestionnaireIndex = state.list.findIndex(
        (dep) => dep.id === id,
      );
      state.list.splice(performanceQuestionnaireIndex, 1);
      state.deletion_loading = false;
    },

    performanceQuestionnaireDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  performanceQuestionnairesRequested,
  performanceQuestionnairesReceived,
  performanceQuestionnairesRequestFailed,
  performanceQuestionnaireCreationRequested,
  performanceQuestionnaireCreationReceived,
  performanceQuestionnaireCreationFailed,
  performanceQuestionnaireUpdationRequested,
  performanceQuestionnaireUpdationReceived,
  performanceQuestionnaireUpdationFailed,
  performanceQuestionnaireDeletionRequested,
  performanceQuestionnaireDeletionReceived,
  performanceQuestionnaireDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/performance_questionnaires";

export const loadPerformanceQuestionnaires = (payload) => (dispatch) => {
  let endpoint = "v1/hrms/performance_questionnaires";
  if (payload.filters && Object.keys(payload.filters).length > 0) {
    const queryString = new URLSearchParams(payload.filters).toString();
    endpoint = `${endpoint}?${queryString}`;
  }
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: performanceQuestionnairesRequested.type,
      onSuccess: performanceQuestionnairesReceived.type,
      onError: performanceQuestionnairesRequestFailed.type,
    }),
  );
};

export const createPerformanceQuestionnaire = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: performanceQuestionnaireCreationRequested.type,
      onSuccess: performanceQuestionnaireCreationReceived.type,
      onError: performanceQuestionnaireCreationFailed.type,
      showAlert: true,
      successMessage: "Questionnaire created successfully!",
    }),
  );
};

export const updatePerformanceQuestionnaire = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: performanceQuestionnaireUpdationRequested.type,
      onSuccess: performanceQuestionnaireUpdationReceived.type,
      onError: performanceQuestionnaireUpdationFailed.type,
      showAlert: true,
      successMessage: "Questionnaire updated successfully!",
    }),
  );
};

export const deletePerformanceQuestionnaire = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: performanceQuestionnaireDeletionRequested.type,
      onSuccess: performanceQuestionnaireDeletionReceived.type,
      onError: performanceQuestionnaireDeletionFailed.type,
      showAlert: true,
      successMessage: "Questionnaire deleted successfully!",
    }),
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};