import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import ListItemIcon from "@mui/material/ListItemIcon";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {
  createStatusGroup,
  updateStatusGroup,
} from "../../../../store/status_groups";
import { useDispatch } from "react-redux";

const StatusGroupForm = ({ open, onClose, statuses, statusGroup }) => {
  const [statusGroupName, setStatusGroupName] = useState("");
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [initialStatusId, setInitialStatusId] = useState(statusGroup);
  const [finalStatusId, setFinalStatusId] = useState(statusGroup);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dispatch = useDispatch();

  // Populate form fields with existing data when the modal is opened for editing
  useEffect(() => {
    if (statusGroup) {
      setStatusGroupName(statusGroup.name);
      setSelectedStatuses(statusGroup.statuses);
      setInitialStatusId(statusGroup.initial_status_id);
      setFinalStatusId(statusGroup.final_status_id);
    }
  }, [statusGroup, statuses]);

  const handleCancel = () => {
    setStatusGroupName("");
    setSelectedStatuses([]);
    setInitialStatusId(null);
    setFinalStatusId(null);
    onClose();
  };

  const selectedStatusIds = selectedStatuses.map((status) => status.id);

  const handleSave = () => {
    if (statusGroup) {
      dispatch(
        updateStatusGroup({
          id: statusGroup.uid,
          name: statusGroupName,
          status_ids: selectedStatusIds,
          initial_status_id: initialStatusId,
          final_status_id: finalStatusId,
        }),
      );
    } else {
      dispatch(
        createStatusGroup({
          name: statusGroupName,
          status_ids: selectedStatusIds,
          initial_status_id: initialStatusId,
          final_status_id: finalStatusId,
        }),
      );
    }
    handleCancel();
  };

  const CustomOption = ({ props, status }) => (
    <li
      {...props}
      style={{ color: status.color, borderBottom: "1px dashed #ccc" }}
    >
      <SquareRoundedIcon fontSize="small" style={{ color: status.color }} />
      <span style={{ color: status.color, marginLeft: "10px" }}>
        {status.name}
      </span>
    </li>
  );

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      maxWidth="sm"
      fullWidth
      PaperProps={{ sx: { margin: 0, width: "calc(100% - 40px)" } }}
    >
      <DialogTitle sx={{ borderBottom: "1px solid #ccc", height: "60px" }}>
        <Typography variant="h6" gutterBottom>
          {statusGroup ? "Update Status Group" : "Create Status Group"}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ marginTop: "8px" }}>
        <TextField
          label="Status Group Name"
          value={statusGroupName}
          onChange={(e) => setStatusGroupName(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box mb={2}>
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={statuses}
            getOptionLabel={(option) => option.name}
            value={selectedStatuses}
            onChange={(event, newValue) => setSelectedStatuses(newValue)}
            open={isMenuOpen} // Use the custom state to control menu open/close
            onOpen={() => setIsMenuOpen(true)}
            onClose={() => setIsMenuOpen(false)}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Statuses"
                fullWidth
                margin="normal"
              />
            )}
            renderTags={(value) =>
              value.map((option, index) => (
                <Chip
                  key={option.uid}
                  label={option.name}
                  onDelete={() =>
                    setSelectedStatuses(
                      selectedStatuses.filter((tag) => tag.id !== option.id),
                    )
                  }
                  style={{
                    backgroundColor: option.color,
                    color: "white",
                    margin: "4px",
                    borderColor: option.color,
                    borderWidth: 1,
                    borderStyle: "solid",
                  }}
                />
              ))
            }
            renderOption={(props, option) => (
              <li
                {...props}
                style={{ color: option.color, borderBottom: "1px dashed #ccc" }}
              >
                <Checkbox
                  color="primary"
                  checked={selectedStatuses.some((tag) => tag.id === option.id)}
                />
                {option.name}
              </li>
            )}
          />
        </Box>
        <Box mb={3}>
          <FormControl id="custom-select-status-group-form-input" fullWidth>
            <InputLabel
              id="initial-status-label"
              style={{ background: "#FFF", padding: "0px 5px" }}
            >
              Initial Status
            </InputLabel>
            <Select
              labelId="initial-status-label"
              id="initial-status"
              value={initialStatusId}
              onChange={(e) => setInitialStatusId(e.target.value)}
              fullWidth
            >
              {selectedStatuses.map((status) => (
                <MenuItem key={status.uid} value={status.id}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SquareRoundedIcon
                      fontSize="small"
                      style={{ color: status.color }}
                    />
                    <span style={{ color: status.color, marginLeft: "10px" }}>
                      {status.name}
                    </span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box mb={3}>
          <FormControl id="custom-select-new-status-group-form-input" fullWidth>
            <InputLabel
              id="final-status-label"
              style={{ background: "#FFF", padding: "0px 5px" }}
            >
              Final Status
            </InputLabel>
            <Select
              labelId="final-status-label"
              id="final-status"
              value={finalStatusId}
              onChange={(e) => setFinalStatusId(e.target.value)}
              fullWidth
            >
              {selectedStatuses.map((status) => (
                <MenuItem key={status.uid} value={status.id}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <SquareRoundedIcon
                      fontSize="small"
                      style={{ color: status.color }}
                    />
                    <span style={{ color: status.color, marginLeft: "10px" }}>
                      {status.name}
                    </span>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions sx={{ borderTop: "1px solid #ccc", height: "70px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            color="default"
            onClick={handleCancel}
            sx={{ mr: 2 }}
          >
            Cancel
          </Button>
          <Button
            variant="success-outlined"
            onClick={handleSave}
            color="primary"
            disabled={
              !statusGroupName ||
              !statusGroupName.trim() ||
              !selectedStatuses ||
              !selectedStatuses.length
            }
          >
            {statusGroup ? "Save" : "Create"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default StatusGroupForm;
