import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React, { useEffect } from "react";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import Toasters from "./common/Toasters";
import { isMobileDevice } from "./constants";
import Layout from "./Layout";
import PublicRoutes from "./PublicRoutes";
import { BrowserRouter } from "react-router-dom";
import { dismissAlert } from "./store/alerts";
import { useDispatch, useSelector } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./App.css";
import PullToRefresh from "./PullToRefresh";
import { useCookies } from "react-cookie";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import createTheme from "@mui/material/styles/createTheme";

import LightTheme from "./common/themes/LightTheme";
import DarkTheme from "./common/themes/DarkTheme";

const store = configureStore();

export default function App() {
  const [cookies] = useCookies(["authToken"]);
  const authToken = cookies.authToken;

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const manualThemeMode = window.localStorage.getItem("theme");

  const theme = React.useMemo(
    () =>
      createTheme(
        manualThemeMode
          ? manualThemeMode === "dark"
            ? DarkTheme
            : LightTheme
          : prefersDarkMode
          ? DarkTheme
          : LightTheme
      ),
    [prefersDarkMode, manualThemeMode]
  );

  const isLoggedIn = !!authToken;

  useEffect(() => {
    if (isMobileDevice) {
      let idleTime = 0;

      const timerIncrement = () => {
        idleTime++;
        if (idleTime > 60) {
          // 60 minutes of idle time
          window.location.reload();
        }
      };

      const resetIdleTime = () => {
        idleTime = 0;
      };

      const idleInterval = setInterval(timerIncrement, 60000); // 1 minute

      window.addEventListener("mousemove", resetIdleTime);
      window.addEventListener("keypress", resetIdleTime);
      window.addEventListener("touchstart", resetIdleTime); // For mobile devices

      return () => {
        clearInterval(idleInterval);
        window.removeEventListener("mousemove", resetIdleTime);
        window.removeEventListener("keypress", resetIdleTime);
        window.removeEventListener("touchstart", resetIdleTime);
      };
    }
  }, []);

  return (
    <BrowserRouter>
      <React.StrictMode>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <PullToRefresh>
              <div className="scrollbarhide app">
                {isLoggedIn ? <Layout /> : <PublicRoutes />}
                <GlobalAlert />
                <Toasters />
              </div>
            </PullToRefresh>
          </ThemeProvider>
        </Provider>
      </React.StrictMode>
    </BrowserRouter>
  );
}

const GlobalAlert = () => {
  const dispatch = useDispatch();
  const alert = useSelector((state) => state.alerts.alert);

  const handleClose = () => {
    dispatch(dismissAlert());
  };

  return (
    <Snackbar
      open={alert.showAlert}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={alert.type} sx={{ width: "100%" }}>
        {alert.message}
      </Alert>
    </Snackbar>
  );
};
