import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "holidays",
  initialState: {
    list: [],
    loading: false,
    deletion_loading: false,
    saving_loading: false,
    saved: false,
  },

  reducers: {
    holidaysRequested: (state, action) => {
      state.loading = true;
    },

    holidaysReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    holidaysRequestFailed: (state, action) => {
      state.loading = false;
    },

    holidayCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    holidayCreationReceived: (state, action) => {
      const { holiday } = action.payload;
      if (holiday) {
        state.list.push(holiday);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    holidayCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    holidayUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    holidayUpdationReceived: (state, action) => {
      const { holiday } = action.payload;

      const holidayIndex = state.list.findIndex(
        (stat) => stat.id === holiday.id,
      );
      if (holidayIndex !== -1) {
        state.list[holidayIndex] = holiday;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    holidayUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    holidayDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    holidayDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const holidayIndex = state.list.findIndex((dep) => dep.id === id);
      state.list.splice(holidayIndex, 1);
      state.deletion_loading = false;
    },

    holidayDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.deletion_loading = false;
      state.saving_loading = false;
      state.saved = false;
    },
  },
});

export default slice.reducer;

const {
  holidaysRequested,
  holidaysReceived,
  holidaysRequestFailed,
  holidayCreationRequested,
  holidayCreationReceived,
  holidayCreationFailed,
  holidayUpdationRequested,
  holidayUpdationReceived,
  holidayUpdationFailed,
  holidayDeletionRequested,
  holidayDeletionReceived,
  holidayDeletionFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/holidays";

export const loadHolidays = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}?year=${payload.year}`,
      onStart: holidaysRequested.type,
      onSuccess: holidaysReceived.type,
      onError: holidaysRequestFailed.type,
    }),
  );
};

export const createHoliday = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: holidayCreationRequested.type,
      onSuccess: holidayCreationReceived.type,
      onError: holidayCreationFailed.type,
      showAlert: true,
      successMessage: "Holiday created successfully!"
    }),
  );
};

export const updateHoliday = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: holidayUpdationRequested.type,
      onSuccess: holidayUpdationReceived.type,
      onError: holidayUpdationFailed.type,
      showAlert: true,
      successMessage: "Holiday updated successfully!"
    }),
  );
};

export const deleteHoliday = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: holidayDeletionRequested.type,
      onSuccess: holidayDeletionReceived.type,
      onError: holidayDeletionFailed.type,
      showAlert: true,
      successMessage: "Holiday deleted successfully!"
    }),
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
