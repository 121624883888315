import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "attachments",
  initialState: {
    data: []
  },

  reducers: {
    createRequested: (attachments, action) => {
      attachments.loading = true;
    },

    createReceived: (attachments, action) => {
      attachments.loading = false;
      attachments.data.push(action.payload)
    },

    createRequestedFailed: (attachments, action) => {
      attachments.loading = false;
    },
  }  
});

export default slice.reducer;

const {
  createRequested,
  createReceived,
  createRequestedFailed,
} = slice.actions;

const url = "v1/attachments";


export const createAttachments = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: url,
      method: "POST",
      data: payload,
      handlers: { 'content-type': 'multipart/form-data' },
      type: "creation",
      onStart: createRequested.type,
      onSuccess: createReceived.type,
      onError: createRequestedFailed.type,
    })
  );
};
