import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import HomeIcon from "./icons/HomeIcon";
import HIcon from "./icons/HIcon";
import PIcon from "./icons/PIcon";
import KIcon from "./icons/KIcon";
import AccountDropdown from "./AccountDropdown";
import useTheme from "@mui/material/styles/useTheme";

const AppMainMenu = ({ currentPath }) => {
  const navigate = useNavigate();

  const theme = useTheme();

  const navigateTo = (path) => {
    navigate(path);
  };

  const Home = () => {
    const active = currentPath === "/home";
    const color = active
      ? theme.custom.appMainMenuActiveColor
      : theme.custom.appMainMenuColor;
    return (
      <Button onClick={() => navigateTo("/home")} sx={{ minWidth: "unset" }}>
        <Box>
          <HomeIcon
            active={active}
            strokeColor={color}
            height="25"
            width="25"
          />
          <Typography
            color={color}
            variant="h6"
            fontSize="0.6rem"
            sx={{ mt: -0.5 }}
          >
            Home
          </Typography>
        </Box>
      </Button>
    );
  };

  const Pms = () => {
    const active = currentPath.startsWith("/pm");
    const color = active
      ? theme.custom.appMainMenuActiveColor
      : theme.custom.appMainMenuColor;
    return (
      <Button onClick={() => navigateTo("/pm")} sx={{ minWidth: "unset" }}>
        <Box>
          <PIcon active={active} strokeColor={color} height="25" width="25" />
          <Typography
            color={color}
            variant="h6"
            fontSize="0.6rem"
            sx={{ mt: -0.5 }}
          >
            PMS
          </Typography>
        </Box>
      </Button>
    );
  };

  const Hrms = () => {
    const active = currentPath.startsWith("/hrms");
    const color = active
      ? theme.custom.appMainMenuActiveColor
      : theme.custom.appMainMenuColor;
    return (
      <Button onClick={() => navigateTo("/hrms")} sx={{ minWidth: "unset" }}>
        <Box>
          <HIcon active={active} strokeColor={color} height="25" width="25" />
          <Typography
            color={color}
            variant="h6"
            fontSize="0.6rem"
            sx={{ mt: -0.5 }}
          >
            HRMS
          </Typography>
        </Box>
      </Button>
    );
  };

  const Kb = () => {
    const active = currentPath.startsWith("/kb");
    const color = active
      ? theme.custom.appMainMenuActiveColor
      : theme.custom.appMainMenuColor;
    return (
      <Button onClick={() => navigateTo("/kb")} sx={{ minWidth: "unset" }}>
        <Box>
          <KIcon active={active} strokeColor={color} height="25" width="25" />
          <Typography
            color={color}
            variant="h6"
            fontSize="0.6rem"
            sx={{ mt: -0.5 }}
          >
            K.B
          </Typography>
        </Box>
      </Button>
    );
  };

  return (
    <Box width="100%" sx={{ display: "flex", justifyContent: "space-around" }}>
      <Home />
      <Pms />
      <Hrms />
      <Kb />
      <AccountDropdown size="23px" showLabel />
    </Box>
  );
};
export default AppMainMenu;
