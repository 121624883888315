import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "statuses",
  initialState: {
    list: [],
    loading: false,
  },

  reducers: {
    statusesRequested: (statuses, action) => {
      statuses.loading = true;
    },

    statusesReceived: (statuses, action) => {
      statuses.list = action.payload.list;
      statuses.loading = false;
    },

    statusesRequestFailed: (statuses, action) => {
      statuses.loading = false;
    },

    statusCreationRequested: (state, action) => {
      state.loading = true;
    },

    statusCreationReceived: (state, action) => {
      const { status } = action.payload;
      if (status) {
        state.list.push(status);
      }
      state.loading = false;
    },

    statusCreationFailed: (state, action) => {
      state.loading = false;
    },

    statusUpdationRequested: (state, action) => {
      state.loading = true;
    },

    statusUpdationReceived: (state, action) => {
      const { status } = action.payload;

        const statusIndex = state.list.findIndex((stat) => stat.id === status.id);
        if (statusIndex !== -1) {
          state.list[statusIndex] = status
        }
      state.loading = false;
    },

    statusUpdationFailed: (state, action) => {
      state.loading = false;
    },

    statusDeletionRequested: (state, action) => {
      state.loading = true;
    },

    statusDeletionReceived: (state, action) => {
      const { id } = action.payload
      const statusIndex = state.list.findIndex((stat) => stat.id === id);
      state.list.splice(statusIndex, 1);
      state.loading = false;
    },

    statusDeletionFailed: (state, action) => {
      state.loading = false;
    },
  },
});

export default slice.reducer;

const {
  statusesRequested,
  statusesReceived,
  statusesRequestFailed,
  statusCreationRequested,
  statusCreationReceived,
  statusCreationFailed,
  statusUpdationRequested,
  statusUpdationReceived,
  statusUpdationFailed,
  statusDeletionRequested,
  statusDeletionReceived,
  statusDeletionFailed,
} = slice.actions;

const url = "v1/statuses";

export const loadStatuses = () => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      onStart: statusesRequested.type,
      onSuccess: statusesReceived.type,
      onError: statusesRequestFailed.type,
    }),
  );
};

export const createStatus = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: payload,
      type: "creation",
      onStart: statusCreationRequested.type,
      onSuccess: statusCreationReceived.type,
      onError: statusCreationFailed.type,
    }),
  );
};

export const updateStatus = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: payload,
      type: "updation",
      onStart: statusUpdationRequested.type,
      onSuccess: statusUpdationReceived.type,
      onError: statusUpdationFailed.type,
    }),
  );
};

export const deleteStatus = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: statusDeletionRequested.type,
      onSuccess: statusDeletionReceived.type,
      onError: statusDeletionFailed.type,
    }),
  );
};
