import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "performance_timeframes",
  initialState: {
    list: [],
    current_timeframes: {},
    current_timeframes_loading: false,
    loading: false,
    saving_loading: false,
    saved: false,
    deletion_loading: false,
  },

  reducers: {
    performanceTimeframesRequested: (state, action) => {
      state.loading = true;
    },

    performanceTimeframesReceived: (state, action) => {
      state.list = action.payload.list;
      state.loading = false;
    },

    performanceTimeframesRequestFailed: (state, action) => {
      state.loading = false;
    },

    currentPerformanceTimeframesRequested: (state, action) => {
      state.current_timeframes_loading = true;
    },

    currentPerformanceTimeframesReceived: (state, action) => {
      state.current_timeframes = action.payload;
      state.current_timeframes_loading = false;
    },

    currentPerformanceTimeframesRequestFailed: (state, action) => {
      state.current_timeframes_loading = false;
    },

    performanceTimeframeCreationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    performanceTimeframeCreationReceived: (state, action) => {
      const { performance_timeframe } = action.payload;
      if (performance_timeframe) {
        state.list.push(performance_timeframe);
      }
      state.saving_loading = false;
      state.saved = true;
    },

    performanceTimeframeCreationFailed: (state, action) => {
      state.saving_loading = false;
    },

    performanceTimeframeUpdationRequested: (state, action) => {
      state.saving_loading = true;
      state.saved = false;
    },

    performanceTimeframeUpdationReceived: (state, action) => {
      const { performance_timeframe } = action.payload;

      const performanceTimeframeIndex = state.list.findIndex(
        (stat) => stat.id === performance_timeframe.id
      );
      if (performanceTimeframeIndex !== -1) {
        state.list[performanceTimeframeIndex] = performance_timeframe;
      }
      state.saving_loading = false;
      state.saved = true;
    },

    performanceTimeframeUpdationFailed: (state, action) => {
      state.saving_loading = false;
    },

    performanceTimeframeDeletionRequested: (state, action) => {
      state.deletion_loading = true;
    },

    performanceTimeframeDeletionReceived: (state, action) => {
      const { id } = action.payload;
      const performanceTimeframeIndex = state.list.findIndex(
        (dep) => dep.id === id
      );
      state.list.splice(performanceTimeframeIndex, 1);
      state.deletion_loading = false;
    },

    performanceTimeframeDeletionFailed: (state, action) => {
      state.deletion_loading = false;
    },

    resetActionStates: (state, action) => {
      state.loading = false;
      state.saving_loading = false;
      state.saved = false;
      state.deletion_loading = false;
      state.current_timeframes_loading = false;
    },
  },
});

export default slice.reducer;

const {
  performanceTimeframesRequested,
  performanceTimeframesReceived,
  performanceTimeframesRequestFailed,
  performanceTimeframeCreationRequested,
  performanceTimeframeCreationReceived,
  performanceTimeframeCreationFailed,
  performanceTimeframeUpdationRequested,
  performanceTimeframeUpdationReceived,
  performanceTimeframeUpdationFailed,
  performanceTimeframeDeletionRequested,
  performanceTimeframeDeletionReceived,
  performanceTimeframeDeletionFailed,
  currentPerformanceTimeframesRequested,
  currentPerformanceTimeframesReceived,
  currentPerformanceTimeframesRequestFailed,
  resetActionStates,
} = slice.actions;

const url = "v1/hrms/performance_timeframes";

export const loadPerformanceTimeframes = (payload) => (dispatch) => {
  let endpoint = url;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: performanceTimeframesRequested.type,
      onSuccess: performanceTimeframesReceived.type,
      onError: performanceTimeframesRequestFailed.type,
    })
  );
};

export const getCurrentPerformanceTimeframes = (payload) => (dispatch) => {
  const endpoint = `${url}/current_timeframes`;
  return dispatch(
    apiCallBegan({
      url: endpoint,
      onStart: currentPerformanceTimeframesRequested.type,
      onSuccess: currentPerformanceTimeframesReceived.type,
      onError: currentPerformanceTimeframesRequestFailed.type,
    })
  );
};

export const createPerformanceTimeframe = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: {
        performance_timeframe: payload.performance_timeframe,
      },
      type: "creation",
      onStart: performanceTimeframeCreationRequested.type,
      onSuccess: performanceTimeframeCreationReceived.type,
      onError: performanceTimeframeCreationFailed.type,
      showAlert: true,
      successMessage: "Timeframe created succefully!",
    })
  );
};

export const updatePerformanceTimeframe = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "PATCH",
      data: {
        performance_timeframe: payload.performance_timeframe,
      },
      type: "updation",
      onStart: performanceTimeframeUpdationRequested.type,
      onSuccess: performanceTimeframeUpdationReceived.type,
      onError: performanceTimeframeUpdationFailed.type,
      showAlert: true,
      successMessage: "Timeframe updated succefully!",
    })
  );
};

export const deletePerformanceTimeframe = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.id}`,
      method: "DELETE",
      type: "deletion",
      onStart: performanceTimeframeDeletionRequested.type,
      onSuccess: performanceTimeframeDeletionReceived.type,
      onError: performanceTimeframeDeletionFailed.type,
      showAlert: true,
      successMessage: "Timeframe deleted succefully!",
    })
  );
};

export const resetActions = () => (dispatch) => {
  return dispatch(resetActionStates());
};
