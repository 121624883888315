import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ConvertToTitleCase } from "../../../common/Utils";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";

const SelectStatus = ({
  statuses,
  handleChange,
  value,
  mt = 0,
  maxHeight = undefined,
}) => {
  return (
    <div>
      <FormControl
        id="select-status-form-input"
        sx={{ marginTop: mt, width: "-webkit-fill-available" }}
        size="medium"
      >
        <InputLabel id="select-status-label" shrink={!!value} sx={{
            background: !!value ? "#FFF" : "transparent",
            padding: "2px 4px",
          }}>Status</InputLabel>
        <Select
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          sx={{ maxHeight: maxHeight }}
        >
          {statuses.map((status, index) => (
            <MenuItem
              key={index}
              value={status.name}
              sx={{ paddingTop: "8px", paddingBottom: "8px", borderBottom: "1px dashed #ccc" }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <SquareRoundedIcon
                  style={{ color: status.color, marginRight: "5px" }}
                />
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: "0.775rem",
                    textOverflow: "ellipsis",
                    whiteSpace: "no-wrap",
                    overflow: "hidden",
                  }}
                  primary={status.name}
                />
              </Box>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default SelectStatus;
