import useTheme from "@mui/material/styles/useTheme";
const MenuDownIcon = ({
  strokeColor = undefined,
  strokeWidth = "1.5",
  height = "25",
  width = "25",
}) => {
  const theme = useTheme();
  const iconColor = strokeColor || theme.custom.themeContrastColorCode
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="icon icon-tabler icon-tabler-square-chevron-down"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth}
      stroke={iconColor}
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M15 11l-3 3l-3 -3" />
      <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
    </svg>
  );
};

export default MenuDownIcon;
