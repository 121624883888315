import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "alerts",
  initialState: {
    alert: {
      showAlert: false,
      message: "",
      type: "info",
    },
    list: [],
  },

  reducers: {
    updateAlert: (state, action) => {
      state.alert = {
        showAlert: true,
        message: action.payload.message,
        type: action.payload.type || "info",
      };
    },

    updateAlerts: (state, action) => {
      state.list = action.payload.alerts
    },

    clearAlert: (state) => {
      state.alert = { showAlert: false, message: "", type: "info" };
      state.list = []
    }
  },
});

export default slice.reducer;

const { updateAlert, updateAlerts, clearAlert } = slice.actions;

export const setAlert = (payload) => (dispatch) => {
  dispatch(updateAlert(payload));

  setTimeout(() => {
    dispatch(clearAlert());
  }, 5000);
};

export const setAlerts = (payload) => (dispatch) => {
  dispatch(updateAlerts(payload));

  setTimeout(() => {
    dispatch(clearAlert());
  }, payload.timeout || 5000);
};


export const dismissAlert = () => (dispatch) => {
  dispatch(clearAlert());
};
