import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";

const slice = createSlice({
  name: "employees_leaves",
  initialState: {
    list: [],
    list_loading: false,
    summary: [],
    ongoing_and_upcoming_leaves: [],
    past_leaves: [],
    summary_loading: false,
    ongoing_and_upcoming_leaves_loading: false,
    past_leaves_loading: false,
    approval_pending_leaves: [],
    approval_pending_leaves_loading: false,
    action_loading: false,
  },

  reducers: {
    employeesLeavesRequested: (state, action) => {
      state.list = [];
      state.list_loading = true;
    },

    employeesLeavesReceived: (state, action) => {
      state.list = action.payload.leaves;
      state.list_loading = false;
    },

    employeesLeavesRequestFailed: (state, action) => {
      state.list_loading = false;
    },

    employeesLeavesSummaryRequested: (state, action) => {
      state.summary = [];
      state.summary_loading = true;
    },

    employeesLeavesSummaryReceived: (state, action) => {
      state.summary = action.payload.summary;
      state.summary_loading = false;
    },

    employeesLeavesSummaryRequestFailed: (state, action) => {
      state.summary_loading = false;
    },

    employeesOngoingAndUpcomingLeavesRequested: (state, action) => {
      state.ongoing_and_upcoming_leaves = [];
      state.ongoing_and_upcoming_leaves_loading = true;
    },

    employeesOngoingAndUpcomingLeavesReceived: (state, action) => {
      state.ongoing_and_upcoming_leaves = action.payload.leaves;
      state.ongoing_and_upcoming_leaves_loading = false;
    },

    employeesOngoingAndUpcomingLeavesRequestFailed: (state, action) => {
      state.ongoing_and_upcoming_leaves_loading = false;
    },

    employeesPastLeavesRequested: (state, action) => {
      state.past_leaves = [];
      state.past_leaves_loading = true;
    },

    employeesPastLeavesReceived: (state, action) => {
      state.past_leaves = action.payload.leaves;
      state.past_leaves_loading = false;
    },

    employeesPastLeavesRequestFailed: (state, action) => {
      state.past_leaves_loading = false;
    },

    employeesApprovalPendingLeavesRequested: (state, action) => {
      state.approval_pending_leaves = [];
      state.approval_pending_leaves_loading = true;
    },

    employeesApprovalPendingLeavesReceived: (state, action) => {
      state.approval_pending_leaves = action.payload.leaves;
      state.approval_pending_leaves_loading = false;
    },

    employeesApprovalPendingLeavesRequestFailed: (state, action) => {
      state.approval_pending_leaves_loading = false;
    },

    takeAnActionRequested: (state, action) => {
      state.action_loading = true;
    },

    takeAnActionReceived: (state, action) => {
      const { id, leave } = action.payload;
      if (action.requestType === "reject" || action.requestType === "approve") {
        state.approval_pending_leaves = state.approval_pending_leaves.filter(
          (item) => item.uid !== id,
        );
      }

      if (state.list.length) {
        const leaveIndex = state.list.findIndex((item) => item.id === leave.id);
        if (leaveIndex !== -1) {
          state.list[leaveIndex] = leave;
        }
      }
      state.action_loading = false;
    },

    takeAnActionRequestFailed: (state, action) => {
      state.action_loading = false;
    },
  },
});

export default slice.reducer;

const {
  employeesLeavesRequested,
  employeesLeavesReceived,
  employeesLeavesRequestFailed,
  employeesLeavesSummaryRequested,
  employeesLeavesSummaryReceived,
  employeesLeavesSummaryRequestFailed,
  employeesOngoingAndUpcomingLeavesRequested,
  employeesOngoingAndUpcomingLeavesReceived,
  employeesOngoingAndUpcomingLeavesRequestFailed,
  employeesPastLeavesRequested,
  employeesPastLeavesReceived,
  employeesPastLeavesRequestFailed,
  employeesApprovalPendingLeavesRequested,
  employeesApprovalPendingLeavesReceived,
  employeesApprovalPendingLeavesRequestFailed,
  takeAnActionRequested,
  takeAnActionReceived,
  takeAnActionRequestFailed,
} = slice.actions;

const url = "v1/hrms/employees_leaves";

export const getEmployeesLeaves = (payload) => (dispatch) => {
  const endPoint = `${url}/${payload.leaves_of}/leaves?start_date=${payload.start_date}&end_date=${payload.end_date}`;
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesLeavesRequested.type,
      onSuccess: employeesLeavesReceived.type,
      onError: employeesLeavesRequestFailed.type,
    }),
  );
};

export const getEmployeesLeavesSummary = (payload) => (dispatch) => {
  let endPoint = `${url}/${payload.leaves_of}/summary`;
  if (payload.year) {
    endPoint = `${endPoint}?year=${payload.year}`
  }
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesLeavesSummaryRequested.type,
      onSuccess: employeesLeavesSummaryReceived.type,
      onError: employeesLeavesSummaryRequestFailed.type,
    }),
  );
};

export const getEmployeesOngoingAndUpcomingLeaves = (payload) => (dispatch) => {
  const endPoint = `${url}/${payload.leaves_of}/ongoing_and_upcoming_leaves`;
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesOngoingAndUpcomingLeavesRequested.type,
      onSuccess: employeesOngoingAndUpcomingLeavesReceived.type,
      onError: employeesOngoingAndUpcomingLeavesRequestFailed.type,
    }),
  );
};

export const getEmployeesPastLeaves = (payload) => (dispatch) => {
  const endPoint = `${url}/${payload.leaves_of}/past_leaves?start_date=${payload.start_date}&end_date=${payload.end_date}`;
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesPastLeavesRequested.type,
      onSuccess: employeesPastLeavesReceived.type,
      onError: employeesPastLeavesRequestFailed.type,
    }),
  );
};

export const getEmployeesApprovalPendingLeaves = (payload) => (dispatch) => {
  const endPoint = `${url}/${payload.leaves_of}/approval_pending_leaves`;
  return dispatch(
    apiCallBegan({
      url: endPoint,
      onStart: employeesApprovalPendingLeavesRequested.type,
      onSuccess: employeesApprovalPendingLeavesReceived.type,
      onError: employeesApprovalPendingLeavesRequestFailed.type,
    }),
  );
};

export const takeAnAction = (payload) => (dispatch) => {
  return dispatch(
    apiCallBegan({
      url: `${url}/${payload.leaves_of}/${payload.action}`,
      method: "PATCH",
      data: { id: payload.id, reason: payload.reason },
      type: payload.action,
      onStart: takeAnActionRequested.type,
      onSuccess: takeAnActionReceived.type,
      onError: takeAnActionRequestFailed.type,
      showAlert: true,
    }),
  );
};
