import React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export default function SelectDate({ value, handleChange, mt = 0, maxHeight = undefined, label = undefined, applyColorCodes = true }) {
  const currentDate = dayjs(new Date()).format("YYYY-MM-DD");
  const formattedSelectedDate = value && dayjs(new Date(value)).format("YYYY-MM-DD");

  const inputFontColor = () => {
    if (!value) {
      return "gray";
    }
    if (dayjs(formattedSelectedDate).isBefore(dayjs(currentDate))) {
      return applyColorCodes ? "red" : "inherit";
    }
    if (dayjs(formattedSelectedDate).isAfter(dayjs(currentDate))) {
      return applyColorCodes ? "green" : "inherit";
    }
    if (dayjs(formattedSelectedDate).isSame(dayjs(currentDate))) {
      return applyColorCodes ? "orange" : "inherit";
    }
  };

  const handleClearDate = () => {
    handleChange(null); // Pass null to indicate no date is selected
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <FormControl
          id="select-date-form-input"
          sx={{ marginTop: mt, width: "-webkit-fill-available" }}
          size="medium"
        >
          <InputLabel
            htmlFor="date-picker"
            shrink
            style={{
              color: applyColorCodes ? inputFontColor() : "#00000099",
              background: 'white',
              fontWeight: 400,
              fontSize: '0.975rem',
              padding: "2px 8px",
            }}
          >
            {label || 'Due Date'}
          </InputLabel>
          <MobileDatePicker
            format="ll"
            value={value ? dayjs(value) : null}
            onChange={(newValue) => handleChange(dayjs(newValue).format('YYYY-MM-DD'))}
            error={false}
            sx={{
              maxHeight: maxHeight,
              "& .MuiInputBase-root": {
                maxHeight: maxHeight,
                "& .MuiInputBase-input": {
                  color: applyColorCodes ? inputFontColor() : "inherit",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#c4c4c4",
                  "& .Mui-error": {
                    borderColor: "#c4c4c4",
                  }
                },
              },
            }}
          />
          {/* Clear icon button */}
          {value && (
            <IconButton
              onClick={handleClearDate}
              size="small"
              sx={{
                position: 'absolute',
                top: '50%',
                right: 8,
                transform: 'translateY(-50%)',
              }}
            >
              <ClearIcon sx={{ fontSize: '14px' }} />
            </IconButton>
          )}
        </FormControl>
      </div>
    </LocalizationProvider>
  );
}

