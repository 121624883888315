import React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

const OAuthButton = ({ data, ...props }) => {
	return(

			<Button variant='success-outlined' {...props}>
				<Avatar src={data.logo} sx={{ height: 32, width: 32, marginRight: '12px' }} />
				{data.label}
			</Button>
	)
}

export default OAuthButton;