import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ConvertToTitleCase } from "../../../common/Utils";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { loadStatusGroups } from "../../../store/status_groups";
import { useDispatch, useSelector } from 'react-redux';
import StatusGroupForm from "../../../pages/pm/customizations/statuses/StatusGroupForm"

const SelectStatusGroup = ({
  statuses,
  handleChange,
  value,
  maxHeight = undefined,
}) => {

  const [openStatusGroupForm, toggleStatusGroupForm] = useState(false)
  const [viewingStatusGroup, setViewingStatusGroup] = useState(null)

  const dispatch = useDispatch()
  const statusGroups = useSelector(state => state.status_groups.list)

  useEffect(() => {
    statusGroups.length < 1 && dispatch(loadStatusGroups())
  }, [])

  const selectedGroupName = statusGroups.find(group => group.id === value)?.name

  return (
    <div>
      <FormControl
        id="select-status-group-form-input"
        sx={{ width: "-webkit-fill-available" }}
        size="medium"
      >
        <InputLabel id="select-status-group-label" sx={{
            background: value ? "#FFF" : "transparent",
            padding: "2px 4px",
          }} shrink={value}>Status Group</InputLabel>
        <Select
          value={value}
          renderValue={() => selectedGroupName }
          onChange={(e) => handleChange(e.target.value)}
          sx={{ maxHeight: maxHeight }}
        >
          {statusGroups.map((group, index) => (
            <MenuItem
              key={group.uid}
              value={group.id}
              sx={{ paddingTop: "8px", paddingBottom: "8px", borderBottom: "1px dashed #ccc", display: 'flex', justifyContent: 'space-between' }}
            >
              {group.name}
              <Button variant='text' onClick={() => {
                setViewingStatusGroup(group)
                toggleStatusGroupForm(true)
              }}>View</Button>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {openStatusGroupForm && viewingStatusGroup && <StatusGroupForm statusGroup={viewingStatusGroup} open={true} onClose={() => toggleStatusGroupForm(false)} statuses={statuses} />}
    </div>
  );
};

export default SelectStatusGroup;
