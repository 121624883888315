import React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import MainMenu from "./MainMenu";
import AppMainMenu from "./AppMainMenu";
import useTheme from "@mui/material/styles/useTheme";

function HideOnScroll({ direction, ...props }) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction={direction} in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function IntelligentAppBar({
  loggedInUser,
  currentPath,
  handleDrawerOpen,
  isBelowMediumScreen,
  isLargeScreen,
  open,
  ...props
}) {
  const theme = useTheme();
  if (isBelowMediumScreen) {
    return (
      <HideOnScroll direction="up" {...props}>
        <AppBar
          elevation={0}
          position="fixed"
          open={open}
          sx={{
            top: "auto",
            bottom: 0,
            borderTop: `0.5px solid ${theme.custom.tabBorderColor}`,
            pt: 1,
            pb: 2,
            backgroundColor: theme.custom.mobileAppBarBackground,
          }}
        >
          <Toolbar
            sx={{ height: "3.325rem", minHeight: "3.325rem !important" }}
          >
            <AppMainMenu currentPath={currentPath} />
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    );
  }

  return (
    <HideOnScroll direction="down" {...props}>
      <AppBar
        position="fixed"
        open={open}
        sx={{
          height: "3.525rem",
          top: 0,
          bottom: "auto",
          backgroundColor: theme.custom.appBarBackground,
          borderBottom: `0.5px solid ${theme.custom.tabBorderColor}`,
        }}
        elevation={0}
      >
        <Toolbar sx={{ height: "3.525rem", minHeight: "3.525rem !important" }}>
          <MainMenu
            currentPath={currentPath}
            loggedInUser={loggedInUser}
            handleDrawerOpen={handleDrawerOpen}
          />
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
}
