import axios from "axios";
import * as actions from "./api";
import { setAlerts } from "./alerts";
import { removeCookie } from "../common/Utils";

const SERVER_URL = process.env.REACT_APP_API_URL;

const authToken = () =>
  decodeURIComponent(
    document.cookie.replace(
      /(?:(?:^|.*;\s*)authToken\s*=\s*([^;]*).*$)|^.*$/,
      "$1"
    )
  );

const request =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    const {
      url,
      method,
      data,
      onStart,
      onSuccess,
      onError,
      type,
      showAlert,
      showErrorAlert,
      successMessage,
      failureMessage,
      isLogoutRequest,
      extraHeaders = {},
      extraInfo = {},
      onProgress,
    } = action.payload;

    if (onStart)
      dispatch({ type: onStart, requestType: type, extraInfo: extraInfo });

    next(action);

    const headers = {
      withCredentials: true,
      Authorization: authToken(),
      ...extraHeaders,
    };

    try {
      const response = await axios.request({
        baseURL: SERVER_URL,
        url,
        method,
        data,
        headers: headers,
        onUploadProgress: (progressEvent) => {
          if (onProgress) {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            dispatch(onProgress(progress));
          }
        },
      });
      // General
      dispatch(actions.apiCallSucess(response.data));
      // Specific
      if (onSuccess)
        dispatch({
          type: onSuccess,
          payload: response.data,
          requestType: type,
          headers: response.headers,
          extraInfo: extraInfo,
        });
      (showAlert || isLogoutRequest) &&
        dispatch(
          setAlerts({
            alerts: [
              {
                message: successMessage || response.data.message,
                type: "success",
              },
            ],
          })
        );
      if (isLogoutRequest) {
        removeCookie("authToken", { path: "/" });
        window.location.href = "/login";
      }
    } catch (error) {
      // General
      dispatch(actions.apiCallFailed(error.message));
      // Specific
      if (onError)
        dispatch({
          type: onError,
          payload: error.message,
          extraInfo: extraInfo,
          requestType: type,
        });
      if (error.response?.status === 401) {
        removeCookie("authToken", { path: "/" });
        window.location.href = "/login";
      }
      if (showAlert || showErrorAlert) {
        if (error.response?.status === 500 && failureMessage) {
          dispatch(
            setAlerts({ alerts: [{ message: failureMessage, type: "error" }] })
          );
        } else {
          dispatch(
            setAlerts({
              alerts: [
                {
                  message:
                    error.response?.data?.message ||
                    error.response?.message ||
                    error.message,
                  type: "error",
                },
              ],
            })
          );
        }
      }
    }
  };

async function makeJsonServerCall({
  method = "POST",
  endpoint,
  payload,
  showAlert = false,
  dispatch,
}) {
  try {
    const url = `${SERVER_URL}/${endpoint}`;
    const data = {
      key1: "value1",
      key2: "value2",
    };

    const response = await axios({
      method: method,
      url: url,
      data: data,
      headers: {
        "Content-Type": "application/json",
        withCredentials: true,
        Authorization: authToken(),
      },
    });

    return response.data;
  } catch (error) {
    showAlert &&
      dispatch(
        setAlerts({ alerts: [{ message: error.message, type: "error" }] })
      );
    if (error.response?.status === 401) {
      removeCookie("authToken", { path: "/" });
      window.location.href = "/login";
    }
    return error;
  }
}

async function makeFormDataServerCall({
  method = "POST",
  endpoint,
  formData,
  showAlert = false,
  dispatch,
}) {
  try {
    const url = `${SERVER_URL}/${endpoint}`;

    const response = await axios({
      method: method,
      url: url,
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        withCredentials: true,
        Authorization: authToken(),
      },
    });

    return response.data;
  } catch (error) {
    showAlert &&
      dispatch(
        setAlerts({ alerts: [{ message: error.message, type: "error" }] })
      );
    if (error.response?.status === 401) {
      removeCookie("authToken", { path: "/" });
      window.location.href = "/login";
    }
  }
}

export { request, makeFormDataServerCall, makeJsonServerCall };
