import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import green from "@mui/material/colors/green";

const loaderStyle = {
  width: "6px",
  height: "6px",
  aspectRatio: "5",
  borderRadius: "50%",
  animation: "loaderDots 1s infinite linear alternate",
};

const keyframesStyle = `
@keyframes loaderDots {
  0%  {box-shadow: 10px 0 #000, -10px 0 #0002;background: #000 }
  33% {box-shadow: 10px 0 #000, -10px 0 #0002;background: #0002}
  66% {box-shadow: 10px 0 #0002,-10px 0 #000; background: #0002}
  100%{box-shadow: 10px 0 #0002,-10px 0 #000; background: #000 }
}
`;

const SuspenseLoader = ({ position = "fixed" }) => {
  return (
    <Box
      sx={{
        position: position,
        display: "flex",
        flexDirection: "column",
        width: "-webkit-fill-available",
        top: "45%",
        alignItems: "center",
      }}
    >
      <style>{keyframesStyle}</style>
      <Stack sx={{ color: "grey.500" }} spacing={1} direction="row">
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            variant="determinate"
            value={100}
            size="1.3rem"
            thickness={7}
            sx={{ color: "#ccc" }}
          />
          <CircularProgress
            disableShrink
            variant="indeterminate"
            size="1.3rem"
            thickness={7}
            sx={{
              color: green["A400"],
              position: "absolute",
              left: 0,
              animationDuration: "650ms",
            }}
          />
        </Box>
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            variant="determinate"
            value={100}
            size="1.3rem"
            thickness={7}
            sx={{ color: "#ccc" }}
          />
          <CircularProgress
            disableShrink
            variant="indeterminate"
            size="1.3rem"
            thickness={7}
            sx={{
              color: green["A400"],
              position: "absolute",
              left: 0,
              animationDuration: "650ms",
            }}
          />
        </Box>
        <Box sx={{ position: "relative" }}>
          <CircularProgress
            variant="determinate"
            value={100}
            size="1.3rem"
            thickness={7}
            sx={{ color: "#ccc" }}
          />
          <CircularProgress
            disableShrink
            variant="indeterminate"
            size="1.3rem"
            thickness={7}
            sx={{
              color: green["A400"],
              position: "absolute",
              left: 0,
              animationDuration: "650ms",
            }}
          />
        </Box>
      </Stack>
      <Box mt={0.5} sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="caption" fontSize="0.875rem" sx={{ mr: 2 }}>
          {" "}
          Loading{" "}
        </Typography>
        <div style={loaderStyle} />
      </Box>
    </Box>
  );
};

export default SuspenseLoader;
