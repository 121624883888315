import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import TextField from "@mui/material/TextField";
import SelectBucket from "./../SelectBucket";
import {
  getBuckets,
  createBucket,
  updateBucket,
} from "../../../../store/buckets";
import { useDispatch, useSelector } from "react-redux";
import { flattenTheList } from "../../../../common/Utils";
import SelectDate from "../../../../pages/pm/components/SelectDate";
import SelectStatusGroup from "../../../../pages/pm/components/SelectStatusGroup";

const BucketFormModal = ({
  bucket = null,
  open = false,
  parentBucketId = null,
  onClose,
  statuses = []
}) => {
  const [newName, setName] = useState("");
  const [newParentBucketId, setParentBucketId] = useState(null);
  const [newStartDate, setNewStartDate] = useState(null);
  const [newEndDate, setNewEndDate] = useState(null);
  const [newStatusGroupId, setNewStatusGroupId] = useState(null);

  const dispatch = useDispatch();
  const buckets = useSelector((state) => state.buckets.list);

  useEffect(() => {
    buckets.length < 1 && dispatch(getBuckets());
  }, []);

  const flattenBuckets = flattenTheList(buckets, "sub_buckets");

  const parentBucket = flattenBuckets.find(
    (buck) => buck.uid === newParentBucketId,
  );

  useEffect(() => {
    setParentBucketId(parentBucketId);
    if (bucket) {
      setName(bucket.name);
      setParentBucketId(bucket.parent_bucket_uid);
      setNewStartDate(bucket.start_date);
      setNewEndDate(bucket.end_date);
      setNewStatusGroupId(bucket.status_group_id)
    }
  }, [parentBucketId, bucket]);

  const handleClose = () => {
    setName("");
    setParentBucketId(null);
    setNewStartDate(null);
    setNewEndDate(null);
    setNewStatusGroupId(null);
    onClose();
  };

  const onSave = async () => {
    const payload = {
      name: newName,
      parent_bucket_id: parentBucket?.id || null,
      start_date: newStartDate,
      end_date: newEndDate,
      status_group_id: newStatusGroupId
    };
    if (bucket) {
      payload["id"] = bucket.uid;
      await dispatch(updateBucket(payload));
    } else {
      await dispatch(createBucket(payload));
    }
    handleClose();
  };

  const handleTitleChange = (e) => {
    setName(e.target.value);
  };

  const Header = () => {
    return (
      <DialogTitle
        sx={{
          width: "-webkit-fill-available",
          height: 60,
          borderBottom: "1px solid #e7e8e7",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "2px 20px",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: 500 }}>
          {bucket ? "Update Bucket" : "Create Bucket"}
        </Typography>
        <CloseRoundedIcon onClick={handleClose} />
      </DialogTitle>
    );
  };

  const Body = () => {
    return (
      <DialogContent
        key="new-ticket-content"
        sx={{
          width: "-webkit-fill-available",
          padding: "5px 25px",
          marginBottom: "20px",
        }}
      >
        <TextField
          key="new-bucket-title"
          sx={{ marginTop: "1.5rem", width: "-webkit-fill-available" }}
          label="Bucket Name"
          id="title"
          value={newName}
          onChange={handleTitleChange}
        />
        <Box mt={2}>
          <SelectBucket
            buckets={buckets || []}
            flattenList={flattenBuckets || []}
            value={newParentBucketId}
            handleChange={setParentBucketId}
            label="Parent Bucket"
          />
        </Box>
        <Box mt={2}>
          <SelectDate
            value={newStartDate}
            handleChange={(value) => setNewStartDate(value)}
            label="Start Date"
            applyColorCodes={false}
          />
        </Box>
        <Box mt={2}>
          <SelectDate
            value={newEndDate}
            handleChange={(value) => setNewEndDate(value)}
            label="End Date"
            applyColorCodes={false}
          />
        </Box>
        <Box mt={2}>
          <SelectStatusGroup
            value={newStatusGroupId}
            handleChange={(value) => setNewStatusGroupId(value)}
            label="Status Group"
            statuses={statuses}
          />
        </Box>
      </DialogContent>
    );
  };

  const Footer = () => {
    return (
      <DialogActions sx={{ borderTop: "1px solid #e7e8e7", height: 60 }}>
        <Button onClick={handleClose} variant="outlined" color="default">
          Cancel
        </Button>
        <Button
          onClick={onSave}
          variant="success-outlined"
          disabled={!newName || !newName.trim().length}
        >
          {bucket ? "Update" : "Create"}
        </Button>
      </DialogActions>
    );
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      key="new-ticket-creation-modal"
    >
      {Header()}
      {Body()}
      {Footer()}
    </Dialog>
  );
};

export default BucketFormModal;
